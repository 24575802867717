import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LeftArrowIcon from "../../../assets/arrow-left.svg?react";
import CloseIcon from "../../../assets/close.svg?react";
import { AppContext, LighthouseContext } from "../../../Context";
import { setIsSavingLighthouse, setIsSectorEditorOpen, setIsShowingIALAWarning } from "../../../store/lighthouseSlice";
import { StoreState } from "../../../store/rootReducer";
import "./SectorEditor.css";
import SectorEditorContent from "./SectorEditorContent";
import { setFeatureViewerOpen, setLoading } from "../../../store/appSlice";
import { moveToLayer } from "../../../utils/arcgisUtils";
import { satisfiesIALA, saveSectorData } from "../../../utils/lighthouseUtils";

const SectorEditor = () => {
    const dispatch = useDispatch();
    const lighthouseContext = useContext(LighthouseContext);
    const appContext = useContext(AppContext);

    const [panelClass, setPanelClass] = useState<string>("collapsed");
    const sectorEditorOpen = useSelector((state: StoreState) => state.lighthouse.isSectorEditorOpen);
    const hasShownIALAWarning = useSelector((state: StoreState) => state.lighthouse.isShowingIALAWarning);
    const trueNorth = useSelector((state: StoreState) => state.lighthouse.currentLighthouseTrueNorth);

    // Due to css behavior with animations to display none and vice versa
    // an additional class is needed to allow panel to slide out
    const toggleOpen = () => {
        if (panelClass === "") {
            closePanel();
        } else if (panelClass === "collapsed") {
            openPanel();
        }
    };

    const openPanel = () => {
        if (panelClass === "") {
            return;
        }
        setPanelClass("opening");
        setTimeout(() => {
            setPanelClass("");
        }, 100);
    };

    const closePanel = () => {
        setPanelClass("collapsed");
    };

    const fullyClosePanel = () => {
        if (!satisfiesIALA(lighthouseContext!.lighthouseData.value) && !hasShownIALAWarning) {
            dispatch(setIsShowingIALAWarning(true));
            return;
        }

        lighthouseContext?.lighthouseSectorPanelsOpen.set(new Set());
        dispatch(setIsSectorEditorOpen(false));
        dispatch(setFeatureViewerOpen(true));
        dispatch(setIsShowingIALAWarning(false));
        moveToLayer("all", "FyrlyktEditing", "Fyrlykt");
        saveSectorData(
            lighthouseContext!.lighthouseData.value,
            appContext!.selectedFeature.value!,
            trueNorth,
            (isSaving: boolean) => dispatch(setIsSavingLighthouse(isSaving)),
            (loadingStatus: boolean) => dispatch(setLoading(loadingStatus))
        );
    };

    useEffect(() => {
        if (sectorEditorOpen) {
            openPanel();
        } else {
            closePanel();
        }
    }, [sectorEditorOpen]);

    if (!sectorEditorOpen) return null;

    return (
        <div className={`feature-viewer-container`}>
            <div className={`feature-viewer ${panelClass}`}>
                <div className="feature-viewer-header sector-editor-close">
                    <button type="button" className="empty-button" onClick={fullyClosePanel}>
                        <CloseIcon />
                    </button>
                </div>
                <SectorEditorContent />
            </div>
            <button type="button" className={`light-calculator-collapse-button ${panelClass}`} onClick={toggleOpen}>
                <LeftArrowIcon />
            </button>
        </div>
    );
};

export default SectorEditor;

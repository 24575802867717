import VersionManagementService from "@arcgis/core/versionManagement/VersionManagementService";
import { useContext, useEffect, useState } from "react";

import ClipboardIcon from "../../assets/versionControl/clipboard.svg?react";
import ArrowUpIcon from "../../assets/versionControl/arrow-up.svg?react";
import ArrowDownIcon from "../../assets/versionControl/arrow-down.svg?react";
import ShareIcon from "../../assets/versionControl/share.svg?react";
import TrashIcon from "../../assets/versionControl/delete.svg?react";
import EditIcon from "../../assets/versionControl/pencil.svg?react";
import ReadIcon from "../../assets/versionControl/read.svg?react";

import "./VersionPanel.css";
import { canEditVersion, getVersionName, getVersionOwner, isMyVersion } from "../../utils/versioningUtils";
import NfsButton from "../genericComponents/NfsButton/NfsButton";
import { formatDate, hasAccessToRoles } from "../../utils/helpers";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/rootReducer";
import ConfirmationPopover from "../genericComponents/confirmationPopover/confirmationPopover";
import { UserContext } from "../../Context";
import { AppConfig } from "../../AppConfig";
import Modal from "react-modal";
import VersionAdmin from "./VersionAdmin";
import { getSelf } from "../../utils/authenticate";

interface VersionPanelProps {
    versionInfo: __esri.VersionInfoExtendedJSON;
    reloadVersions: () => Promise<void>;
    selectVersion: (versionInfo: __esri.VersionInfoJSON) => Promise<void>;
    deleteVersion: (versionInfo: __esri.VersionInfoJSON) => void;
    versionsSharedWithMe: string[];
}

const VersionPanel = (props: VersionPanelProps & { versionServiceRef: React.RefObject<VersionManagementService> }) => {

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isDefault, setIsDefault] = useState<boolean>(false);
    const [isAdminModalOpen, setIsAdminModalOpen] = useState<boolean>(false);
    const [canIEditVersion, setCanIEditVersion] = useState<boolean>(false);

    const userContext = useContext(UserContext);

    // const dispatch = useDispatch();

    // const activeSessionID = useSelector((state: StoreState) => state.version.activeSessionID);
    const activeGuid = useSelector((state: StoreState) => state.version.activeGuid);
    // const readActive = useSelector((state: StoreState) => state.version.readActive);
    // const editActive = useSelector((state: StoreState) => state.version.editActive);
    // const versionInfo = useSelector((state: StoreState) => state.version.versionInfo);

    // const versionServiceRef = useRef(
    //     new VersionManagementService({
    //         url: AppConfig.ArcGIS.BaseUrl + AppConfig.ArcGIS.VersionManagementService,
    //     })
    // );


    const selectVersion = async () => {
        await props.selectVersion(props.versionInfo);
    }

    const deleteVersion = () => {
        props.deleteVersion(props.versionInfo);
    }

    const isActiveVersion = () => {
        const trimmedGuid = props.versionInfo.versionIdentifier.guid.replace("{", "").replace("}", "");
        return activeGuid === trimmedGuid;
    }

    const isDefaultVersion = () => {
        if (!props.versionServiceRef.current) return;
        props.versionServiceRef.current.load().then(() => {
            if (!props.versionServiceRef.current) return;
            setIsDefault(props.versionInfo.versionIdentifier.guid === props.versionServiceRef.current.defaultVersionIdentifier.guid);
        });
    }

    const isVersionOwner = () => {
        const user = userContext?.user.value;
        if (!user) {
            return false;
        }

        const versionAdminRoles = AppConfig.RoleAccess.VersionAdmin;
        const userSelf = userContext?.userSelf.value;
        if (!userSelf) {
            getSelf().then((res: any) => {
                if (res.data) {
                    userContext?.userSelf.set(res.data);
                }
            });
        }
        const hasAccess = hasAccessToRoles(userSelf, versionAdminRoles);
        if (hasAccess) {
            return true;
        }

        return isMyVersion(props.versionInfo, user);
    }

    const canAdminVersion = () => {
        const versionAdminRoles = AppConfig.RoleAccess.VersionAdmin
        const userSelf = userContext?.userSelf.value;
        if (!userSelf) {
            getSelf().then((res: any) => {
                if (res.data) {
                    userContext?.userSelf.set(res.data);
                }
            });
        }
        if (versionAdminRoles.length === 0 || !userSelf) {
            return false;
        }

        const hasAccess = hasAccessToRoles(userSelf, versionAdminRoles);
        if (hasAccess) {
            return true;
        }

        const user = userContext?.user.value;
        if (!user) {
            return false;
        }

        const isOwner = isMyVersion(props.versionInfo, user);
        return isOwner;
    }

    const checkCanEditVersion = async () => {
        const userSelf = userContext?.userSelf.value;
        if (!userSelf) {
            getSelf().then((res: any) => {
                if (res.data) {
                    userContext?.userSelf.set(res.data);
                }
            });
        }

        canEditVersion(props.versionInfo, props.versionsSharedWithMe, userSelf).then((res: boolean) => {
            setCanIEditVersion(res);
        });
    }

    useEffect(() => {
        checkCanEditVersion();
    }, [props.versionsSharedWithMe]);

    useEffect(() => {
        if (!props.versionServiceRef.current) return;
        isDefaultVersion();

        checkCanEditVersion();
    }, []);

    return (
        <div className={`version-panel ${isOpen ? "expanded" : ""}`}>
            <button className="version-panel-header" onClick={() => setIsOpen(!isOpen)}>
                <span className="small-gap center">
                    <ClipboardIcon style={{ minWidth: "24px" }} />
                    <span className="text-left break-all">{getVersionOwner(props.versionInfo)}: {getVersionName(props.versionInfo)}</span>
                    {/* <span>{props.versionInfo.versionIdentifier.name}</span> */}
                </span>

                {isOpen ? <ArrowUpIcon style={{ minWidth: "24px" }} /> : <ArrowDownIcon style={{ minWidth: "24px" }} />}
            </button>
            {isOpen && props.versionInfo &&
                <div className="version-panel-content">
                    <div className="version-panel-content-row">
                        <span className="version-panel-access-label">
                            {props.versionInfo.access}
                        </span>
                        {canAdminVersion() && !isDefault &&
                            <>
                                <NfsButton className="no-flex" inverted outlined onClick={() => { setIsAdminModalOpen(true) }}>
                                    <ShareIcon />
                                    Administrer
                                </NfsButton>

                                <Modal
                                    isOpen={isAdminModalOpen}
                                    shouldCloseOnOverlayClick={true}
                                    shouldCloseOnEsc={true}
                                    onRequestClose={() => setIsAdminModalOpen(false)}
                                    style={{
                                        overlay: {
                                            background: 'rgba(37, 37, 37, 0.50)',
                                        },
                                        content: {
                                            top: '50%',
                                            left: '50%',
                                            right: 'auto',
                                            bottom: 'auto',
                                            marginRight: '-50%',
                                            transform: 'translate(-50%, -50%)',
                                            padding: 0,
                                            background: "none",
                                            border: "none",
                                        },
                                    }}
                                >
                                    <VersionAdmin reloadVersions={props.reloadVersions} close={() => setIsAdminModalOpen(false)} version={props.versionInfo} />
                                </Modal>
                            </>
                        }
                    </div>
                    <div className="version-panel-content-row">
                        <span className="version-panel-info-text">Eier</span>
                        <span className="version-panel-info-text bold">{getVersionOwner(props.versionInfo)}</span>
                    </div>
                    <div className="version-panel-content-row">
                        <span className="version-panel-info-text">Opprettet</span>
                        <span className="version-panel-info-text bold">{formatDate(props.versionInfo.creationDate ?? 0)}</span>
                    </div>
                    <div className="version-panel-content-row">
                        <span className="version-panel-info-text">Sist endret</span>
                        <span className="version-panel-info-text bold">{formatDate(props.versionInfo.modifiedDate ?? 0)}</span>
                    </div>
                    <div className="version-panel-comment">
                        <label htmlFor={"kommentar" + props.versionInfo.versionIdentifier.guid} className="version-panel-comment-label">
                            Kommentar:<span className="version-panel-comment-content" id={"kommentar" + props.versionInfo.versionIdentifier.guid}>{props.versionInfo.description}</span>
                        </label>
                    </div>
                    <div className="version-panel-actions">
                        {!isDefault && isVersionOwner() &&
                            <ConfirmationPopover
                                onConfirm={deleteVersion}
                                message="Er du sikker på at du vil slette denne versjonen?"
                                title="Slett versjon"
                                warning
                                onCancel={() => { }}
                                cancelText="Avbryt"
                                confirmText="Slett"
                            >
                                <div className="no-flex nfs-button inverted outline">
                                    <TrashIcon />
                                    Slett
                                </div>
                            </ConfirmationPopover>
                        }
                        <NfsButton className="no-flex" disabled={isActiveVersion()} onClick={selectVersion}>
                            {canIEditVersion ?
                                <>
                                    <EditIcon />
                                    Velg
                                </>
                                :
                                <>
                                    <ReadIcon />
                                    Se
                                </>
                            }
                        </NfsButton>
                    </div>
                </div>
            }
        </div>
    );
}

export default VersionPanel;
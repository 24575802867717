import { uuidv4 } from "./helpers";
import { AppConfig } from "../AppConfig";
import { getAGEToken } from "./authenticate";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import { getMapView } from "./arcgisUtils";
import Graphic from "@arcgis/core/Graphic";
import VersionManagementService from "@arcgis/core/versionManagement/VersionManagementService";

export const getSessionID = () => {
    return uuidv4().toLowerCase();
};

export const getDiffrences = async (
    versionGuid: string,
    sessionID: string,
    resultType?: string
): Promise<DiffrencesObject[]> => {
    const versionServiceUrl = getVersionServiceUrl();
    const token = await getAGEToken();
    let features: DiffrencesObject[] = [];
    resultType = resultType ?? "features";
    const urlSessionID = encodeURIComponent(sessionID);
    await fetch(
        versionServiceUrl +
        `/versions/${versionGuid}/differences?f=json&sessionId=${urlSessionID}&resultType=${resultType}`,
        {
            method: "POST",
            headers: {
                "X-Esri-Authorization": "Bearer " + token,
            },
        }
    )
        .then(async (response) => {
            await response.json().then((data) => {
                console.log(data);
                if (data && data.features) {
                    features = data.features;
                }
            });
        })
        .catch((error) => {
            console.error(error);
        });
    return features;
};

export const reconcileVersion = async (
    versionGuid: string,
    sessionID: string,
    abortIfConflict: boolean,
    conflicDetection: string,
    withPost: boolean
) => {
    const versionServiceUrl = getVersionServiceUrl();
    const token = await getAGEToken();
    const urlSessionID = encodeURIComponent(sessionID);
    await fetch(
        versionServiceUrl +
        `/versions/${versionGuid}/reconcile?f=json&sessionId=${urlSessionID}&abortIfConflict=${abortIfConflict}&conflictDetection=${conflicDetection}&withPost=${withPost}`,
        {
            method: "POST",
            headers: {
                "X-Esri-Authorization": "Bearer " + token,
            },
        }
    )
        .then(async (response) => {
            await response.json().then((data) => {
                console.log(data);
            });
        })
        .catch((error) => {
            console.error(error);
        });
};

export const getConflicts = async (versionGuid: string, sessionID: string) => {
    const versionServiceUrl = getVersionServiceUrl();
    const token = await getAGEToken();
    const urlSessionID = encodeURIComponent(sessionID);
    await fetch(versionServiceUrl + `/versions/${versionGuid}/conflicts?f=json&sessionId=${urlSessionID}`, {
        method: "POST",
        headers: {
            "X-Esri-Authorization": "Bearer " + token,
        },
    })
        .then((response) => {
            response.json().then((data) => {
                console.log(data);
            });
        })
        .catch((error) => {
            console.error(error);
        });
};

export const startReading = async (versionGuid: string, sessionID: string): Promise<boolean> => {
    const success = false;
    const versionServiceUrl = getVersionServiceUrl();
    const token = await getAGEToken();
    const urlSessionID = encodeURIComponent(sessionID);
    await fetch(versionServiceUrl + `/versions/${versionGuid}/startReading?f=json&sessionId=${urlSessionID}`, {
        method: "POST",
        headers: {
            "X-Esri-Authorization": "Bearer " + token,
        },
    })
        .then((response) => {
            response.json().then((data) => {
                if (data.success) {
                    return true;
                }
            });
        })
        .catch((error) => {
            console.error(error);
        });
    return success;
};

export const stopReading = async (versionGuid: string, sessionID: string): Promise<boolean> => {
    const success = false;
    const versionServiceUrl = getVersionServiceUrl();
    const token = await getAGEToken();
    const urlSessionID = encodeURIComponent(sessionID);
    await fetch(versionServiceUrl + `/versions/${versionGuid}/stopReading?f=json&sessionId=${urlSessionID}`, {
        method: "POST",
        headers: {
            "X-Esri-Authorization": "Bearer " + token,
        },
    })
        .then((response) => {
            response.json().then((data) => {
                if (data.success) {
                    return true;
                }
            });
        })
        .catch((error) => {
            console.error(error);
        });
    return success;
};

export const startEditing = async (versionGuid: string, sessionID: string): Promise<boolean> => {
    const success = false;
    const versionServiceUrl = getVersionServiceUrl();
    const token = await getAGEToken();
    const urlSessionID = encodeURIComponent(sessionID);
    await fetch(versionServiceUrl + `/versions/${versionGuid}/startEditing?f=json&sessionId=${urlSessionID}`, {
        method: "POST",
        headers: {
            "X-Esri-Authorization": "Bearer " + token,
        },
    })
        .then((response) => {
            response.json().then((data) => {
                if (data.success) {
                    return true;
                }
            });
        })
        .catch((error) => {
            console.error(error);
        });
    return success;
};

export const stopEditing = async (versionGuid: string, sessionID: string, saveEdits: boolean): Promise<boolean> => {
    const success = false;
    const versionServiceUrl = getVersionServiceUrl();
    const token = await getAGEToken();
    const urlSessionID = encodeURIComponent(sessionID);
    await fetch(
        versionServiceUrl +
        `/versions/${versionGuid}/stopEditing?f=json&sessionId=${urlSessionID}&saveEdits=${saveEdits}`,
        {
            method: "POST",
            headers: {
                "X-Esri-Authorization": "Bearer " + token,
            },
        }
    )
        .then((response) => {
            response.json().then((data) => {
                if (data.success) {
                    return true;
                }
            });
        })
        .catch((error) => {
            console.error(error);
        });
    return success;
};

export const postChanges = async (versionGuid: string, sessionID: string, rowsParameter: { layerId: number, objectIds: number[] }[]): Promise<boolean> => {
    let success = false;

    const versionServiceUrl = getVersionServiceUrl();
    const token = await getAGEToken();
    const urlSessionID = encodeURIComponent(sessionID);

    const urlEncodedRowsParameter = encodeURIComponent(JSON.stringify(rowsParameter));

    await fetch(versionServiceUrl + `/versions/${versionGuid}/post?f=json&sessionId=${urlSessionID}&rows=${urlEncodedRowsParameter}`,
        {
            method: 'POST',
            headers: {
                'X-Esri-Authorization': 'Bearer ' + token
            }
        }).then(async (response) => {
            await response.json().then((data) => {
                if (data.success) {
                    success = true;
                    return true;
                }
            });
        }).catch((error) => {
            console.error(error);
        });


    return success;
}

export const alterVersion = async (
    versionGuid: string,
    newOwnerName?: string,
    newVersionName?: string,
    newDescription?: string
): Promise<boolean> => {
    let success = false;
    const versionServiceUrl = getVersionServiceUrl();
    const token = await getAGEToken();

    let paramString = "";
    if (newOwnerName) {
        paramString += `&ownerName=${newOwnerName}`;
    }

    if (newVersionName) {
        paramString += `&versionName=${newVersionName}`;
    }

    if (newDescription) {
        paramString += `&description=${newDescription}`;
    }

    await fetch(versionServiceUrl + `/versions/${versionGuid}/alter?f=json${paramString}`, {
        method: "POST",
        headers: {
            "X-Esri-Authorization": "Bearer " + token,
        },
    })
        .then(async (response) => {
            await response.json().then((data) => {
                if (data.success) {
                    success = true;
                    return true;
                }
            });
        })
        .catch((error) => {
            console.error(error);
        });
    return success;
};

const getVersionServiceUrl = () => {
    return AppConfig.ArcGIS.BaseUrl + AppConfig.ArcGIS.VersionManagementService;
};

export const findDifferences = async (versionGuid: string, activeSession?: string): Promise<OrganizedObjects[]> => {
    const sessionID = activeSession ?? getSessionID();
    await startReading(versionGuid, sessionID);
    await startEditing(versionGuid, sessionID);
    await reconcileVersion(versionGuid, sessionID, false, "byObject", false);
    const editedFeatures = await getDiffrences(versionGuid, sessionID);
    if (!editedFeatures) {
        await stopEditing(versionGuid, sessionID, false);
        await stopReading(versionGuid, sessionID);
        return [];
    }
    const jsonObjects = await getObjects(editedFeatures);
    // await restoreDeleted(4, 8983, versionGuid, jsonObjects, sessionID);
    await stopEditing(versionGuid, sessionID, false);
    await stopReading(versionGuid, sessionID);
    return jsonObjects;
};

interface DiffrencesObject {
    layerId: number;
    inserts: {
        geometry: __esri.Geometry;
        attributes: {
            [key: string]: any;
        };
    }[];
    updates: {
        geometry: __esri.Geometry;
        attributes: {
            [key: string]: any;
        };
    }[];
    deletes: {
        geometry: __esri.Geometry;
        attributes: {
            [key: string]: any;
        };
    }[];
}

export interface FeatureObject {
    type: "insert" | "update" | "delete";
    originalFeature?: {
        geometry: __esri.Geometry;
        attributes: {
            [key: string]: any;
        };
    };
    originalGraphic?: __esri.Graphic;
    editedFeature: {
        geometry: __esri.Geometry;
        attributes: {
            [key: string]: any;
        };
    };
    changedFields: string[];
}

export interface OrganizedObjects {
    layerId: number;
    objects: {
        [objectId: string]: FeatureObject;
    };
}

const getObjects = async (objectsToFetch: DiffrencesObject[]): Promise<OrganizedObjects[]> => {
    const objects: OrganizedObjects[] = [];
    const promisis: Promise<any>[] = [];

    for (const differences of objectsToFetch) {
        const layerId = differences.layerId;
        const featureLayer = new FeatureLayer({
            url: `${AppConfig.NauticalObjectsFeatureServer}${layerId}`,
        });

        const objectIds = [];
        const insertedFeatureIds: string[] = [];
        const featureMap: Record<string, { type: "insert" | "update" | "delete"; feature: any }> = {};
        if (differences.inserts) {
            for (const insert of differences.inserts) {
                insertedFeatureIds.push(insert.attributes.objectid);
                featureMap[insert.attributes.objectid] = {
                    type: "insert",
                    feature: insert,
                };
            }
        }
        if (differences.updates) {
            for (const update of differences.updates) {
                objectIds.push(update.attributes.objectid);
                featureMap[update.attributes.objectid] = {
                    type: "update",
                    feature: update,
                };
            }
        }
        if (differences.deletes) {
            for (const del of differences.deletes) {
                objectIds.push(del.attributes.objectid);
                featureMap[del.attributes.objectid] = {
                    type: "delete",
                    feature: del,
                };
            }
        }

        if (objectIds.length > 0) {
            promisis.push(
                featureLayer
                    .queryFeatures({
                        objectIds: objectIds,
                        outFields: ["*"],
                        returnGeometry: true,
                    })
                    .then((result) => {
                        const organizedLayer: OrganizedObjects = {
                            layerId: layerId,
                            objects: {},
                        };
                        for (const feature of result.features) {
                            const edited = featureMap[feature.attributes.objectid];
                            const changedFields: string[] = [];
                            for (const key in feature.attributes) {
                                if (feature.attributes[key] !== edited.feature.attributes[key]) {
                                    if (key === "oppdatert_av" || key === "oppdatert_dato") {
                                        continue;
                                    }
                                    changedFields.push(key);
                                }
                            }
                            if (changedFields.length === 0) {
                                continue;
                            }
                            organizedLayer.objects[feature.attributes.objectid] = {
                                type: edited.type,
                                originalFeature: {
                                    geometry: feature.geometry,
                                    attributes: feature.attributes,
                                },
                                originalGraphic: feature,
                                editedFeature: {
                                    geometry: edited.feature.geometry,
                                    attributes: edited.feature.attributes,
                                },
                                changedFields: changedFields,
                            };
                        }
                        for (const inserted of insertedFeatureIds) {
                            const edited = featureMap[inserted];
                            organizedLayer.objects[inserted] = {
                                type: edited.type,
                                originalFeature: undefined,
                                originalGraphic: undefined,
                                editedFeature: {
                                    geometry: edited.feature.geometry,
                                    attributes: edited.feature.attributes,
                                },
                                changedFields: Object.keys(edited.feature.attributes),
                            };
                        }

                        objects.push(organizedLayer);
                    })
                    .catch((error) => {
                        console.error(error);
                    })
            );
        } else {
            const organizedLayer: OrganizedObjects = {
                layerId: layerId,
                objects: {},
            };

            for (const inserted of insertedFeatureIds) {
                const edited = featureMap[inserted];
                organizedLayer.objects[inserted] = {
                    type: edited.type,
                    originalFeature: undefined,
                    originalGraphic: undefined,
                    editedFeature: { geometry: edited.feature.geometry, attributes: edited.feature.attributes },
                    changedFields: Object.keys(edited.feature.attributes),
                };
            }
            objects.push(organizedLayer);
        }
    }

    await Promise.all(promisis);

    return objects;
};

export const restoreAttributes = async (
    attributes: string[],
    feature: __esri.Graphic,
    versionChanges: OrganizedObjects[]
): Promise<__esri.Graphic> => {
    const newFeature = feature.clone();
    const layerId = (feature.layer as FeatureLayer).layerId;

    const versionChange = versionChanges.find((change) => {
        return change.layerId == layerId;
    });

    if (!versionChange) {
        return feature;
    }

    const originalFeature = versionChange.objects[newFeature.attributes.objectid].originalFeature;
    if (!originalFeature) {
        return feature;
    }

    for (const attribute of attributes) {
        if (newFeature.attributes.hasOwnProperty(attribute)) {
            newFeature.attributes[attribute] = originalFeature.attributes[attribute];
        }
    }

    const result = await (feature.layer as FeatureLayer).applyEdits({ updateFeatures: [feature] });
    if (result.updateFeatureResults[0].error) {
        console.error("Error updating feature: ", result.updateFeatureResults[0].error);
    } else {
        return newFeature;
    }

    return feature;
};

export const restoreGeometry = async (
    feature: __esri.Graphic,
    versionChanges: OrganizedObjects[]
): Promise<__esri.Graphic> => {
    const newFeature = feature.clone();
    const layerId = (feature.layer as FeatureLayer).layerId;

    const versionChange = versionChanges.find((change) => {
        return change.layerId == layerId;
    });

    if (!versionChange) {
        return feature;
    }

    const originalFeature = versionChange.objects[newFeature.attributes.objectid].originalFeature;
    if (!originalFeature) {
        return feature;
    }

    newFeature.geometry = originalFeature.geometry;

    const result = await (feature.layer as FeatureLayer).applyEdits({ updateFeatures: [feature] });
    if (result.updateFeatureResults[0].error) {
        console.error("Error updating feature: ", result.updateFeatureResults[0].error);
    } else {
        return newFeature;
    }

    return feature;
};

export const restoreAllFromFeatureObject = async (featureObject: FeatureObject, versionChanges: OrganizedObjects[]): Promise<__esri.Graphic | undefined> => {
    const mapView = getMapView();
    if (!mapView) {
        return;
    }
    const originalGraphic = featureObject.originalGraphic;
    if (!originalGraphic) {
        return
    };
    let allLayers = mapView.map.allLayers.toArray();
    allLayers = allLayers.concat(mapView.map.allTables.toArray());
    let existingLayer
    for (const layer of allLayers) {
        if ((layer as FeatureLayer).layerId == (originalGraphic.layer as FeatureLayer).layerId) {
            if ((layer as FeatureLayer).url == (originalGraphic.layer as FeatureLayer).url) {
                existingLayer = layer as FeatureLayer;
                break;
            }
        }
    }
    if (!existingLayer) {
        return;
    }

    const existingFeature = await existingLayer.queryFeatures({
        where: `objectid = ${originalGraphic.attributes.objectid}`,
        outFields: ['*'],
    });
    if (existingFeature.features.length === 0) {
        return;
    }
    const featureGraphic = existingFeature.features[0];

    const restoredGraphic = await restoreAll(featureGraphic, versionChanges);

    return restoredGraphic;
}

export const restoreAll = async (
    feature: __esri.Graphic,
    versionChanges: OrganizedObjects[]
): Promise<__esri.Graphic> => {
    const newFeature = feature.clone();
    const layerId = (feature.layer as FeatureLayer).layerId;

    const versionChange = versionChanges.find((change) => {
        return change.layerId == layerId;
    });

    if (!versionChange) {
        return feature;
    }

    const originalFeature = versionChange.objects[newFeature.attributes.objectid].originalFeature;
    if (!originalFeature) {
        return feature;
    }

    newFeature.geometry = originalFeature.geometry;
    for (const key in newFeature.attributes) {
        newFeature.attributes[key] = originalFeature.attributes[key];
    }

    const result = await (feature.layer as FeatureLayer).applyEdits({ updateFeatures: [newFeature] });
    if (result.updateFeatureResults[0].error) {
        console.error("Error updating feature: ", result.updateFeatureResults[0].error);
    } else {
        return newFeature;
    }

    return feature;
};

export const removeInserted = async (feature: __esri.Graphic, versionChanges: OrganizedObjects[]): Promise<boolean> => {
    const layerId = (feature.layer as FeatureLayer).layerId;

    const versionChange = versionChanges.find((change) => {
        return change.layerId == layerId;
    });

    if (!versionChange) {
        return false;
    }

    const featureChange = versionChange.objects[feature.attributes.objectid];
    if (featureChange.type == "insert") {
        const result = await (feature.layer as FeatureLayer).applyEdits({ deleteFeatures: [feature] });
        if (result.deleteFeatureResults[0].error) {
            console.error("Error deleting feature: ", result.deleteFeatureResults[0].error);
            return false;
        } else {
            return true;
        }
    }

    return false;
};

// // To do replace layerid and objectid with featureobject type
// // To do investigate the possibility of restoring the object identically to the original
// /**
//  * This does not work properly as the restored object is not identical to the original
//  * @param layerId
//  * @param objectId
//  * @param versionName
//  * @param versionChanges
//  * @returns
//  */
// export const restoreDeleted = async (layerId: number, objectId: string, versionName: string, versionChanges: OrganizedObjects[]): Promise<__esri.Graphic | null> => {
//     const featureLayer = new FeatureLayer({
//         url: `https://vmkyst03.azure.geodata.no/server/rest/services/BV/Branch_Versioning_DEV/FeatureServer/${layerId}`,
//         gdbVersion: versionName
//     })

//     const versionChange = versionChanges.find((change) => {
//         return change.layerId == layerId.toString()
//     });

//     if (!versionChange) {
//         return null;
//     }

//     const featureChange = versionChange.objects[objectId];
//     if (featureChange.type == "delete") {
//         const removedFeature = featureChange.originalGraphic;
//         if (!removedFeature) {
//             return null;
//         }
//         const result = await featureLayer.applyEdits({ addFeatures: [removedFeature] }, { globalIdUsed: true });
//         if (result.addFeatureResults[0].error) {
//             console.error("Error adding feature: ", result.addFeatureResults[0].error);
//             return null;
//         } else {
//             return removedFeature;
//         }
//     }

//     return null;
// };

// This does not work properly as the restoreRows endpoint throws an error working on a fix or workaround
export const restoreDeleted = async (
    layerId: number,
    objectId: number,
    versionGuid: string,
    versionChanges: OrganizedObjects[],
    sessionId?: string
): Promise<__esri.Graphic | null> => {
    const versionServiceUrl = getVersionServiceUrl();
    const token = await getAGEToken();
    const changedVersion = versionChanges.find((change) => {
        return change.layerId == layerId;
    });
    if (!changedVersion) {
        return null;
    }
    let restoredFeature = changedVersion.objects[objectId].originalGraphic;
    if (!restoredFeature) {
        return null;
    }

    const restoreInfo = [
        {
            layerId: layerId,
            objectIds: [objectId],
        },
    ];

    const urlEncodedRestoreInfo = encodeURIComponent(JSON.stringify(restoreInfo));

    let activeSession = sessionId;
    if (!activeSession) {
        activeSession = getSessionID();
        await startReading(versionGuid, activeSession);
        await startEditing(versionGuid, activeSession);
    }

    activeSession = encodeURIComponent(activeSession);

    await fetch(
        versionServiceUrl +
        `/versions/${versionGuid}/restoreRows?f=json&sessionId=${activeSession}&rows=${urlEncodedRestoreInfo}`,
        {
            method: "POST",
            headers: {
                "X-Esri-Authorization": "Bearer " + token,
            },
        }
    )
        .then(async (response) => {
            await response.json().then((data) => {
                if (!data.success) {
                    restoredFeature = undefined;
                }
            });
        })
        .catch((error) => {
            console.error(error);
            restoredFeature = undefined;
        });

    if (!sessionId) {
        await stopEditing(versionGuid, activeSession, false);
        await stopReading(versionGuid, activeSession);
    }

    return restoredFeature;
};

export const isMyVersion = (versionInfo: __esri.VersionInfoJSON, user: __esri.PortalUser): boolean => {
    let isMyVersion = false;
    const versionOwner = getVersionOwner(versionInfo).toLowerCase();
    const userName = user.username.toLowerCase();
    if (versionOwner === userName) {
        isMyVersion = true;
    }
    return isMyVersion;
};

export const isDefaultVersion = (versionInfo: __esri.VersionInfoJSON | __esri.VersionInfoExtendedJSON | null, versionManagementService: VersionManagementService): boolean => {
    if (versionInfo === null) {
        return false;
    }
    if (versionInfo.versionIdentifier.guid === versionManagementService.defaultVersionIdentifier.guid) {
        return true;
    }
    return false;
}

export const getVersionName = (versionInfo: __esri.VersionInfoJSON | __esri.VersionInfoExtendedJSON | null): string => {
    if (versionInfo === null) {
        return "Fant ingen versjon";
    }
    let versionName = versionInfo.versionIdentifier.name;
    versionName = versionName.split("").reverse().join("");
    versionName = versionName.slice(0, versionName.indexOf("."));

    return versionName.split("").reverse().join("");
};

export const getVersionOwner = (
    versionInfo: __esri.VersionInfoJSON | __esri.VersionInfoExtendedJSON | null
): string => {
    if (versionInfo === null) {
        return "Fant ingen versjon";
    }
    let versionName = getVersionName(versionInfo);
    let versionOwner = versionInfo.versionIdentifier.name.replace("." + versionName, "");
    return versionOwner;
};

export const canEditVersion = async (
    versionInfo: __esri.VersionInfoJSON | __esri.VersionInfoExtendedJSON | null,
    versionsSharedWithMe: string[] | null,
    user?: __esri.PortalUser | null
): Promise<boolean> => {
    if (versionInfo === null || versionsSharedWithMe === null) {
        return false;
    }
    if (user === null || user === undefined) {
        return false;
    }

    const versionOwner = getVersionOwner(versionInfo);
    const userName = user.username;

    if (versionOwner.toLowerCase() === userName.toLowerCase()) {
        return true;
    }

    if (versionsSharedWithMe.includes(versionInfo.versionIdentifier.guid)) {
        return true;
    }

    return false;
};

export const getVersionsSharedWithMe = async (user: __esri.PortalUser): Promise<string[]> => {
    const versionSharedWithMe: string[] = [];
    let mapView = getMapView();
    let attemptsMapview = 0;
    while (!mapView && attemptsMapview < 3) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        mapView = getMapView();
        attemptsMapview++;
    }
    if (!mapView) {
        return [];
    }

    let versionAccessTable = mapView.map.findTableById("Versjonstilgang") as __esri.FeatureLayer;
    let attempts = 0;
    while (!versionAccessTable && attempts < 3) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        versionAccessTable = mapView.map.findTableById("Versjonstilgang") as __esri.FeatureLayer;
        attempts++;
    }
    if (!versionAccessTable) {
        return [];
    }

    const query = versionAccessTable.createQuery();
    query.outFields = ["*"];
    query.where = `epost LIKE '${user.email}'`;

    const result = await versionAccessTable.queryFeatures(query);
    if (result.features.length > 0) {
        result.features.forEach((feature) => {
            versionSharedWithMe.push(feature.attributes.versjon_id);
        });
    }
    return versionSharedWithMe;
};

export const getVersionSharedWith = async (
    version: __esri.VersionInfoJSON | __esri.VersionInfoExtendedJSON
): Promise<__esri.Graphic[]> => {
    const versionSharedWith: __esri.Graphic[] = [];
    let mapView = getMapView();
    let attemptsMapview = 0;
    while (!mapView && attemptsMapview < 3) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        mapView = getMapView();
        attemptsMapview++;
    }
    if (!mapView) {
        return [];
    }

    let versionAccessTable = mapView.map.findTableById("Versjonstilgang") as __esri.FeatureLayer;
    let attempts = 0;
    while (!versionAccessTable && attempts < 3) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        versionAccessTable = mapView.map.findTableById("Versjonstilgang") as __esri.FeatureLayer;
        attempts++;
    }
    if (!versionAccessTable) {
        return [];
    }

    const query = versionAccessTable.createQuery();
    query.outFields = ["*"];
    query.where = `versjon_id LIKE '${version.versionIdentifier.guid}'`;

    const result = await versionAccessTable.queryFeatures(query);
    if (result.features.length > 0) {
        return result.features;
    }
    return versionSharedWith;
};

export const accectVersionAccess = async (
    versionInfo: __esri.VersionInfoExtendedJSON | null,
    user: __esri.PortalUser
): Promise<string[]> => {
    const versionSharedWithMe: string[] = [];
    let mapView = getMapView();
    let attemptsMapview = 0;
    while (!mapView && attemptsMapview < 3) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        mapView = getMapView();
        attemptsMapview++;
    }
    if (!mapView) {
        return [];
    }

    let versionAccessTable = mapView.map.findTableById("Versjonstilgang") as __esri.FeatureLayer;
    let attempts = 0;
    while (!versionAccessTable && attempts < 3) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        versionAccessTable = mapView.map.findTableById("Versjonstilgang") as __esri.FeatureLayer;
        attempts++;
    }
    if (!versionAccessTable) {
        return [];
    }
    await versionAccessTable.applyEdits({
        addFeatures: [
            new Graphic({
                attributes: {
                    epost: user.email,
                    versjon_id: versionInfo?.versionIdentifier.guid,
                },
            }),
        ],
    });

    const query = versionAccessTable.createQuery();
    query.outFields = ["*"];
    query.where = `epost LIKE '${user.email}'`;

    const result = await versionAccessTable.queryFeatures(query);
    if (result.features.length > 0) {
        result.features.forEach((feature) => {
            versionSharedWithMe.push(feature.attributes.versjon_id);
        });
    }
    return versionSharedWithMe;
};

export const removeVersionAccess = async (accessVersionGlobalId: string): Promise<boolean> => {
    let removalResult = false;
    let mapView = getMapView();
    let attemptsMapview = 0;
    while (!mapView && attemptsMapview < 3) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        mapView = getMapView();
        attemptsMapview++;
    }
    if (!mapView) {
        return false;
    }

    let versionAccessTable = mapView.map.findTableById("Versjonstilgang") as __esri.FeatureLayer;
    let attempts = 0;
    while (!versionAccessTable && attempts < 3) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        versionAccessTable = mapView.map.findTableById("Versjonstilgang") as __esri.FeatureLayer;
        attempts++;
    }
    if (!versionAccessTable) {
        return false;
    }

    await versionAccessTable
        .applyEdits(
            {
                deleteFeatures: [{ globalId: accessVersionGlobalId }],
            },
            {
                globalIdUsed: true,
            }
        )
        .then((result) => {
            if (result.deleteFeatureResults[0].error) {
                console.error("Error deleting feature: ", result.deleteFeatureResults[0].error);
                return false;
            } else {
                removalResult = true;
            }
        })
        .catch((error) => {
            console.error(error);
            return false;
        });

    return removalResult;
};

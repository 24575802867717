"use client";

import React, { useMemo } from "react";
import "./App.css";
import "@arcgis/core/assets/esri/themes/light/main.css";
import { UserContext, UserContextType } from "./Context";
import { AuthenticatedContent } from "./components/AuthenticatedContent/AuthenticatedContent";
import { LoginScreen } from "./components/Login/Login";

import { defineCustomElements } from "@arcgis/map-components/dist/loader";
import { ErrorBoundary } from "react-error-boundary";
defineCustomElements(window, { resourcesUrl: "https://js.arcgis.com/map-components/4.31/assets" });

function App() {
    const [user, setUser] = React.useState<__esri.PortalUser>();
    const [self, setSelf] = React.useState<any>();
    const userContextStore = {
        user: {
            set: setUser,
            value: user,
        },
        userSelf: {
            set: setSelf,
            value: self,
        },
    } as UserContextType;

    const memoStore = useMemo(() => userContextStore, [userContextStore]);

    const [onErrorError, setOnErrorError] = React.useState<any>();
    const [errorStack, setErrorStack] = React.useState<React.ErrorInfo>();
    function fallbackRender({ error, resetErrorBoundary }: any) {
        return (
            <div className="error-container" role="alert">
                <p>Noe har gått veldig galt.</p>
                <p>Inkluder hele teksten under når du rapporterer feilen.</p>
                <pre style={{ color: "red" }}>{error.message}{error.stack}</pre>
                <pre style={{ color: "red" }}>{onErrorError?.message}{errorStack?.componentStack}</pre>

                <button className="fit-content" onClick={resetErrorBoundary}>Prøv igjen</button>
                <span>Om det ikke virker kan du laste siden på nytt</span>
                <button className="fit-content" onClick={() => window.location.reload()}>Last siden på nytt</button>
            </div>
        );
    }

    return (
        <ErrorBoundary fallbackRender={fallbackRender} onError={(error, info) => { setOnErrorError(error); setErrorStack(info) }}>
            <UserContext.Provider value={memoStore}>
                {memoStore.user.value ? <AuthenticatedContent /> : <LoginScreen />}
            </UserContext.Provider>
        </ErrorBoundary>
    );
}

export default App;

import { isNumber } from "../../../utils/helpers";
import "./InputField.css";

interface InputFieldProps {
    inputFieldId: string;
    label: string;
    value: string;
    type: string;
    onChange: (value: string) => void;
    onBlur?: (value: string) => void;
    onFocus?: (value: string) => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    readonly?: boolean;
    isTextArea?: boolean;
    textAreaRows?: number;
    w100?: boolean;
    hasError?: boolean;
    placeholder?: string;
    min?: number;
    max?: number;
    step?: number;
    maxLength?: number;
    valueSuffix?: string;
}

const InputField = (props: InputFieldProps) => {
    return (
        <label htmlFor={props.inputFieldId} className={`input-field-label ${props.w100 ? "w-100" : ""}`}>
            {props.label}
            <div
                className={`input-field-container ${props.readonly ? "readonly" : ""} ${props.valueSuffix ?? ""} ${props.hasError ? "error" : ""}`}
            >
                {!props.isTextArea && (
                    <input
                        id={props.inputFieldId}
                        readOnly={props.readonly}
                        placeholder={props.placeholder}
                        onChange={(event) => {
                            const value = event.target.value;
                            if (props.type === "number" && !isNumber(value)) {
                                event.stopPropagation();
                                return;
                            }
                            props.onChange(value);
                        }}
                        onFocus={(event) => props.onFocus?.(event.target.value)}
                        onBlur={(event) => props.onBlur?.(event.target.value)}
                        onMouseEnter={props.onMouseEnter}
                        onMouseLeave={props.onMouseLeave}
                        value={props.value}
                        className="input-field"
                        type={props.type}
                        min={props.min}
                        max={props.max}
                        step={props.step}
                        maxLength={props.maxLength}
                    />
                )}
                {props.isTextArea && (
                    <textarea
                        id={props.inputFieldId}
                        readOnly={props.readonly}
                        onChange={(event) => props.onChange(event.target.value)}
                        value={props.value}
                        className="input-field"
                        rows={props.textAreaRows ?? 5}
                    />
                )}
            </div>
        </label>
    );
};

export default InputField;

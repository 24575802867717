import ArrowDownIcon from "../../../assets/arrow-down.svg?react";
import "./Collapsible.css";

interface CollapsibleProps {
    children?: React.ReactNode;
    title: string;
    icon?: React.ReactNode;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    onToggle?: () => void;
}

const Collapsible = (props: CollapsibleProps) => {
    const { children, title, icon, isOpen, setIsOpen, onToggle } = props;

    const clickHandler = () => {
        setIsOpen(!isOpen);
        if (onToggle) {
            onToggle();
        }
    };

    return (
        <div className="nfs-collapsible-container">
            <button className="nfs-collapsible-title" onClick={clickHandler}>
                <div className="flex-row small-gap center">
                    {icon}
                    <span>{title}</span>
                </div>
                <ArrowDownIcon className={isOpen ? "rotate-180" : ""} />
            </button>
            {isOpen && <div className="nfs-collapsible-content">{children}</div>}
        </div>
    );
};

export default Collapsible;

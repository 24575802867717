import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../Context";
import { WorkPaginatedResult } from "../../../types/workOrderTypes";
import { getWorkOrdersForObject } from "../../../utils/FDVUtils/workOrderUtils";

import ArrowDownIcon from "../../../assets/arrow-down.svg?react";
import OpenInNewWindowIcon from "../../../assets/openInNew.svg?react";

import "./FeatureWorkOrderGroup.css";
import { AppConfig } from "../../../AppConfig";

const FeatureWorkOrderGroup = () => {

    const [open, setOpen] = useState(false);
    const [workOrders, setWorkOrders] = useState<WorkPaginatedResult>();

    const selectedFeatureRef = useRef<__esri.Graphic | null | undefined>();

    const appContext = useContext(AppContext);
    const selectedFeature = appContext?.selectedFeature.value;

    useEffect(() => {

        if (selectedFeatureRef.current !== selectedFeature) {
            setWorkOrders(undefined);
            selectedFeatureRef.current = selectedFeature;
        }


        if (selectedFeature) {
            const gisObjectId = selectedFeature.attributes["gis_obj_id"];
            if (!gisObjectId || gisObjectId === 0) return;
            getWorkOrdersForObject({ page: 1, pageSize: 200, gisObjId: gisObjectId, statusCodes: "R" }).then((result) => {
                setWorkOrders(result);
            });
        } else {
            setWorkOrders(undefined);
        }
    }, [selectedFeature]);

    if (workOrders === undefined) return null;
    if (workOrders.items?.length === 0) return null;

    return (
        <>
            <button className="feature-work-order-group" onClick={() => setOpen(!open)}>
                <span className="feature-work-order-group-title">Arbeidsordre {"(" + workOrders.items?.length + ")"}</span>
                <ArrowDownIcon style={{ rotate: open ? "180deg" : "" }} />
            </button>
            {open &&
                <div>
                    {workOrders && workOrders.items?.length! > 0 ? (
                        <div className="feature-work-order-group-list">
                            {workOrders.items?.map((wo) => (
                                <div key={wo.workOrderID} className="feature-work-order-group-list-item">
                                    <span>{wo.workOrderID}-{wo.workOrderType}</span>
                                    <span className="flex-row w-100 center">
                                        <span className="flex-row w-100">{wo.workOrderDescription}</span>
                                        <a href={`${AppConfig.FDV.BaseUrl}${AppConfig.FDV.WorkOrderBase}${wo.workOrderID}`} target="_blank" rel="noopener" className="fit-content empty-button"><OpenInNewWindowIcon /></a>
                                    </span>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="feature-work-order-group-no-items">Ingen arbeidsordre</div>
                    )}
                </div>
            }
        </>
    );
}

export default FeatureWorkOrderGroup;
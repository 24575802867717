import { useState, useRef, useEffect, JSXElementConstructor, ReactElement, ReactNode, ReactPortal } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/rootReducer";

interface RenderInWindowProps {
    children:
        | string
        | number
        | boolean
        | ReactElement<any, string | JSXElementConstructor<any>>
        | Iterable<ReactNode>
        | ReactPortal
        | null
        | undefined;
    width?: number;
    height?: number;
    left?: number;
    top?: number;

    disabled?: boolean;
    onClose?: () => void;
}

const RenderInWindow = (props: RenderInWindowProps) => {
    const [container, setContainer] = useState<HTMLDivElement | null>(null);
    const newWindow = useRef<WindowProxy | null>(null);

    const disabledRef = useRef<boolean>(props.disabled ?? false);
    const hasRun = useRef<boolean>(false);

    const darkmode = useSelector((state: StoreState) => state.app.darkmode);

    useEffect(() => {
        // Create container element on client-side
        if (props.disabled) {
            return;
        }
        setContainer(document.createElement("div"));
    }, []);

    useEffect(() => {
        disabledRef.current = props.disabled ?? false;
        if (!props.disabled) {
            setContainer(document.createElement("div"));
        }
    }, [props.disabled]);

    useEffect(() => {
        // When container is ready
        if (container) {
            // Create window
            newWindow.current = window.open(
                "",
                "",
                `width=${props.width ?? 600},height=${props.height ?? 400},left=${props.left ?? 200},top=${
                    props.top ?? 200
                }`
            );

            try {
                // Trick to check if popup is blocked
                // @ts-ignore
                newWindow.current.focus();
            } catch (e) {
                alert("Popup er blokkert av nettleseren. Vennligst tillat popup for denne siden og prøv igjen.");
                props.onClose?.();
            }

            // Append container
            newWindow.current?.document.write(
                "<html><head>" + window.document.head.innerHTML + `</head><body class="${darkmode ? "dark" : "light"}">`
            );
            newWindow.current?.document.body.appendChild(container);

            // Save reference to window for cleanup
            const curWindow = newWindow.current;

            curWindow?.addEventListener("keydown", (e) => {
                if (e.key === "F5") {
                    e.preventDefault();
                    e.stopPropagation();
                }
                if (
                    (e.ctrlKey && e.key === "r") ||
                    (e.metaKey && e.key === "r") ||
                    (e.ctrlKey && e.key === "R") ||
                    (e.metaKey && e.key === "R")
                ) {
                    e.preventDefault();
                    e.stopPropagation();
                }
            });

            curWindow?.addEventListener("beforeunload", function () {
                if (disabledRef.current && !hasRun.current) {
                    hasRun.current = true;
                    props.onClose?.();
                }
            });

            window?.addEventListener("beforeunload", function () {
                // e.preventDefault();
                // e.returnValue = '';
                curWindow?.close();
            });

            // Return cleanup function
            return () => {
                curWindow?.close();
            };
        }
    }, [container]);

    if (props.disabled) {
        newWindow.current?.close();
        return props.children as JSX.Element;
    }

    return container && createPortal(props.children, container);
};

export default RenderInWindow;

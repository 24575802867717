import { SectorColor } from "../../../utils/lighthouseUtils";
import NfsDropdown from "../../genericComponents/NfsDropdown/NfsDropdown";
import SectorColorIndicator from "./SectorColorIndicator";

interface SectorColorAttributeProps {
    label: string;
    value: SectorColor;
    setSelectedOption: (option: string | null) => void;
}

const SectorColorAttribute = (props: SectorColorAttributeProps) => {
    const { label, value, setSelectedOption } = props;

    const colorTranslations = { WHITE: "Hvit", RED: "Rød", GREEN: "Grønn", DARK: "Mørk" };

    const displayValueFunction = (option: string | number) => {
        const color = SectorColor[option as keyof typeof SectorColor];
        return (
            <div className="flex-row small-gap center">
                <SectorColorIndicator color={color} height="1rem" />
                {colorTranslations[option as keyof typeof colorTranslations]}
            </div>
        );
    };

    return (
        <div className="sector-attribute">
            <span className="sector-label">{label}</span>
            <NfsDropdown
                options={Object.keys(SectorColor)}
                selectedOption={Object.entries(SectorColor).find(([, v]) => v === value)?.[0] ?? null}
                setSelectedOption={setSelectedOption}
                placeholder=""
                displayValueFunction={displayValueFunction}
            />
            <div className="sector-unit"></div>
        </div>
    );
};

export default SectorColorAttribute;

import Circle from "@arcgis/core/geometry/Circle";
import * as labelPointOperator from "@arcgis/core/geometry/operators/labelPointOperator.js";
import Point from "@arcgis/core/geometry/Point";
import Polygon from "@arcgis/core/geometry/Polygon";
import Polyline from "@arcgis/core/geometry/Polyline";
import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import MapView from "@arcgis/core/views/MapView";
import { MutableRefObject } from "react";
import { computeTrueNorth, highlightFeature } from "../../../utils/arcgisUtils";
import { createSector, LighthouseData, sanitizeSectors } from "../../../utils/lighthouseUtils";

export const lighthouseHandlerPointerMove = (
    view: MapView,
    currentLine: React.MutableRefObject<Graphic | null>,
    isDragging: React.MutableRefObject<boolean>,
    isCenterSelected: React.MutableRefObject<boolean>
) => {
    const lighthouseLayer = view.map.findLayerById("Fyrlykt");
    const lighthouseEditingLayer = view.map.findLayerById("FyrlyktEditing");

    return view.on("pointer-move", (event) => {
        // Refresh overlay graphics
        const overlayGraphicsLayer = view.map.findLayerById("overlayGraphicsLayer") as __esri.GraphicsLayer;
        if (overlayGraphicsLayer) {
            overlayGraphicsLayer.removeAll();
        }
        const excluded = [lighthouseLayer, lighthouseEditingLayer, view.graphics];
        if (overlayGraphicsLayer) {
            excluded.push(overlayGraphicsLayer.graphics);
        }
        view.hitTest(event, { exclude: excluded }).then(() => {
            if (!isDragging.current) currentLine.current = null;
        });

        view.hitTest(event, { include: lighthouseEditingLayer }).then((response) => {
            handleHitTestResponse(view, response, currentLine, isDragging, isCenterSelected);
        });
    });
};

const handleHitTestResponse = (
    view: MapView,
    response: __esri.HitTestResult,
    currentLine: React.MutableRefObject<Graphic | null>,
    isDragging: React.MutableRefObject<boolean>,
    isCenterSelected: React.MutableRefObject<boolean>
) => {
    const results = response.results;

    view.popupEnabled = results.length === 0;

    if (results.length > 0 && results[0].layer.id === "Fyrlykt") {
        view.container.style.cursor = "pointer";
        return;
    }

    if (!isCenterSelected.current) {
        updateCurrentLine(results as __esri.GraphicHit[], currentLine, isDragging);
        addOverlayGraphics(view, results as __esri.GraphicHit[], isDragging);
    }

    if (view.container.style.cursor !== "move") {
        updateCursorStyle(view, results as __esri.GraphicHit[]);
    }
};

const updateCurrentLine = (
    results: __esri.GraphicHit[],
    currentLine: React.MutableRefObject<Graphic | null>,
    isDragging: React.MutableRefObject<boolean>
) => {
    if (!results || results.length === 0) return;

    const lines = results.filter((result) => result.graphic.attributes?.objectType === "line");

    if (lines.length > 0 && !isDragging.current) {
        currentLine.current = lines[0].graphic ?? null;
    } else if (!isDragging.current) {
        currentLine.current = null;
    }
};

const addOverlayGraphics = (
    view: MapView,
    results: __esri.GraphicHit[],
    isDragging: React.MutableRefObject<boolean>
) => {
    if (!results || results.length === 0) return;
    let overlayGraphicsLayer = view.map.findLayerById("overlayGraphicsLayer") as __esri.GraphicsLayer;
    if (!overlayGraphicsLayer) {
        overlayGraphicsLayer = new GraphicsLayer({
            id: "overlayGraphicsLayer",
            title: "Overlay Graphics",
        });
        view.map.add(overlayGraphicsLayer);
    }
    const lines = results.filter((result) => result.graphic.attributes?.objectType === "line");

    if (lines.length > 0) {
        const newGraphic = lines[0].graphic.clone();

        const dragHandleGraphic = new Graphic({
            geometry: new Circle({
                center: new Point({
                    x: (newGraphic.geometry as Polyline).paths[0][1][0],
                    y: (newGraphic.geometry as Polyline).paths[0][1][1],
                    spatialReference: view.spatialReference,
                }),
                radius: 10,
                spatialReference: view.spatialReference,
                geodesic: false,
            }),
            symbol: new SimpleMarkerSymbol({
                style: "circle",
                color: [0, 0, 0, 1],
                size: 10,
            }),
            attributes: {
                objectType: "dragHandle",
            },
        });
        (newGraphic.symbol as SimpleLineSymbol).width = 2;
        overlayGraphicsLayer.add(newGraphic);
        overlayGraphicsLayer.add(dragHandleGraphic);
    } else if (lines.length === 0 && !isDragging.current) {
        const plusSign = new Graphic({
            geometry: labelPointOperator.execute(results[0].graphic.geometry as Polygon),
            symbol: new SimpleMarkerSymbol({
                style: "cross",
                color: [0, 0, 0, 1],
                size: 10,
                outline: new SimpleLineSymbol({ color: [0, 0, 0, 1], width: 2 }),
            }),
            attributes: {
                objectType: "addSectorSymbol",
                index: results[0].graphic.attributes?.index,
                id: results[0].graphic.attributes?.id,
            },
        });
        overlayGraphicsLayer.add(plusSign);
    }
};

const updateCursorStyle = (view: MapView, results: __esri.GraphicHit[]) => {
    if (!results) return;
    const lines = results.filter((result) => result.graphic.attributes?.objectType === "line");

    if (lines.length > 0) {
        const angleDeg = calculateAngle(lines[0].graphic.geometry as Polyline);
        setCursorStyle(view, angleDeg);
    } else if (results.length === 0) {
        view.container.style.cursor = "default";
    }
};

const calculateAngle = (geometry: Polyline): number => {
    const lineCoords = geometry.paths[0];
    const angle = Math.atan2(lineCoords[1][1] - lineCoords[0][1], lineCoords[1][0] - lineCoords[0][0]);
    return ((angle * 180) / Math.PI + 360) % 360;
};

const setCursorStyle = (view: MapView, angleDeg: number) => {
    if (angleDeg <= 22.5 || angleDeg > 337.5 || (angleDeg > 157.5 && angleDeg <= 202.5)) {
        view.container.style.cursor = "ns-resize";
    } else if ((angleDeg > 22.5 && angleDeg <= 67.5) || (angleDeg > 202.5 && angleDeg <= 247.5)) {
        view.container.style.cursor = "nwse-resize";
    } else if ((angleDeg > 67.5 && angleDeg <= 112.5) || (angleDeg > 247.5 && angleDeg <= 292.5)) {
        view.container.style.cursor = "ew-resize";
    } else if ((angleDeg > 112.5 && angleDeg <= 157.5) || (angleDeg > 292.5 && angleDeg <= 337.5)) {
        view.container.style.cursor = "nesw-resize";
    }
};

export const lighthouseOverlayHandler = (view: MapView) => {
    return view.on("pointer-move", (event) => {
        view.hitTest(event, { include: view.graphics }).then((response) => {
            const resultDragHandle = response.results.filter(
                (result) => (result as __esri.GraphicHit).graphic.attributes?.objectType === "dragHandle"
            );
            const resultAddSector = response.results.filter(
                (result) => (result as __esri.GraphicHit).graphic.attributes?.objectType === "addSectorSymbol"
            );

            if (resultDragHandle.length > 0) {
                view.container.style.cursor = "move";
            } else if (/* resultSectorLine.length > 0 &&  */ resultAddSector.length > 0) {
                view.container.style.cursor = "pointer";
            } else {
                view.container.style.cursor = "default";
            }
        });
    });
};

export const lighthouseHandlerClickSector = (
    view: MapView,
    setLighthouseSectorPanelsOpen: ((value: Set<number>) => void) | undefined
) => {
    const lighthouseEditingLayer = view.map.layers.find((layer) => layer.id === "FyrlyktEditing");

    return view.on("click", (event) => {
        view.hitTest(event, { include: lighthouseEditingLayer }).then((response) => {
            const result = response.results.filter(
                (result) => (result as __esri.GraphicHit).graphic.attributes?.objectType === "sector"
            );

            if (result.length === 0) return;

            const sectorIndex = (result[0] as __esri.GraphicHit).graphic.attributes.index;
            setLighthouseSectorPanelsOpen!(new Set([sectorIndex]));
        });
    });
};

export const lighthouseHandlerAddSector = (
    view: MapView,
    lighthouseData: MutableRefObject<LighthouseData>,
    setLighthouseData: ((value: LighthouseData) => void) | undefined
) => {
    return view.on("click", (event) => {
        const overlayGraphicsLayer = view.map.findLayerById("overlayGraphicsLayer") as __esri.GraphicsLayer;
        const include = [];
        if (overlayGraphicsLayer) {
            include.push(overlayGraphicsLayer.graphics);
        }
        view.hitTest(event, { include: include }).then((response) => {
            const result = response.results.filter(
                (result) => (result as __esri.GraphicHit).graphic.attributes?.objectType === "addSectorSymbol"
            );

            if (result.length === 0) return;

            const newLighthouseData = createSector(
                lighthouseData.current,
                (result[0] as __esri.GraphicHit).graphic.attributes.index
            );
            lighthouseData.current = newLighthouseData;
            setLighthouseData!({ ...lighthouseData.current });

            // Remove the plus sign after adding a sector

            if (overlayGraphicsLayer) {
                overlayGraphicsLayer.remove((result[0] as __esri.GraphicHit).graphic);
            }
        });
    });
};

export const lighthouseHandlerPointerEditingLayer = (
    view: MapView,
    isCenterSelected: React.MutableRefObject<boolean>,
    isDraggingLine: React.MutableRefObject<boolean>
) => {
    const lighthouseEditingLayer = view.map.layers.find((layer) => layer.id === "FyrlyktEditing");
    let highlight: IHandle | void | null = null;

    return view.on("pointer-move", (event) => {
        view.hitTest(event, { include: lighthouseEditingLayer }).then((response) => {
            const resultsCenter = response.results.filter(
                (result) => (result as __esri.GraphicHit).graphic.attributes?.objectType === "center"
            );
            const resultsSector = response.results.filter(
                (result) => (result as __esri.GraphicHit).graphic.attributes?.objectType === "sector"
            );
            const resultsSectorLine = response.results.filter(
                (result) => (result as __esri.GraphicHit).graphic.attributes?.objectType === "line"
            );

            if (resultsCenter.length > 0 && !isDraggingLine.current) {
                view.container.style.cursor = "move";
                isCenterSelected.current = true;
                const overlayGraphicsLayer = view.map.findLayerById("overlayGraphicsLayer") as __esri.GraphicsLayer;
                if (overlayGraphicsLayer) {
                    overlayGraphicsLayer.removeAll();
                }
            } else {
                isCenterSelected.current = false;
            }

            if (highlight) {
                highlight.remove();
            }

            if (resultsSector.length > 0 && resultsSectorLine.length === 0 && !isDraggingLine.current) {
                view.container.style.cursor = "pointer";
                highlightFeature((resultsSector[0] as __esri.GraphicHit).graphic, lighthouseEditingLayer).then(
                    (result) => {
                        highlight = result;
                    }
                );
            } else if (resultsSector.length === 0 && resultsSectorLine.length === 0 && resultsCenter.length === 0) {
                view.container.style.cursor = "default";
            }
        });
    });
};

const dragStartHandler = (
    view: MapView,
    dragStartCursor: React.MutableRefObject<string>,
    currentLine: React.MutableRefObject<Graphic | null>,
    temporaryLine: React.MutableRefObject<Graphic | null>
) => {
    dragStartCursor.current = view.container.style.cursor;

    if (!currentLine.current) return;

    // Temporary line to show where the line currently being dragged was before starting dragging
    temporaryLine.current = new Graphic({
        geometry: currentLine.current.geometry,
        attributes: { objectType: "temporaryLine" },
        symbol: new SimpleLineSymbol({
            color: [0, 0, 0, 1],
            width: 1,
            style: "dash",
        }),
    });
};

interface DragHandlerParams {
    view: MapView;
    currentLine: React.MutableRefObject<Graphic | null>;
    temporaryLine: React.MutableRefObject<Graphic | null>;
    dragStartCursor: React.MutableRefObject<string>;
    lighthouseData: React.MutableRefObject<LighthouseData | null>;
    setLighthouseData: (value: LighthouseData) => void;
    isDraggingLine: React.MutableRefObject<boolean>;
    isDraggingCenter: React.MutableRefObject<boolean>;
    isCenterSelected: React.MutableRefObject<boolean>;
    setIsEditing: ((value: boolean) => void) | undefined;
    trueNorth: React.MutableRefObject<number>;
    setTrueNorth: (angle: number) => void;
}

const dragLogic = (event: __esri.ViewDragEvent, params: DragHandlerParams) => {
    const {
        view,
        currentLine,
        temporaryLine,
        dragStartCursor,
        lighthouseData,
        setLighthouseData,
        isDraggingLine,
        isDraggingCenter,
        isCenterSelected,
        setIsEditing,
        trueNorth,
        setTrueNorth,
    } = params;

    event.stopPropagation();

    setIsEditing!(true);

    const lighthouseLayer = view.map.layers.find((layer) => layer.id === "FyrlyktEditing") as GraphicsLayer;
    let overlayGraphicsLayer = view.map.findLayerById("overlayGraphicsLayer") as __esri.GraphicsLayer;
    if (!overlayGraphicsLayer) {
        overlayGraphicsLayer = new GraphicsLayer({
            id: "overlayGraphicsLayer",
            title: "Overlay Graphics",
        });
        view.map.add(overlayGraphicsLayer);
    }

    const data = lighthouseData.current!;

    // Stop dragging in the map if we are dragging a drag handle
    const action = event.action;

    if ((isCenterSelected.current || isDraggingCenter.current) && !isDraggingLine.current) {
        data.center = view.toMap({ x: event.x, y: event.y });

        isDraggingCenter.current = true;
        lighthouseData.current = data;

        if (action === "end" && isDraggingCenter.current) {
            computeTrueNorth(data.center).then((angle) => {
                setTrueNorth(angle);
            });
        }
    } else if (currentLine.current && !isCenterSelected.current && !isDraggingCenter.current) {
        isDraggingLine.current = true;
        if (action === "start") {
            dragStartHandler(view, dragStartCursor, currentLine, temporaryLine);
        }

        if (temporaryLine.current) overlayGraphicsLayer.add(temporaryLine.current);

        // Compute the new line length based on cursor position
        const endPoint = view.toMap({ x: event.x, y: event.y });
        const geometry = currentLine.current.geometry as Polyline;

        const currentIndex = currentLine.current.attributes.index;
        const currentItem = data.sectors.find((sector) => sector.index === currentIndex);

        if (!currentItem) return;

        if (dragStartCursor.current === "move") {
            currentItem.startLineLength = Math.sqrt(
                Math.pow(endPoint.x - geometry.paths[0][0][0], 2) + Math.pow(endPoint.y - geometry.paths[0][0][1], 2)
            );
        }

        const rotationOffset = 270;

        const newAngle =
            (-Math.atan2(endPoint.y - geometry.paths[0][0][1], endPoint.x - geometry.paths[0][0][0]) * 180) / Math.PI +
            rotationOffset +
            trueNorth.current;
        currentItem.startAngle = newAngle;

        // Sanitize all sectors to maintain proper ordering, line lengths, and angles
        sanitizeSectors(data, currentIndex);

        lighthouseData.current = data;

        endDragLogic(
            action,
            currentLine,
            temporaryLine,
            lighthouseLayer,
            isDraggingLine,
            isCenterSelected,
            setIsEditing!
        );
    }

    setLighthouseData({ ...lighthouseData.current! });
};

const endDragLogic = (
    action: string,
    currentLine: React.MutableRefObject<Graphic | null>,
    temporaryLine: React.MutableRefObject<Graphic | null>,
    lighthouseLayer: GraphicsLayer,
    isDraggingLine: React.MutableRefObject<boolean>,
    isCenterSelected: React.MutableRefObject<boolean>,
    setIsEditing: (value: boolean) => void
) => {
    if (action === "end") {
        currentLine.current = null;
        isDraggingLine.current = false;
        isCenterSelected.current = false;
        setIsEditing(false);

        if (temporaryLine.current) {
            lighthouseLayer.remove(temporaryLine.current);
            temporaryLine.current = null;
        }
    }
};

export const lighthouseHandlerDrag = ({
    view,
    currentLine,
    temporaryLine,
    dragStartCursor,
    lighthouseData,
    setLighthouseData,
    isDraggingLine,
    isDraggingCenter,
    isCenterSelected,
    setIsEditing,
    trueNorth,
    setTrueNorth,
}: DragHandlerParams) => {
    return view.on("drag", (event) => {
        if (currentLine.current || isCenterSelected.current || isDraggingCenter.current || isDraggingLine.current) {
            dragLogic(event, {
                view: view,
                currentLine: currentLine,
                temporaryLine: temporaryLine,
                dragStartCursor: dragStartCursor,
                lighthouseData: lighthouseData,
                setLighthouseData: setLighthouseData,
                isDraggingLine: isDraggingLine,
                isDraggingCenter: isDraggingCenter,
                isCenterSelected: isCenterSelected,
                setIsEditing: setIsEditing,
                trueNorth: trueNorth,
                setTrueNorth: setTrueNorth,
            });
        } else {
            isDraggingLine.current = false;
            isDraggingCenter.current = false;
            isCenterSelected.current = false;
        }

        if (event.action === "end") {
            isDraggingLine.current = false;
            isDraggingCenter.current = false;
            isCenterSelected.current = false;
        }
    });
};

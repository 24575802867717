import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFeatureViewerOpen, setLightCalculatorOpen } from "../../store/appSlice";
import { StoreState } from "../../store/rootReducer";
import LabelClass from "@arcgis/core/layers/support/LabelClass.js";

import LeftArrowIcon from "../../assets/arrow-left.svg?react";
import CloseIcon from "../../assets/close.svg?react";

import "./LightCalculator.css";
import InputField from "../genericComponents/InputField/InputField";
import NfsDropdown from "../genericComponents/NfsDropdown/NfsDropdown";
import {
    clearLightInMap,
    featureHasLight,
    formatArcadeForLightLabel,
    formatRangeNm,
    formatRangeOfRangeNm,
    getCharacterColors,
    getDomainCodeFromValue,
    getFeatureTable,
    getShortestActiveTime,
    lightCalculationIncandescent,
    lightCalculationLED,
    renderLightInMap,
} from "./lightCalculationUtils";
import { GetCodedValueAndAlias, getMapView } from "../../utils/arcgisUtils";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import {
    BackgroundLight,
    LensOrder,
    lensOrderStringToNumber,
    LightCalculationLightColors,
    LightCalculationResult,
    PassThroughMaterial,
} from "../../constants/LightCalculation";
import { beregnMin, findRange, lightCalculationCandela, omvFixIntensity } from "./lightCalculationCandelaUtils";
import { AppContext, UserContext } from "../../Context";
import NfsButton from "../genericComponents/NfsButton/NfsButton";
import { canEditVersion } from "../../utils/versioningUtils";
import RadioButtonGroup from "../genericComponents/RadioButton/RadioButtonGroup";
import Point from "@arcgis/core/geometry/Point";
import { metersPerNauticalMile } from "../../utils/constants";
import FeatureViewerTitle from "../FeatureViewer/FeatureViewerTitle";
import { AppConfig, LayersConfig } from "../../AppConfig";
import * as symbolUtils from "@arcgis/core/symbols/support/symbolUtils";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import Toggle from "../genericComponents/Toggle/Toggle";

const LightCalculator = () => {
    const dispatch = useDispatch();

    const [symbolHtmlElement, setSymbolHtmlElement] = useState<HTMLElement | null>(null);
    const [showRangeInMap, setShowRangeInMap] = useState<boolean>(false);

    const [panelClass, setPanelClass] = useState<string>("collapsed");
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [canIEditVersion, setCanIEditVersion] = useState<boolean>(false);
    const [activeUnit, setActiveUnit] = useState<"Nm" | "m">("Nm");

    const [desiredDistanceDynamicInput, setDesiredDistanceDynamicInput] = useState<string>("");
    const [desiredDistance, setDesiredDistance] = useState<number>(0);
    const [shortestActiveTime, setShortestActiveTime] = useState<string>("");
    const [backgroundLight, setBackgroundLight] = useState<BackgroundLight>(BackgroundLight.NONE);
    const [lightColor, setLightColor] = useState<LightCalculationLightColors | null>(null);
    const [passthroughMaterial, setPassthroughMaterial] = useState<PassThroughMaterial>(PassThroughMaterial.NONE);
    const [lensOrder, setLensOrder] = useState<LensOrder | null>(null);

    const [whiteMaxCandela, setWhiteMaxCandela] = useState<number>(0);
    const [whiteMinCandela, setWhiteMinCandela] = useState<number>(0);
    const [redMaxCandela, setRedMaxCandela] = useState<number>(0);
    const [redMinCandela, setRedMinCandela] = useState<number>(0);
    const [greenMinCandela, setGreenMinCandela] = useState<number>(0);
    const [greenMaxCandela, setGreenMaxCandela] = useState<number>(0);
    const [rangeWhite, setRangeWhite] = useState<string>("");
    const [rangeRed, setRangeRed] = useState<string>("");
    const [rangeGreen, setRangeGreen] = useState<string>("");
    const [resultCandela, setResultCandela] = useState<{
        effectiveIntensity: number;
        maxIntensity: number;
        imin: number;
        message: string;
    }>({ effectiveIntensity: 0, maxIntensity: 0, imin: 0, message: "" });

    const [intensityWhite, setIntensityWhite] = useState<number>(0);
    const [percentageWhite, setPercentageWhite] = useState<number>(100);
    const [intensityRed, setIntensityRed] = useState<number>(0);
    const [percentageRed, setPercentageRed] = useState<number>(100);
    const [intensityGreen, setIntensityGreen] = useState<number>(0);
    const [percentageGreen, setPercentageGreen] = useState<number>(100);
    const [intensityIncandescent, setIntensityIncandescent] = useState<number>(0);

    const [calculationResults, setCalculationResults] = useState<LightCalculationResult>();
    const [candelaCL, setCandelaCL] = useState<__esri.Graphic[]>([]);
    const [relevantCandelaCLs, setRelevantCandelaCLs] = useState<__esri.Graphic[]>([]);
    const [selectedCandela, setSelectedCandela] = useState<__esri.Graphic | null>(null);
    const [characterCL, setCharacterCL] = useState<__esri.Graphic[]>([]);
    const [relevantCharacterCLs, setRelevantCharacterCLs] = useState<__esri.Graphic[]>([]);
    const [selectedCharacter, setSelectedCharacter] = useState<__esri.Graphic | null>(null);
    const [relevantCharacterTimes, setRelevantCharacterTimes] = useState<__esri.Graphic[]>([]);

    const isLoadingFromFeature = useRef<boolean>(false);
    const layerConfig = useRef<LayersConfig>();

    const [lockedInColor, setLockedInColor] = useState<number | null>(null);
    const [colorsToShow, setColorsToShow] = useState<LightCalculationLightColors[]>([]);

    const lightCalculatorOpen = useSelector((state: StoreState) => state.app.lightCalculatorOpen);
    const activeVersion = useSelector((state: StoreState) => state.version.versionInfo);
    const versionsSharedWithMe = useSelector((state: StoreState) => state.version.versionsSharedWithMe);

    const userContext = useContext(UserContext);
    const appContext = useContext(AppContext);
    const selectedFeature = appContext?.selectedFeature.value;


    // Due to css behavior with animations to display none and vice versa
    // an additional class is needed to allow panel to slide out
    const toggleOpen = () => {
        if (panelClass === "") {
            closePanel();
        } else if (panelClass === "collapsed") {
            openPanel();
        }
    };

    const openPanel = () => {
        if (panelClass === "") {
            return;
        }
        setPanelClass("opening");
        setTimeout(() => {
            setPanelClass("");
        }, 100);
    };

    const closePanel = () => {
        setPanelClass("collapsed");
    };

    const fullyClosePanel = () => {
        resetAllValues(true);
        dispatch(setLightCalculatorOpen({ open: false, loadFeatureValues: false }));
        dispatch(setFeatureViewerOpen(true));
    };

    const resetAllValues = (allValues: boolean) => {
        setDesiredDistance(0);
        setDesiredDistanceDynamicInput("");
        setWhiteMaxCandela(0);
        setWhiteMinCandela(0);
        setRedMaxCandela(0);
        setRedMinCandela(0);
        setGreenMaxCandela(0);
        setGreenMinCandela(0);
        setRangeWhite("");
        setRangeRed("");
        setRangeGreen("");
        setResultCandela({ effectiveIntensity: 0, maxIntensity: 0, imin: 0, message: "" });

        setIntensityWhite(0);
        setIntensityRed(0);
        setIntensityGreen(0);
        setIntensityIncandescent(0);

        setCalculationResults(undefined);

        if (!isLoadingFromFeature.current) {
            setPercentageWhite(100);
            setPercentageRed(100);
            setPercentageGreen(100);
        }
        if (allValues) {
            setColorsToShow([]);
            setLightColor(null);
            setLockedInColor(null);
            setSelectedCandela(null);
            setSelectedCharacter(null);
            setBackgroundLight(BackgroundLight.NONE);
            setPassthroughMaterial(PassThroughMaterial.NONE);
            setLensOrder(null);

            const uniqueCharacters = characterCL.filter(
                (character, index, self) =>
                    index === self.findIndex((c) => c.attributes.kode === character.attributes.kode)
            );
            setRelevantCharacterCLs(uniqueCharacters);
        }
    };

    const resetAndLoadValuesFromSelectedFeature = (fromFeature: boolean) => {
        if (fromFeature) {
            isLoadingFromFeature.current = true;
            resetAllValues(false);
            setSelectedCandela(null);
            setSelectedCharacter(null);

            // Using a short timeout to ensure all fields are wiped and then loaded
            setTimeout(() => {
                if (selectedFeature && featureHasLight(selectedFeature)) {
                    const lightColor = selectedFeature.attributes.lys_farge;
                    if (lightColor) {
                        const lightColorString = GetCodedValueAndAlias(
                            selectedFeature.layer as FeatureLayer,
                            "lys_farge",
                            lightColor
                        )[1].toString();
                        setLightColor(lightColorString as LightCalculationLightColors);
                        setLockedInColor(parseInt(lightColor));
                    } else if (selectedFeature.attributes.klasse === 3 || selectedFeature.attributes.klasse === 4) {
                        setLockedInColor(0); // Multiple colors
                        setLightColor(null)
                    } else {
                        setLightColor(null);
                        setLockedInColor(null);
                    }

                    let lamp = selectedFeature.attributes.lampe as string;
                    if (lamp) {
                        lamp = lamp.split(" (")[0];
                        const incomingCandela = candelaCL.find((candela) => candela.attributes.lampe === lamp);
                        if (incomingCandela) {
                            setSelectedCandela(incomingCandela);
                        } else {
                            setSelectedCandela(null);
                        }

                        try {
                            const intensities = selectedFeature.attributes.lampe.replace(")", "").split(" (");
                            if (intensities.length > 1) {
                                const percentages = intensities[1].split("/");
                                if (percentages.length === 3) {
                                    setTimeout(() => {
                                        setPercentageWhite(parseInt(percentages[0].replace("%", "")));
                                        setPercentageRed(parseInt(percentages[1].replace("%", "")));
                                        setPercentageGreen(parseInt(percentages[2].replace("%", "")));
                                    }, 50);
                                }
                            }
                        } catch (e) {
                            console.error(e);
                        }

                    } else {
                        setSelectedCandela(null);
                    }
                    const character = selectedFeature.attributes.fyrliste_karakter;
                    const characterTime = selectedFeature.attributes.karakter_tid;
                    if (character) {
                        const tempRelavantCharacteres = characterCL.filter((c) => c.attributes.kode === character);
                        if (tempRelavantCharacteres.length > 0) {
                            if (characterTime) {
                                const incomingCharacterTime = tempRelavantCharacteres.find(
                                    (c) => c.attributes.karakter_tid === characterTime
                                );
                                if (incomingCharacterTime) {
                                    setSelectedCharacter(incomingCharacterTime);
                                } else {
                                    setSelectedCharacter(tempRelavantCharacteres[0]);
                                }
                            } else {
                                setSelectedCharacter(tempRelavantCharacteres[0]);
                            }
                        }
                    } else {
                        setSelectedCharacter(null);
                    }

                    const lightType = selectedFeature.attributes.lys_type;
                    let filteredCharacters;
                    if (lightType) {
                        filteredCharacters = characterCL.filter((c) => c.attributes.lys_type_1 === lightType);
                    }
                    if (selectedFeature?.attributes?.lys_farge && filteredCharacters) {
                        const lightColorCode = selectedFeature.attributes.lys_farge;
                        if (lightColorCode) {
                            filteredCharacters = filteredCharacters.filter(
                                (c) => c.attributes.lys_farge === lightColorCode
                            );
                        }
                    }
                    let uniqueFilteredCharacters;
                    if (filteredCharacters && filteredCharacters?.length > 0) {
                        uniqueFilteredCharacters = filteredCharacters.filter(
                            (character, index, self) =>
                                index === self.findIndex((c) => c.attributes.kode === character.attributes.kode)
                        );
                    }
                    if (uniqueFilteredCharacters && lockedInColor) {
                        const colorFilteredCharacters = uniqueFilteredCharacters.filter(
                            (c) => c.attributes.lys_farge === lockedInColor
                        );
                        setRelevantCharacterCLs(colorFilteredCharacters);
                    } else if (uniqueFilteredCharacters) {
                        setRelevantCharacterCLs(uniqueFilteredCharacters);
                    }

                    const incomingBackgroundlight = GetCodedValueAndAlias(selectedFeature.layer, "bakgrunnsbelysning", selectedFeature.attributes.bakgrunnsbelysning)[1].toString() as BackgroundLight;
                    setBackgroundLight(incomingBackgroundlight);

                } else {
                    setLightColor(null);
                    setLockedInColor(null);
                    setSelectedCandela(null);
                    setSelectedCharacter(null);
                    setBackgroundLight(BackgroundLight.NONE);
                    setPassthroughMaterial(PassThroughMaterial.NONE);

                    const uniqueCharacters = characterCL.filter(
                        (character, index, self) =>
                            index === self.findIndex((c) => c.attributes.kode === character.attributes.kode)
                    );
                    setRelevantCharacterCLs(uniqueCharacters);
                }
                isLoadingFromFeature.current = false;
            }, 50);
        } else {
            resetAllValues(true);
        }
    };

    const addLabelToFeatures = () => {
        const mapview = getMapView();
        if (!mapview) {
            return;
        }

        const arcadeScript = formatArcadeForLightLabel();
        const lightLabelClass = new LabelClass({
            labelExpressionInfo: {
                expression: arcadeScript,
            },
            labelPlacement: "above-center",
            symbol: {
                type: "text",
                color: [255, 255, 255, 0.7],
                haloColor: [0, 0, 0, 0.7],
                haloSize: 2,
                font: {
                    size: 14,
                    family: "sans-serif",
                },
            },
        });
        mapview.map.allLayers.forEach((layer) => {
            if (layer.type === "feature") {
                if (
                    (layer as FeatureLayer).fields.find((field) => field.name === "fyrliste_karakter") ||
                    (layer as FeatureLayer).fields.find((field) => field.name === "karakter_tid")
                ) {
                    (layer as FeatureLayer).labelingInfo = [lightLabelClass];
                }
            }
        });
    };

    const removeLabelFromFeatures = () => {
        const mapview = getMapView();
        if (!mapview) {
            return;
        }
        mapview.map.allLayers.forEach((layer) => {
            if (layer.type === "feature") {
                if ((layer as FeatureLayer).fields.find((field) => field.name === "fyrliste_karakter")) {
                    (layer as FeatureLayer).labelingInfo = [];
                }
            }
        });
    };

    const shouldUseCandelaForLED = () => {
        if (selectedCandela?.attributes.type === "LED-1") {
            return true;
        }
        return false;
    };

    const setListOfCandela = () => {
        const candelaTable = getFeatureTable("Candela_CL");
        if (!candelaTable) {
            setTimeout(() => {
                setListOfCandela();
            }, 1000);
            return;
        }
        candelaTable.queryFeatures().then((result) => {
            setCandelaCL(result.features);
        });
    };

    const setListOfCharacter = () => {
        const characterTable = getFeatureTable("Karakter_CL");
        if (!characterTable) {
            setTimeout(() => {
                setListOfCharacter();
            }, 1000);
            return;
        }
        characterTable.queryFeatures().then((result) => {
            setCharacterCL(result.features);
        });
    };

    const selectCandela = (lampName: string | null) => {
        if (lampName === null) {
            setSelectedCandela(null);
        }
        const selected = relevantCandelaCLs.find((candela) => candela.attributes.lampe === lampName);
        if (selected) {
            setSelectedCandela(selected);
        }
    };

    const selectCharacter = (character: string | null) => {
        if (character === null) {
            setSelectedCharacter(null);
        }

        const selected = relevantCharacterCLs.find((c) => {
            return c.attributes.kode === character;
        });

        if (selected) {
            setSelectedCharacter(selected);
        }
    };

    const selectCharacterTime = (time: string | null) => {
        const selected = relevantCharacterTimes.find((c) => {
            return c.attributes.karakter_tid === time;
        });

        if (selected) {
            setSelectedCharacter(selected);
        }
    };

    const loadLightValues = (resetLightValues: boolean) => {
        if (selectedCandela) {
            if (selectedCandela.attributes.type === "LED" && resetLightValues) {

                setIntensityWhite(
                    selectedCandela.attributes.lysstyrke_led_hvit ?? selectedCandela.attributes.lysstyrke
                );
                setIntensityRed(selectedCandela.attributes.lysstyrke_led_rod ?? selectedCandela.attributes.lysstyrke);
                setIntensityGreen(
                    selectedCandela.attributes.lysstyrke_led_gronn ?? selectedCandela.attributes.lysstyrke
                );
                setIntensityIncandescent(0);
            } else if (selectedCandela.attributes.type === "GLØDE" && resetLightValues) {
                setIntensityFromLensOrder(selectedCandela);
                setIntensityWhite(0);
                setIntensityRed(0);
                setIntensityGreen(0);
            } else if (selectedCandela.attributes.type === "LED-1") {
                const tau = getShortestActiveTime(selectedCharacter?.attributes.karakter_tid);

                if (tau) {
                    let iWhite = selectedCandela.attributes.lysstyrke_led_hvit;
                    if (tau > 0) {
                        iWhite = omvFixIntensity(iWhite, tau);
                    }
                    setWhiteMaxCandela(iWhite.toFixed(2));
                    let iWhiteMin = beregnMin(iWhite);
                    iWhiteMin = Math.ceil(iWhiteMin);
                    setWhiteMinCandela(iWhiteMin);

                    const { dmin, dmaks } = findRange(iWhiteMin, iWhite, passthroughMaterial, backgroundLight, tau);
                    setRangeWhite(`${dmin.toFixed(2)}Nm - ${dmaks.toFixed(2)}Nm`);

                    let iRed = selectedCandela.attributes.lysstyrke_led_rod;
                    if (tau > 0) {
                        iRed = omvFixIntensity(iRed, tau);
                    }
                    setRedMaxCandela(iRed.toFixed(2));
                    let iRedMin = beregnMin(iRed);
                    iRedMin = Math.ceil(iRedMin);
                    setRedMinCandela(iRedMin);

                    const redRange = findRange(iRedMin, iRed, passthroughMaterial, backgroundLight, tau);
                    setRangeRed(`${redRange.dmin.toFixed(2)}Nm - ${redRange.dmaks.toFixed(2)}Nm`);

                    let iGreen = selectedCandela.attributes.lysstyrke_led_gronn;
                    if (tau > 0) {
                        iGreen = omvFixIntensity(iGreen, tau);
                    }
                    setGreenMaxCandela(iGreen.toFixed(2));
                    let iGreenMin = beregnMin(iGreen);
                    iGreenMin = Math.ceil(iGreenMin);
                    setGreenMinCandela(iGreenMin);

                    const greenRange = findRange(iGreenMin, iGreen, passthroughMaterial, backgroundLight, tau);
                    setRangeGreen(`${greenRange.dmin.toFixed(2)}Nm - ${greenRange.dmaks.toFixed(2)}Nm`);

                    setIntensityIncandescent(0);
                }
            }
        }
    };

    const setIntensityFromLensOrder = (candela: __esri.Graphic) => {
        let intensity = candela.attributes.lysstyrke;
        switch (lensOrder) {
            case LensOrder.FIRST:
                intensity = candela.attributes.ord_glass_1;
                break;
            case LensOrder.SECOND:
                intensity = candela.attributes.ord_glass_2;
                break;
            case LensOrder.THIRD:
                intensity = candela.attributes.ord_glass_3;
                break;
            case LensOrder.FOURTH:
                intensity = candela.attributes.ord_glass_4;
                break;
            case LensOrder.FIFTH:
                intensity = candela.attributes.ord_glass_5;
                break;
            case LensOrder.SIXTH:
                intensity = candela.attributes.ord_glass_6;
                break;
        }
        setIntensityIncandescent(intensity);
    };

    const canSaveLightValues = () => {
        if (!selectedFeature || !featureHasLight(selectedFeature)) {
            return false;
        }

        if (!selectedCandela || !selectedCharacter) {
            return false;
        }

        if (!canIEditVersion) {
            return false;
        }

        if (selectedCandela.attributes.type === "LED-1") {
            if (!desiredDistance || desiredDistance <= 0) {
                return false;
            }
        }

        return true;
    };

    const saveLightValues = () => {
        if (!selectedFeature || !selectedCandela || !selectedCharacter || !featureHasLight(selectedFeature)) {
            return;
        }

        if (!canSaveLightValues()) {
            return;
        }
        const backgroundLightCode = getDomainCodeFromValue(
            selectedFeature.layer as FeatureLayer,
            "bakgrunnsbelysning",
            backgroundLight
        );
        if (backgroundLightCode === backgroundLight) {
            return;
        }

        setIsSaving(true);

        const copyFeature = selectedFeature.clone();
        copyFeature.attributes = {};
        copyFeature.attributes.objectid = selectedFeature.attributes.objectid;
        copyFeature.attributes.globalid = selectedFeature.attributes.globalid;

        if (selectedCandela.attributes.type === "LED") {
            let lampString = selectedCandela.attributes.lampe;
            lampString += " (";
            if (colorsToShow.includes(LightCalculationLightColors.WHITE)) {
                lampString += `${percentageWhite}%/`;
            } else {
                lampString += "0%/";
            }
            if (colorsToShow.includes(LightCalculationLightColors.RED)) {
                lampString += `${percentageRed}%/`;
            } else {
                lampString += "0%/";
            }
            if (colorsToShow.includes(LightCalculationLightColors.GREEN)) {
                lampString += `${percentageGreen}%)`;
            } else {
                lampString += "0%)";
            }

            copyFeature.attributes.lampe = lampString;
        } else {
            copyFeature.attributes.lampe = selectedCandela.attributes.lampe;
        }

        copyFeature.attributes.fyrliste_karakter = selectedCharacter.attributes.kode;
        copyFeature.attributes.karakter_tid = selectedCharacter.attributes.karakter_tid;
        copyFeature.attributes.lys_farge = selectedCharacter.attributes.lys_farge == 0 ? null : selectedCharacter.attributes.lys_farge;
        copyFeature.attributes.bakgrunnsbelysning = backgroundLightCode;
        copyFeature.attributes.lys_gjennomgang = passthroughMaterial;

        copyFeature.attributes.lysstyrke_hvit = null;
        copyFeature.attributes.rekkevidde_hvit = null;
        copyFeature.attributes.lysstyrke_rod = null;
        copyFeature.attributes.rekkevidde_rod = null;
        copyFeature.attributes.lysstyrke_gronn = null;
        copyFeature.attributes.rekkevidde_gronn = null;
        copyFeature.attributes.lysstyrke_gul = null;
        copyFeature.attributes.rekkevidde_gul = null;
        copyFeature.attributes.linse_orden = null;

        if (colorsToShow.length > 0) {
            if (colorsToShow.includes(LightCalculationLightColors.WHITE)) {
                if (selectedCandela?.attributes.type === "LED-1") {
                    copyFeature.attributes.lysstyrke_hvit = resultCandela.effectiveIntensity;
                    copyFeature.attributes.rekkevidde_hvit = desiredDistance;
                } else {
                    copyFeature.attributes.lysstyrke_hvit = calculationResults?.effectiveIntensityWhite;
                    copyFeature.attributes.rekkevidde_hvit = calculationResults?.rangeWhite?.replace(" Nm", "");
                }
            }

            if (colorsToShow.includes(LightCalculationLightColors.RED)) {
                if (selectedCandela?.attributes.type === "LED-1") {
                    copyFeature.attributes.lysstyrke_rod = resultCandela.effectiveIntensity;
                    copyFeature.attributes.rekkevidde_rod = desiredDistance;
                } else {
                    copyFeature.attributes.lysstyrke_rod = calculationResults?.effectiveIntensityRed;
                    copyFeature.attributes.rekkevidde_rod = calculationResults?.rangeRed?.replace(" Nm", "");
                }
            }

            if (colorsToShow.includes(LightCalculationLightColors.GREEN)) {
                if (selectedCandela?.attributes.type === "LED-1") {
                    copyFeature.attributes.lysstyrke_gronn = resultCandela.effectiveIntensity;
                    copyFeature.attributes.rekkevidde_gronn = desiredDistance;
                } else {
                    copyFeature.attributes.lysstyrke_gronn = calculationResults?.effectiveIntensityGreen;
                    copyFeature.attributes.rekkevidde_gronn = calculationResults?.rangeGreen?.replace(" Nm", "");
                }
            }

            if (colorsToShow.includes(LightCalculationLightColors.YELLOW)) {
                if (selectedCandela?.attributes.type === "LED-1") {
                    copyFeature.attributes.lysstyrke_gul = resultCandela.effectiveIntensity;
                    copyFeature.attributes.rekkevidde_gul = desiredDistance;
                } else {
                    copyFeature.attributes.lysstyrke_gul = calculationResults?.effectiveIntensityGreen;
                    copyFeature.attributes.rekkevidde_gul = calculationResults?.rangeGreen?.replace(" Nm", "");
                }
            }
        } else {
            switch (lightColor) {
                case LightCalculationLightColors.WHITE:
                    if (selectedCandela?.attributes.type === "LED-1") {
                        copyFeature.attributes.lysstyrke_hvit = resultCandela.effectiveIntensity;
                        copyFeature.attributes.rekkevidde_hvit = desiredDistance;
                    } else {
                        copyFeature.attributes.lysstyrke_hvit = calculationResults?.effectiveIntensityWhite;
                        copyFeature.attributes.rekkevidde_hvit = calculationResults?.rangeWhite?.replace(" Nm", "");
                    }
                    break;
                case LightCalculationLightColors.RED:
                    if (selectedCandela?.attributes.type === "LED-1") {
                        copyFeature.attributes.lysstyrke_rod = resultCandela.effectiveIntensity;
                        copyFeature.attributes.rekkevidde_rod = desiredDistance;
                    } else {
                        copyFeature.attributes.lysstyrke_rod = calculationResults?.effectiveIntensityRed;
                        copyFeature.attributes.rekkevidde_rod = calculationResults?.rangeRed?.replace(" Nm", "");
                    }
                    break;
                case LightCalculationLightColors.GREEN:
                    if (selectedCandela?.attributes.type === "LED-1") {
                        copyFeature.attributes.lysstyrke_gronn = resultCandela.effectiveIntensity;
                        copyFeature.attributes.rekkevidde_gronn = desiredDistance;
                    } else {
                        copyFeature.attributes.lysstyrke_gronn = calculationResults?.effectiveIntensityGreen;
                        copyFeature.attributes.rekkevidde_gronn = calculationResults?.rangeGreen?.replace(
                            " Nm",
                            ""
                        );
                    }
                    break;
                case LightCalculationLightColors.YELLOW:
                    if (selectedCandela?.attributes.type === "LED-1") {
                        copyFeature.attributes.lysstyrke_gul = resultCandela.effectiveIntensity;
                        copyFeature.attributes.rekkevidde_gul = desiredDistance;
                    } else {
                        copyFeature.attributes.lysstyrke_gul = calculationResults?.effectiveIntensityGreen;
                        copyFeature.attributes.rekkevidde_gul = calculationResults?.rangeGreen?.replace(" Nm", "");
                    }
                    break;
            }
        }

        if (selectedCandela?.attributes.type === "GLØDE") {
            copyFeature.attributes.linse_orden = lensOrderStringToNumber(lensOrder as LensOrder);
        }

        (selectedFeature.layer as FeatureLayer)
            .applyEdits({
                updateFeatures: [copyFeature],
            })
            .then((result) => {
                if (result.updateFeatureResults[0].error) {
                    appContext.selectedFeature.set(selectedFeature);
                } else {
                    for (const key of Object.keys(selectedFeature.attributes)) {
                        if (!Object.keys(copyFeature.attributes).includes(key)) {
                            copyFeature.attributes[key] = selectedFeature.attributes[key];
                        }
                    }
                    appContext.selectedFeature.set(copyFeature);
                }
            })
            .catch(() => {
                appContext.selectedFeature.set(selectedFeature);
            })
            .finally(() => {
                setIsSaving(false);
            });
    };

    const handleDesiredDistanceChange = (value: string) => {
        setDesiredDistanceDynamicInput(value);
        if (activeUnit === "Nm") {
            setDesiredDistance(parseFloat(value));
        } else {
            setDesiredDistance(parseFloat(value) / metersPerNauticalMile);
        }
    };

    const updateLightRangeInMap = () => {
        clearLightInMap();
        if (!selectedFeature || !showRangeInMap || !calculationResults) {
            clearLightInMap();
            return;
        }


        let lightCenter = selectedFeature.geometry as Point;
        if (selectedFeature.geometry.type !== "point") {
            lightCenter = selectedFeature.geometry.extent.center;
        }

        if (selectedCandela?.attributes.type !== "LED-1") {
            if (
                colorsToShow.includes(LightCalculationLightColors.WHITE) ||
                lightColor == LightCalculationLightColors.WHITE
            ) {
                const radiusWhite = calculationResults?.rangeWhite
                    ? parseFloat(calculationResults.rangeWhite.replace("Nm", ""))
                    : 0;
                renderLightInMap(lightCenter, "white", radiusWhite);
            }
            if (
                colorsToShow.includes(LightCalculationLightColors.RED) ||
                lightColor == LightCalculationLightColors.RED
            ) {
                const radiusRed = calculationResults?.rangeRed
                    ? parseFloat(calculationResults.rangeRed.replace("Nm", ""))
                    : 0;
                renderLightInMap(lightCenter, "red", radiusRed);
            }

            if (
                colorsToShow.includes(LightCalculationLightColors.GREEN) ||
                lightColor == LightCalculationLightColors.GREEN
            ) {
                const radiusGreen = calculationResults?.rangeGreen
                    ? parseFloat(calculationResults.rangeGreen.replace("Nm", ""))
                    : 0;
                renderLightInMap(lightCenter, "green", radiusGreen);
            }

            if (
                colorsToShow.includes(LightCalculationLightColors.YELLOW) ||
                lightColor == LightCalculationLightColors.YELLOW
            ) {
                const radiusYellow = calculationResults?.rangeYellow
                    ? parseFloat(calculationResults.rangeYellow.replace("Nm", ""))
                    : 0;
                renderLightInMap(lightCenter, "yellow", radiusYellow);
            }
        }
    }

    useEffect(() => {
        updateLightRangeInMap();

        // Get the map view and watch for scale changes
        const mapView = getMapView();
        let scaleWatcher: __esri.WatchHandle | null = null;

        if (mapView) {
            scaleWatcher = reactiveUtils.watch(() => mapView.scale, () => { updateLightRangeInMap() });
        }

        // Clean up the watcher when component unmounts or dependencies change
        return () => {
            if (scaleWatcher) {
                scaleWatcher.remove();
            }
        };
    }, [calculationResults, showRangeInMap]);

    useEffect(() => {
        clearLightInMap();
        if (!selectedFeature) {
            clearLightInMap();
            return;
        }

        if (!resultCandela) {
            clearLightInMap();
            return;
        }

        let lightCenter = selectedFeature.geometry as Point;
        if (selectedFeature.geometry.type !== "point") {
            lightCenter = selectedFeature.geometry.extent.center;
        }

        if (selectedCandela?.attributes.type === "LED-1") {
            const radius = desiredDistance;
            renderLightInMap(lightCenter, "led-160", radius, resultCandela.message !== "");
        }
    }, [resultCandela]);

    useEffect(() => {
        if (activeUnit === "Nm") {
            setDesiredDistanceDynamicInput(desiredDistance.toFixed(2));
        } else {
            setDesiredDistanceDynamicInput((desiredDistance * metersPerNauticalMile).toFixed(2));
        }
    }, [activeUnit]);

    useEffect(() => {
        if (selectedCandela) {
            setIntensityFromLensOrder(selectedCandela);
        }
    }, [lensOrder]);

    useEffect(() => {
        loadLightValues(false);
    }, [selectedCharacter, desiredDistance, backgroundLight, lightColor, passthroughMaterial]);

    // Filter relevant characters based on selected light color
    useEffect(() => {
        const uniqueCharacterCLs = characterCL.filter(
            (character, index, self) => index === self.findIndex((c) => c.attributes.kode === character.attributes.kode)
        );
        if (lockedInColor !== null) {
            const tempRelevantCharacters = uniqueCharacterCLs.filter((character) => {
                return character.attributes.lys_farge == lockedInColor;
            });
            setRelevantCharacterCLs(tempRelevantCharacters);
        } else {
            setRelevantCharacterCLs(uniqueCharacterCLs);
        }
    }, [lightColor]);

    useEffect(() => {
        if (!selectedCandela) {
            return;
        }
        resetAllValues(false);
        loadLightValues(true);
    }, [selectedCandela]);

    // Finds the relevant character times based on selected character
    // and sets the shortest active time
    useEffect(() => {
        if (selectedCharacter === null) {
            setRelevantCharacterTimes([]);
            setShortestActiveTime("");
        }
        if (selectedCharacter) {
            const relCharacterTimes = characterCL.filter((character) => {
                return character.attributes.kode === selectedCharacter.attributes.kode;
            });
            setRelevantCharacterTimes(relCharacterTimes);
            const shortestTime = getShortestActiveTime(selectedCharacter.attributes.karakter_tid);
            setShortestActiveTime(shortestTime.toString());
            const colorCode = selectedCharacter.attributes.lys_farge;
            const mapView = getMapView();
            const lightLayer = mapView?.map?.findLayerById("Lys") as FeatureLayer;
            const colorValue = GetCodedValueAndAlias(lightLayer, "lys_farge", colorCode)[1].toString();
            if (colorValue === "0") {
                const characterColors = getCharacterColors(selectedCharacter.attributes.kode);
                setColorsToShow(characterColors);
                setLightColor(null);
            } else {
                setLightColor(colorValue as LightCalculationLightColors);
                setColorsToShow([]);
            }
            loadLightValues(false);
        }
    }, [selectedCharacter]);

    useEffect(() => {
        if (relevantCandelaCLs.length === 0) {
            setRelevantCandelaCLs(candelaCL);
        }
    }, [candelaCL]);

    // Filters characters to only show unique characters
    useEffect(() => {
        if (relevantCharacterCLs.length === 0) {
            const uniqueCharacterCLs = characterCL.filter(
                (character, index, self) =>
                    index === self.findIndex((c) => c.attributes.kode === character.attributes.kode)
            );
            setRelevantCharacterCLs(uniqueCharacterCLs);
        }
    }, [characterCL]);

    useEffect(() => {
        if (selectedCharacter && selectedCandela) {
            const shortestActiveTimeNumber = parseFloat(shortestActiveTime);
            if (selectedCandela?.attributes.type === "LED") {
                const result = lightCalculationLED(
                    shortestActiveTimeNumber,
                    passthroughMaterial,
                    backgroundLight,
                    intensityWhite,
                    percentageWhite,
                    intensityRed,
                    percentageRed,
                    intensityGreen,
                    percentageGreen
                );
                setCalculationResults(result);
            } else if (selectedCandela?.attributes.type === "GLØDE") {
                const result = lightCalculationIncandescent(
                    intensityIncandescent,
                    shortestActiveTimeNumber,
                    passthroughMaterial,
                    selectedCharacter.attributes.lys_farge,
                    backgroundLight
                );
                setCalculationResults(result);
            } else if (selectedCandela.attributes.type === "LED-1") {
                loadLightValues(false);
                const colorCode = selectedCharacter.attributes.lys_farge;
                const result = lightCalculationCandela(
                    desiredDistance,
                    passthroughMaterial,
                    backgroundLight,
                    shortestActiveTimeNumber,
                    colorCode,
                    whiteMinCandela,
                    whiteMaxCandela,
                    redMinCandela,
                    redMaxCandela,
                    greenMinCandela,
                    greenMaxCandela
                );
                setResultCandela(result);
            }
        }
    }, [
        selectedCharacter,
        shortestActiveTime,
        passthroughMaterial,
        backgroundLight,
        intensityWhite,
        percentageWhite,
        intensityRed,
        percentageRed,
        intensityGreen,
        percentageGreen,
        intensityIncandescent,
        desiredDistance,
        whiteMinCandela,
        whiteMaxCandela,
        redMinCandela,
        redMaxCandela,
        greenMinCandela,
        greenMaxCandela,
    ]);

    useEffect(() => {
        if (lightCalculatorOpen.open && lightCalculatorOpen.loadFeatureValues) {
            resetAndLoadValuesFromSelectedFeature(true);
        }

        if (selectedFeature) {

            layerConfig.current = AppConfig.Layers.find((layerConfig) => layerConfig.Name === selectedFeature?.layer.id);

            symbolUtils.getDisplayedSymbol(selectedFeature).then((symbol) => {
                if (symbol) {
                    symbolUtils.renderPreviewHTML(symbol, { size: 12 }).then((symbolElement) => {
                        setSymbolHtmlElement(symbolElement);
                    });
                } else {
                    setSymbolHtmlElement(null);
                }
            });
        } else {
            setSymbolHtmlElement(null);
            layerConfig.current = undefined;
        }

    }, [selectedFeature]);

    useEffect(() => {
        const user = userContext?.user.value;
        canEditVersion(activeVersion, versionsSharedWithMe, user).then((result) => {
            setCanIEditVersion(result);
        });
    }, [activeVersion, versionsSharedWithMe]);

    useEffect(() => {
        const shortestActiveTimeNumber = parseFloat(shortestActiveTime);
        setListOfCandela();
        setListOfCharacter();
        const result = lightCalculationLED(
            shortestActiveTimeNumber,
            passthroughMaterial,
            backgroundLight,
            intensityWhite,
            percentageWhite,
            intensityRed,
            percentageRed,
            intensityGreen,
            percentageGreen
        );
        setCalculationResults(result);
    }, []);

    useEffect(() => {
        if (lightCalculatorOpen.open) {
            if (lightCalculatorOpen.loadFeatureValues) {
                resetAndLoadValuesFromSelectedFeature(true);
            }
            openPanel();
            addLabelToFeatures();
        } else {
            closePanel();
            removeLabelFromFeatures();
        }
    }, [lightCalculatorOpen]);

    if (!lightCalculatorOpen.open) {
        return null;
    }

    return (
        <div className="light-calculator-container">
            <div className={`light-calculator ${panelClass}`}>
                <div className="light-calculator-header">
                    <span className="flex-row small-gap" style={{ width: "100%" }}>
                        {shouldUseCandelaForLED() ? "Beregning av candela for LED" : "Beregning av lysrekkevidde"}
                    </span>
                    <button type="button" className="empty-button" onClick={fullyClosePanel}>
                        <CloseIcon />
                    </button>
                </div>
                {selectedFeature && featureHasLight(selectedFeature) &&
                    <FeatureViewerTitle
                        layerConfig={layerConfig}
                        selectedFeature={selectedFeature}
                        symbolHtmlElement={symbolHtmlElement}
                    />
                }
                <div className="light-calculator-content">
                    <div className="flex-col w-100 small-gap">
                        <RadioButtonGroup
                            options={["Vis i Nm", "Vis i m"]}
                            selectedIndex={activeUnit === "Nm" ? 0 : 1}
                            onChange={(selectedIndex) => {
                                setActiveUnit(selectedIndex === 0 ? "Nm" : "m");
                            }}
                        />
                        <div className="flex-row small-gap align-center space-between">
                            <span>Vis rekkevidde i kartet</span>
                            <Toggle
                                small
                                checked={showRangeInMap}
                                onChange={() => { setShowRangeInMap(!showRangeInMap) }}
                            />
                        </div>
                    </div>
                    <div className="light-calculator-input-fields">
                        {!shouldUseCandelaForLED() && (
                            <>
                                <div className="flex-row w-100 small-gap">
                                    <label className={`input-field-label`}>
                                        Lampe
                                        <NfsDropdown
                                            options={relevantCandelaCLs.map((candela) => candela.attributes.lampe)}
                                            allowEmpty
                                            selectedOption={selectedCandela?.attributes.lampe ?? null}
                                            setSelectedOption={selectCandela}
                                            placeholder="Velg lampe"
                                            searchable
                                        />
                                    </label>
                                </div>
                                <div className="flex-row w-100 small-gap">
                                    <InputField
                                        inputFieldId="lamp-type"
                                        label="Lampe type"
                                        type="text"
                                        readonly
                                        value={selectedCandela?.attributes.type ?? ""}
                                        onChange={() => { }}
                                    />
                                    {selectedCandela?.attributes.type === "GLØDE" && (
                                        <NfsDropdown
                                            options={[
                                                LensOrder.FIRST,
                                                LensOrder.SECOND,
                                                LensOrder.THIRD,
                                                LensOrder.FOURTH,
                                                LensOrder.FIFTH,
                                                LensOrder.SIXTH,
                                            ]}
                                            allowEmpty
                                            selectedOption={lensOrder}
                                            setSelectedOption={(value) => setLensOrder(value as LensOrder)}
                                            placeholder="Velg linse orden"
                                        />
                                    )}
                                </div>
                                <div className="flex-row small-gap w-100">
                                    <label className={`input-field-label`}>
                                        Fyrliste karakter
                                        <NfsDropdown
                                            options={relevantCharacterCLs.map((character) => character.attributes.kode)}
                                            allowEmpty
                                            selectedOption={selectedCharacter?.attributes.kode ?? null}
                                            setSelectedOption={selectCharacter}
                                            placeholder="Velg fyrliste karakter"
                                            searchable
                                        />
                                    </label>
                                </div>
                                <div className="flex-row w-100 small-gap">
                                    <label className={`input-field-label`}>
                                        Karakter
                                        <NfsDropdown
                                            disabled={selectedCharacter === null}
                                            options={relevantCharacterTimes.map(
                                                (character) => character.attributes.karakter_tid
                                            )}
                                            selectedOption={selectedCharacter?.attributes.karakter_tid ?? null}
                                            setSelectedOption={selectCharacterTime}
                                            placeholder="Velg karakter"
                                        />
                                    </label>
                                </div>
                                <div className="flex-row w-100 small-gap">
                                    <label className={`input-field-label`}>
                                        Bakgrunnsbelysning
                                        <NfsDropdown
                                            options={[
                                                BackgroundLight.NONE,
                                                BackgroundLight.MEDIUM,
                                                BackgroundLight.HIGH,
                                            ]}
                                            selectedOption={backgroundLight}
                                            setSelectedOption={(value) => setBackgroundLight(value as BackgroundLight)}
                                            placeholder="Velg bakgrunnsbelysning"
                                        />
                                    </label>
                                    <InputField
                                        inputFieldId="shortest-active-time"
                                        label="Korteste aktive tid"
                                        type="text"
                                        value={shortestActiveTime}
                                        readonly
                                        onChange={() => { }}
                                    />
                                </div>
                                <div className="flex-row w-100 small-gap">
                                    <label className={`input-field-label`}>
                                        Lysfarge
                                        <NfsDropdown
                                            options={[
                                                LightCalculationLightColors.WHITE,
                                                LightCalculationLightColors.RED,
                                                LightCalculationLightColors.GREEN,
                                                LightCalculationLightColors.YELLOW,
                                            ]}
                                            allowEmpty
                                            readonly
                                            selectedOption={
                                                colorsToShow.length > 0 ? colorsToShow.join(", ") : lightColor
                                            }
                                            setSelectedOption={(value) =>
                                                setLightColor(value as LightCalculationLightColors)
                                            }
                                            placeholder="Velg lysfarge"
                                        />
                                    </label>
                                    <label className={`input-field-label`}>
                                        Lysgjennomgang
                                        <NfsDropdown
                                            options={[
                                                PassThroughMaterial.GLASS,
                                                PassThroughMaterial.PLASTIC,
                                                PassThroughMaterial.NONE,
                                            ]}
                                            selectedOption={passthroughMaterial}
                                            setSelectedOption={(value) =>
                                                setPassthroughMaterial(value as PassThroughMaterial)
                                            }
                                            placeholder="Velg lysgjennomgang"
                                        />
                                    </label>
                                </div>
                                {selectedCandela?.attributes.type === "GLØDE" && (
                                    <div className="flex-row w-100 small-gap">
                                        <InputField
                                            inputFieldId="light-intensity-incandescent"
                                            label="Lysstyrke"
                                            type="number"
                                            readonly
                                            value={intensityIncandescent?.toString()}
                                            valueSuffix="cd"
                                            onChange={(value) => {
                                                setIntensityIncandescent(parseFloat(value));
                                            }}
                                        />
                                    </div>
                                )}
                                {(lightColor === LightCalculationLightColors.WHITE ||
                                    (lightColor === null &&
                                        colorsToShow.includes(LightCalculationLightColors.WHITE))) && (
                                        <>
                                            {selectedCandela?.attributes.type === "LED" && (
                                                <div className="flex-row w-100 small-gap">
                                                    <InputField
                                                        inputFieldId="light-intensity-white"
                                                        label="Lysstyrke (Hvit)"
                                                        type="number"
                                                        valueSuffix="cd"
                                                        value={intensityWhite?.toString()}
                                                        onChange={(value) => {
                                                            setIntensityWhite(parseFloat(value));
                                                        }}
                                                    />
                                                    <InputField
                                                        inputFieldId="percentage-used-white"
                                                        label="Prosent utnyttelse"
                                                        type="number"
                                                        min={0}
                                                        max={100}
                                                        valueSuffix="percent"
                                                        value={percentageWhite?.toString()}
                                                        onChange={(value) => {
                                                            setPercentageWhite(parseFloat(value));
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <div className="flex-col small-gap">
                                                {selectedFeature && (
                                                    <div className="flex-row w-100 small-gap">
                                                        <InputField
                                                            inputFieldId="light-intensity-white"
                                                            label="Dagens lystyrke hvit"
                                                            type="text"
                                                            valueSuffix="cd"
                                                            value={selectedFeature.attributes.lysstyrke_hvit ?? ""}
                                                            readonly
                                                            onChange={() => { }}
                                                        />
                                                        <InputField
                                                            inputFieldId="light-range-white"
                                                            label="Dagens rekkevidde hvit"
                                                            type="text"
                                                            valueSuffix={activeUnit}
                                                            value={formatRangeNm(
                                                                selectedFeature.attributes.rekkevidde_hvit,
                                                                activeUnit === "m",
                                                                false
                                                            )}
                                                            readonly
                                                            onChange={() => { }}
                                                        />
                                                    </div>
                                                )}
                                                <div className="flex-row w-100 small-gap">
                                                    <InputField
                                                        inputFieldId="effective-light-intensity-white"
                                                        label="Effektiv lysstyrke hvit"
                                                        type="text"
                                                        valueSuffix="cd"
                                                        value={calculationResults?.effectiveIntensityWhite ?? ""}
                                                        readonly
                                                        onChange={() => { }}
                                                    />
                                                    <InputField
                                                        inputFieldId="calculated-range-white"
                                                        label="Beregnet rekkevidde hvit"
                                                        type="text"
                                                        valueSuffix={activeUnit}
                                                        value={formatRangeNm(
                                                            calculationResults?.rangeWhite,
                                                            activeUnit === "m",
                                                            false
                                                        )}
                                                        readonly
                                                        onChange={() => { }}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                {(lightColor === LightCalculationLightColors.RED ||
                                    (lightColor === null &&
                                        colorsToShow.includes(LightCalculationLightColors.RED))) && (
                                        <>
                                            {selectedCandela?.attributes.type === "LED" && (
                                                <div className="flex-row w-100 small-gap">
                                                    <InputField
                                                        inputFieldId="light-intensity-red"
                                                        label="Lysstyrke (Rød)"
                                                        type="number"
                                                        valueSuffix="cd"
                                                        value={intensityRed?.toString()}
                                                        onChange={(value) => {
                                                            setIntensityRed(parseFloat(value));
                                                        }}
                                                    />
                                                    <InputField
                                                        inputFieldId="percentage-used-red"
                                                        label="Prosent utnyttelse"
                                                        type="number"
                                                        min={0}
                                                        max={100}
                                                        valueSuffix="percent"
                                                        value={percentageRed?.toString()}
                                                        onChange={(value) => {
                                                            setPercentageRed(parseFloat(value));
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <div className="flex-col small-gap">
                                                {selectedFeature && (
                                                    <div className="flex-row w-100 small-gap">
                                                        <InputField
                                                            inputFieldId="light-intensity-red"
                                                            label="Dagens lystyrke rød"
                                                            type="text"
                                                            valueSuffix="cd"
                                                            value={selectedFeature.attributes.lysstyrke_rod ?? ""}
                                                            readonly
                                                            onChange={() => { }}
                                                        />
                                                        <InputField
                                                            inputFieldId="light-range-red"
                                                            label="Dagens rekkevidde rød"
                                                            type="text"
                                                            valueSuffix={activeUnit}
                                                            value={formatRangeNm(
                                                                selectedFeature.attributes.rekkevidde_rod,
                                                                activeUnit === "m",
                                                                false
                                                            )}
                                                            readonly
                                                            onChange={() => { }}
                                                        />
                                                    </div>
                                                )}
                                                <div className="flex-row w-100 small-gap">
                                                    <InputField
                                                        inputFieldId="effective-light-intensity-red"
                                                        label="Effektiv lysstyrke rød"
                                                        type="text"
                                                        valueSuffix="cd"
                                                        value={calculationResults?.effectiveIntensityRed ?? ""}
                                                        readonly
                                                        onChange={() => { }}
                                                    />
                                                    <InputField
                                                        inputFieldId="calculated-range-red"
                                                        label="Beregnet rekkevidde rød"
                                                        type="text"
                                                        valueSuffix={activeUnit}
                                                        value={formatRangeNm(
                                                            calculationResults?.rangeRed,
                                                            activeUnit === "m",
                                                            false
                                                        )}
                                                        readonly
                                                        onChange={() => { }}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                {(lightColor === LightCalculationLightColors.GREEN ||
                                    (lightColor === null &&
                                        colorsToShow.includes(LightCalculationLightColors.GREEN))) && (
                                        <>
                                            {selectedCandela?.attributes.type === "LED" && (
                                                <div className="flex-row w-100 small-gap">
                                                    <InputField
                                                        inputFieldId="light-intensity-green"
                                                        label="Lysstyrke (Grønn)"
                                                        type="number"
                                                        valueSuffix="cd"
                                                        value={intensityGreen?.toString()}
                                                        onChange={(value) => {
                                                            setIntensityGreen(parseFloat(value));
                                                        }}
                                                    />
                                                    <InputField
                                                        inputFieldId="percentage-used-green"
                                                        label="Prosent utnyttelse"
                                                        type="number"
                                                        min={0}
                                                        max={100}
                                                        valueSuffix="percent"
                                                        value={percentageGreen?.toString()}
                                                        onChange={(value) => {
                                                            setPercentageGreen(parseFloat(value));
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <div className="flex-col small-gap">
                                                {selectedFeature && (
                                                    <div className="flex-row w-100 small-gap">
                                                        <InputField
                                                            inputFieldId="light-intensity-green"
                                                            label="Dagens lystyrke grønn"
                                                            type="text"
                                                            valueSuffix="cd"
                                                            value={selectedFeature.attributes.lysstyrke_gronn ?? ""}
                                                            readonly
                                                            onChange={() => { }}
                                                        />
                                                        <InputField
                                                            inputFieldId="light-range-green"
                                                            label="Dagens rekkevidde grønn"
                                                            type="text"
                                                            valueSuffix={activeUnit}
                                                            value={formatRangeNm(
                                                                selectedFeature.attributes.rekkevidde_gronn,
                                                                activeUnit === "m",
                                                                false
                                                            )}
                                                            readonly
                                                            onChange={() => { }}
                                                        />
                                                    </div>
                                                )}
                                                <div className="flex-row w-100 small-gap">
                                                    <InputField
                                                        inputFieldId="effective-light-intensity-green"
                                                        label="Effektiv lysstyrke grønn"
                                                        type="text"
                                                        valueSuffix="cd"
                                                        value={calculationResults?.effectiveIntensityGreen ?? ""}
                                                        readonly
                                                        onChange={() => { }}
                                                    />
                                                    <InputField
                                                        inputFieldId="calculated-range-green"
                                                        label="Beregnet rekkevidde grønn"
                                                        type="text"
                                                        valueSuffix={activeUnit}
                                                        value={formatRangeNm(
                                                            calculationResults?.rangeGreen,
                                                            activeUnit === "m",
                                                            false
                                                        )}
                                                        readonly
                                                        onChange={() => { }}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                {selectedCandela?.attributes.type === "GLØDE" && (
                                    <>
                                        {(lightColor === LightCalculationLightColors.YELLOW ||
                                            (lightColor === null &&
                                                colorsToShow.includes(LightCalculationLightColors.YELLOW))) && (
                                                <>
                                                    {/* <div className="flex-row w-100 small-gap">
                                            <InputField
                                                inputFieldId="light-intensity-yellow"
                                                label="Lysstyrke (Gul)"
                                                type="number"
                                                value={""}
                                                onChange={() => { }}
                                            />
                                            <InputField
                                                inputFieldId="percentage-used-yellow"
                                                label="Prosent utnyttelse"
                                                type="number"
                                                min={0}
                                                max={100}
                                                value={""}
                                                onChange={() => { }}
                                            />
                                        </div> */}
                                                    <div className="flex-col small-gap">
                                                        {selectedFeature && (
                                                            <div className="flex-row w-100 small-gap">
                                                                <InputField
                                                                    inputFieldId="light-intensity-yellow"
                                                                    label="Dagens lystyrke gul"
                                                                    type="text"
                                                                    valueSuffix="cd"
                                                                    value={selectedFeature.attributes.lysstyrke_gul ?? ""}
                                                                    readonly
                                                                    onChange={() => { }}
                                                                />
                                                                <InputField
                                                                    inputFieldId="light-range-yellow"
                                                                    label="Dagens rekkevidde gul"
                                                                    type="text"
                                                                    valueSuffix={activeUnit}
                                                                    value={formatRangeNm(
                                                                        selectedFeature.attributes.rekkevidde_gul,
                                                                        activeUnit === "m",
                                                                        false
                                                                    )}
                                                                    readonly
                                                                    onChange={() => { }}
                                                                />
                                                            </div>
                                                        )}
                                                        <div className="flex-row w-100 small-gap">
                                                            <InputField
                                                                inputFieldId="effective-light-intensity-yellow"
                                                                label="Effektiv lysstyrke gul"
                                                                type="text"
                                                                valueSuffix="cd"
                                                                value={calculationResults?.effectiveIntensityYellow ?? ""}
                                                                readonly
                                                                onChange={() => { }}
                                                            />
                                                            <InputField
                                                                inputFieldId="calculated-range-yellow"
                                                                label="Beregnet rekkevidde gul"
                                                                type="text"
                                                                valueSuffix={activeUnit}
                                                                value={formatRangeNm(
                                                                    calculationResults?.rangeYellow,
                                                                    activeUnit === "m",
                                                                    false
                                                                )}
                                                                readonly
                                                                onChange={() => { }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                    </>
                                )}
                            </>
                        )}
                        {shouldUseCandelaForLED() && (
                            <>
                                <div className="flex-row small-gap">
                                    <label className={`input-field-label`}>
                                        Lampe
                                        <NfsDropdown
                                            options={relevantCandelaCLs.map((candela) => candela.attributes.lampe)}
                                            allowEmpty
                                            selectedOption={selectedCandela?.attributes.lampe ?? null}
                                            setSelectedOption={selectCandela}
                                            placeholder="Velg lampe"
                                            searchable
                                        />
                                    </label>
                                    <InputField
                                        inputFieldId="lamp-type"
                                        label="Lampe type"
                                        type="text"
                                        readonly
                                        value={selectedCandela?.attributes.type ?? ""}
                                        onChange={() => { }}
                                    />
                                </div>
                                <div className="flex-row small-gap">
                                    <InputField
                                        inputFieldId="desired-distance"
                                        label={`Ønsket rekkevidde i ${activeUnit}`}
                                        type="number"
                                        min={0}
                                        step={0.1}
                                        valueSuffix={activeUnit}
                                        value={desiredDistanceDynamicInput}
                                        onChange={handleDesiredDistanceChange}
                                        w100
                                    />
                                </div>
                                <div className="flex-row small-gap w-100">
                                    <label className={`input-field-label`}>
                                        Fyrliste karakter
                                        <NfsDropdown
                                            options={relevantCharacterCLs.map((character) => character.attributes.kode)}
                                            allowEmpty
                                            selectedOption={selectedCharacter?.attributes.kode ?? null}
                                            setSelectedOption={selectCharacter}
                                            placeholder="Velg fyrliste karakter"
                                            searchable
                                        />
                                    </label>
                                </div>
                                <div className="flex-row w-100 small-gap">
                                    <label className={`input-field-label`}>
                                        Karakter
                                        <NfsDropdown
                                            disabled={selectedCharacter === null}
                                            options={relevantCharacterTimes.map(
                                                (character) => character.attributes.karakter_tid
                                            )}
                                            selectedOption={selectedCharacter?.attributes.karakter_tid ?? null}
                                            setSelectedOption={selectCharacterTime}
                                            placeholder="Velg karakter"
                                        />
                                    </label>
                                </div>
                                <div className="flex-row w-100 small-gap">
                                    <label className={`input-field-label`}>
                                        Bakgrunnsbelysning
                                        <NfsDropdown
                                            options={[
                                                BackgroundLight.NONE,
                                                BackgroundLight.MEDIUM,
                                                BackgroundLight.HIGH,
                                            ]}
                                            selectedOption={backgroundLight}
                                            setSelectedOption={(value) => setBackgroundLight(value as BackgroundLight)}
                                            placeholder="Velg bakgrunnsbelysning"
                                        />
                                    </label>
                                    <InputField
                                        inputFieldId="shortest-active-time"
                                        label="Korteste aktive tid"
                                        type="text"
                                        value={shortestActiveTime}
                                        readonly
                                        onChange={() => { }}
                                    />
                                </div>
                                <div className="flex-row w-100 small-gap">
                                    <label className={`input-field-label`}>
                                        Lysfarge
                                        <NfsDropdown
                                            options={[
                                                LightCalculationLightColors.WHITE,
                                                LightCalculationLightColors.RED,
                                                LightCalculationLightColors.GREEN,
                                                LightCalculationLightColors.YELLOW,
                                            ]}
                                            allowEmpty
                                            readonly
                                            selectedOption={
                                                colorsToShow.length > 0 ? colorsToShow.join(", ") : lightColor
                                            }
                                            setSelectedOption={(value) =>
                                                setLightColor(value as LightCalculationLightColors)
                                            }
                                            placeholder="Velg lysfarge"
                                        />
                                    </label>
                                    <label className={`input-field-label`}>
                                        Lysgjennomgang
                                        <NfsDropdown
                                            options={[
                                                PassThroughMaterial.GLASS,
                                                PassThroughMaterial.PLASTIC,
                                                PassThroughMaterial.NONE,
                                            ]}
                                            selectedOption={passthroughMaterial}
                                            setSelectedOption={(value) =>
                                                setPassthroughMaterial(value as PassThroughMaterial)
                                            }
                                            placeholder="Velg lysgjennomgang"
                                        />
                                    </label>
                                </div>
                                {(lightColor === LightCalculationLightColors.WHITE ||
                                    (lightColor === null &&
                                        colorsToShow.includes(LightCalculationLightColors.WHITE))) && (
                                        <>
                                            <div className="flex-row w-100 small-gap">
                                                <InputField
                                                    inputFieldId="light-intensity-white-min"
                                                    label="Min lysstyrke Cd (Hvit)"
                                                    type="text"
                                                    readonly
                                                    valueSuffix="cd"
                                                    value={whiteMinCandela?.toString()}
                                                    onChange={() => { }}
                                                />
                                                <InputField
                                                    inputFieldId="light-intensity-white-max"
                                                    label="Maks lysstyrke Cd (Hvit)"
                                                    type="text"
                                                    readonly
                                                    valueSuffix="cd"
                                                    value={whiteMaxCandela?.toString()}
                                                    onChange={() => { }}
                                                />
                                            </div>

                                            <div className="flex-col small-gap">
                                                {selectedFeature && (
                                                    <div className="flex-row w-100 small-gap">
                                                        <InputField
                                                            inputFieldId="light-intensity-white"
                                                            label="Dagens lystyrke hvit"
                                                            type="text"
                                                            valueSuffix="cd"
                                                            value={selectedFeature.attributes.lysstyrke_hvit ?? ""}
                                                            readonly
                                                            onChange={() => { }}
                                                        />
                                                        <InputField
                                                            inputFieldId="light-range-white"
                                                            label="Dagens rekkevidde hvit"
                                                            type="text"
                                                            valueSuffix={activeUnit}
                                                            value={formatRangeNm(
                                                                selectedFeature.attributes.rekkevidde_hvit,
                                                                activeUnit === "m",
                                                                false
                                                            )}
                                                            readonly
                                                            onChange={() => { }}
                                                        />
                                                    </div>
                                                )}
                                                <div className="flex-row w-100 small-gap">
                                                    <InputField
                                                        inputFieldId="distance-white"
                                                        label="Rekkevidde hvit"
                                                        type="text"
                                                        value={formatRangeOfRangeNm(rangeWhite, activeUnit === "m", true)}
                                                        readonly
                                                        onChange={() => { }}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                {(lightColor === LightCalculationLightColors.RED ||
                                    (lightColor === null &&
                                        colorsToShow.includes(LightCalculationLightColors.RED))) && (
                                        <>
                                            <div className="flex-row w-100 small-gap">
                                                <InputField
                                                    inputFieldId="light-intensity-red-min"
                                                    label="Min lysstyrke Cd (Rød)"
                                                    type="text"
                                                    readonly
                                                    valueSuffix="cd"
                                                    value={redMinCandela?.toString()}
                                                    onChange={() => { }}
                                                />
                                                <InputField
                                                    inputFieldId="light-intensity-red-max"
                                                    label="Maks lysstyrke Cd (Rød)"
                                                    type="text"
                                                    readonly
                                                    valueSuffix="cd"
                                                    value={redMaxCandela?.toString()}
                                                    onChange={() => { }}
                                                />
                                            </div>

                                            <div className="flex-col small-gap">
                                                {selectedFeature && (
                                                    <div className="flex-row w-100 small-gap">
                                                        <InputField
                                                            inputFieldId="light-intensity-red"
                                                            label="Dagens lystyrke rød"
                                                            type="text"
                                                            valueSuffix="cd"
                                                            value={selectedFeature.attributes.lysstyrke_rod ?? ""}
                                                            readonly
                                                            onChange={() => { }}
                                                        />
                                                        <InputField
                                                            inputFieldId="light-range-red"
                                                            label="Dagens rekkevidde rød"
                                                            type="text"
                                                            valueSuffix={activeUnit}
                                                            value={formatRangeNm(
                                                                selectedFeature.attributes.rekkevidde_rod,
                                                                activeUnit === "m",
                                                                false
                                                            )}
                                                            readonly
                                                            onChange={() => { }}
                                                        />
                                                    </div>
                                                )}
                                                <div className="flex-row w-100 small-gap">
                                                    <InputField
                                                        inputFieldId="distance-red"
                                                        label="Rekkevidde rød"
                                                        type="text"
                                                        value={formatRangeOfRangeNm(rangeRed, activeUnit === "m", true)}
                                                        readonly
                                                        onChange={() => { }}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                {(lightColor === LightCalculationLightColors.GREEN ||
                                    (lightColor === null &&
                                        colorsToShow.includes(LightCalculationLightColors.GREEN))) && (
                                        <>
                                            <div className="flex-row w-100 small-gap">
                                                <InputField
                                                    inputFieldId="light-intensity-green-min"
                                                    label="Min lysstyrke Cd (Grønn)"
                                                    type="text"
                                                    readonly
                                                    valueSuffix="cd"
                                                    value={greenMinCandela?.toString()}
                                                    onChange={() => { }}
                                                />
                                                <InputField
                                                    inputFieldId="light-intensity-green-max"
                                                    label="Maks lysstyrke Cd (Grønn)"
                                                    type="text"
                                                    readonly
                                                    valueSuffix="cd"
                                                    value={greenMaxCandela?.toString()}
                                                    onChange={() => { }}
                                                />
                                            </div>

                                            <div className="flex-col small-gap">
                                                {selectedFeature && (
                                                    <div className="flex-row w-100 small-gap">
                                                        <InputField
                                                            inputFieldId="light-intensity-green"
                                                            label="Dagens lystyrke grønn"
                                                            type="text"
                                                            valueSuffix="cd"
                                                            value={selectedFeature.attributes.lysstyrke_gronn ?? ""}
                                                            readonly
                                                            onChange={() => { }}
                                                        />
                                                        <InputField
                                                            inputFieldId="light-range-green"
                                                            label="Dagens rekkevidde grønn"
                                                            type="text"
                                                            valueSuffix={activeUnit}
                                                            value={formatRangeNm(
                                                                selectedFeature.attributes.rekkevidde_gronn,
                                                                activeUnit === "m",
                                                                false
                                                            )}
                                                            readonly
                                                            onChange={() => { }}
                                                        />
                                                    </div>
                                                )}
                                                <div className="flex-row w-100 small-gap">
                                                    <InputField
                                                        inputFieldId="distance-green"
                                                        label="Rekkevidde grønn"
                                                        type="text"
                                                        value={formatRangeOfRangeNm(rangeGreen, activeUnit === "m", true)}
                                                        readonly
                                                        onChange={() => { }}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                <div className="flex-col w-100 small-gap">
                                    {resultCandela.message !== "" && (
                                        <InputField
                                            inputFieldId="effective-light-intensity-candela-message"
                                            label="Feilmelding"
                                            type="text"
                                            isTextArea
                                            textAreaRows={2}
                                            value={resultCandela.message}
                                            readonly
                                            onChange={() => { }}
                                        />
                                    )}
                                    <InputField
                                        inputFieldId="effective-light-intensity-candela"
                                        label="Effektiv lysstyrke Cd (innstilling laterne)"
                                        type="text"
                                        valueSuffix="cd"
                                        value={resultCandela.effectiveIntensity.toFixed(2) ?? ""}
                                        readonly
                                        onChange={() => { }}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="light-calculator-footer flex-col">
                    <div className="flex-row small-gap">
                        <NfsButton onClick={() => resetAndLoadValuesFromSelectedFeature(false)}>Nullstill</NfsButton>
                        {selectedFeature && featureHasLight(selectedFeature) && (
                            <NfsButton onClick={() => { resetAndLoadValuesFromSelectedFeature(true) }}>
                                Last fra objekt
                            </NfsButton>
                        )}
                    </div>
                    {selectedFeature && featureHasLight(selectedFeature) && canSaveLightValues() && (
                        <NfsButton disabled={isSaving} onClick={saveLightValues}>
                            Lagre
                        </NfsButton>
                    )}
                </div>
            </div>
            <button type="button" className={`light-calculator-collapse-button ${panelClass}`} onClick={toggleOpen}>
                <LeftArrowIcon />
            </button>
        </div>
    );
};

export default LightCalculator;

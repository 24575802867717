import React, { useRef, useEffect } from 'react';
import './Loader.css';

interface LoaderProps {
    size?: number;
    color?: string;
    overlay?: 'fullscreen' | 'element' | false;
    inline?: boolean;
    targetElement?: string;
}

const Loader: React.FC<LoaderProps> = ({
    size = 40,
    color = '#007bff',
    overlay = false,
    inline = false,
    targetElement
}) => {
    const loaderRef = useRef<HTMLDivElement>(null);
    const originalPositionRef = useRef<string | null>(null);

    const style = {
        width: `${size}px`,
        height: `${size}px`,
        borderColor: color,
        borderTopColor: 'transparent',
    };

    useEffect(() => {
        if (overlay === 'element' && targetElement) {
            const target = document.querySelector(targetElement) as HTMLElement;
            if (target && loaderRef.current) {
                originalPositionRef.current = target.style.position;
                target.style.position = 'relative';
                loaderRef.current.style.position = 'absolute';
                loaderRef.current.style.top = '0';
                loaderRef.current.style.left = '0';
                loaderRef.current.style.width = '100%';
                loaderRef.current.style.height = '100%';
                target.appendChild(loaderRef.current);
            }
        }

        return () => {
            if (overlay === 'element' && targetElement) {
                const target = document.querySelector(targetElement) as HTMLElement;
                if (target) {
                    if (originalPositionRef.current !== null) {
                        target.style.position = originalPositionRef.current;
                    }
                }
            }
        };
    }, [overlay, targetElement]);

    const getOverlayClass = () => {
        if (overlay === 'fullscreen') return 'loader-overlay-fullscreen';
        if (overlay === 'element') return 'loader-overlay-element';
        return '';
    };

    return (
        <div
            ref={loaderRef}
            className={`loader-container ${getOverlayClass()} ${inline ? 'loader-inline' : ''}`}
        >
            <div className="loader" style={style}></div>
        </div>
    );
};

export default Loader;

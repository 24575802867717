import "../InputField/InputField.css";
import "./TextArea.css";

interface TextAreaProps {
    inputFieldId: string;
    label: string;
    value: string;
    onChange: (value: string) => void;
    onBlur?: () => void;
    readonly?: boolean;
    maxLength?: number;
}

const TextArea = (props: TextAreaProps) => {
    return (
        <label htmlFor={props.inputFieldId} className="input-field-label">
            {props.label}
            <div className={`input-field-container text-area-container ${props.readonly ? "readonly" : ""}`}>
                <textarea
                    id={props.inputFieldId}
                    readOnly={props.readonly}
                    onChange={(event) => props.onChange(event.target.value)}
                    onBlur={props.onBlur}
                    value={props.value}
                    className="input-field"
                    maxLength={props.maxLength ?? 200}
                />
            </div>
        </label>
    );
};

export default TextArea;

import Color from "@arcgis/core/Color.js";
import Graphic from "@arcgis/core/Graphic";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import * as symbolUtils from "@arcgis/core/symbols/support/symbolUtils.js";
import { useEffect, useState } from "react";
import { getMapView } from "../../../utils/arcgisUtils";
import { SectorColor, SectorFallbackColor } from "../../../utils/lighthouseUtils";
import "./SectorColorIndicator.css";

interface SectorColorIndicatorProps {
    color: string;
    height?: string;
}

const SectorColorIndicator = (props: SectorColorIndicatorProps) => {
    const { color, height } = props;

    const [displayColor, setDisplayColor] = useState<Color>(new Color(SectorColor.DARK));

    useEffect(() => {
        const mapview = getMapView();
        const sectorColorLayer = mapview?.map.findLayerById("Lysfjlewsektor");
        const dummyGraphic = new Graphic({ layer: sectorColorLayer, attributes: { lys_farge: parseInt(color) } });
        const sectorColorSymbol = symbolUtils.getDisplayedSymbol(dummyGraphic) as Promise<SimpleFillSymbol>;

        sectorColorSymbol.then((symbol) => {
            if (symbol) {
                setDisplayColor(symbol.color);
            } else {
                // Get the fallback color
                switch (color) {
                    case SectorColor.GREEN:
                        setDisplayColor(new Color(SectorFallbackColor.GREEN));
                        break;
                    case SectorColor.WHITE:
                        setDisplayColor(new Color(SectorFallbackColor.WHITE));
                        break;
                    case SectorColor.RED:
                        setDisplayColor(new Color(SectorFallbackColor.RED));
                        break;
                    case SectorColor.DARK:
                        setDisplayColor(new Color(SectorFallbackColor.DARK));
                        break;
                }
            }
        });
    }, [color]);

    return (
        <div
            className="sector-color-indicator"
            style={{
                backgroundColor: displayColor.toCss(true),
                height: height ?? "100%",
                borderWidth: color === SectorColor.DARK ? 1 : 0,
            }}
        />
    );
};

export default SectorColorIndicator;

import { createSlice } from "@reduxjs/toolkit";

interface LighthouseState {
    isShowingIALAWarning: boolean;
    isSectorTableOpen: boolean;
    isSectorEditorOpen: boolean;
    lighthouseTableSelectedSector: number | null;
    currentLighthouseTrueNorth: number;
    isSavingLighthouse: boolean;
}

const initialState: LighthouseState = {
    isShowingIALAWarning: false,
    isSectorTableOpen: false,
    isSectorEditorOpen: false,
    lighthouseTableSelectedSector: null,
    currentLighthouseTrueNorth: 0,
    isSavingLighthouse: false,
};

const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        setIsShowingIALAWarning(state, action) {
            state.isShowingIALAWarning = action.payload;
        },
        setIsSectorTableOpen(state, action) {
            state.isSectorTableOpen = action.payload;
        },
        setIsSectorEditorOpen(state, action) {
            state.isSectorEditorOpen = action.payload;
        },
        setLighthouseTableSelectedSector(state, action) {
            state.lighthouseTableSelectedSector = action.payload;
        },
        setCurrentLighthouseTrueNorth(state, action) {
            state.currentLighthouseTrueNorth = action.payload;
        },
        setIsSavingLighthouse(state, action) {
            state.isSavingLighthouse = action.payload;
        },
    },
});

export default searchSlice.reducer;
export const {
    setIsShowingIALAWarning,
    setIsSectorTableOpen,
    setIsSectorEditorOpen,
    setLighthouseTableSelectedSector,
    setCurrentLighthouseTrueNorth,
    setIsSavingLighthouse,
} = searchSlice.actions;
export type { LighthouseState };

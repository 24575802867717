import "./Spacer.css";

export interface SpacerProps {
    height?: number;
    width?: number;
}

const Spacer = (props: SpacerProps) => {
    const { height, width } = props;

    return <div className="spacer" style={{ height, width }}></div>;
};

export default Spacer;


import NfsButton from "../genericComponents/NfsButton/NfsButton";

import CloseIcon from "../../assets/versionControl/close.svg?react";

import "./AcceptVersionAccess.css";
import { accectVersionAccess, getVersionName, getVersionOwner } from "../../utils/versioningUtils";
import { toast } from "react-toastify";
import { useContext } from "react";
import { UserContext } from "../../Context";
import { useDispatch } from "react-redux";
import { setVersionsSharedWithMe } from "../../store/versionSlice";

interface AcceptVersionAccessProps {
    close: () => void;
    versionInfo: __esri.VersionInfoExtendedJSON | null;
}

const AcceptVersionAccess = (props: AcceptVersionAccessProps) => {

    const dispatch = useDispatch();

    const userContext = useContext(UserContext);
    const accept = async () => {
        const user = userContext?.user.value;
        if (!user) {
            return;
        }
        const newAccessList = await accectVersionAccess(props.versionInfo, user);
        dispatch(setVersionsSharedWithMe(newAccessList));
        toast.success("Du aksepterte tilgang til versjonen");
        props.close();
    }

    const decline = () => {
        toast.success("Du avslo tilgang til versjonen");
        props.close();
    }

    return (
        <div className="accept-version-access">
            <div className="accept-version-header">
                <h2>Godkjenn tilgang til versjon: {getVersionName(props.versionInfo)}</h2>
                <button className="empty-button" onClick={props.close}>
                    <CloseIcon />
                </button>
            </div>
            <div className="accept-version-content">
                <p>En annen bruker ønsker å gi deg skrive tilgang til en versjon. Vil du godkjenne tilgangen?</p>
                <span>Versjonseier: {getVersionOwner(props.versionInfo)}</span>
                <span>Versjonsnavn: {getVersionName(props.versionInfo)}</span>
                <span>Versjonsbeskrivelse: {props.versionInfo?.description}</span>
            </div>
            <div className="accept-version-footer">
                <NfsButton onClick={accept}>Godkjenn</NfsButton>
                <NfsButton outlined onClick={decline}>Avslå</NfsButton>
            </div>
        </div>
    );
};

export default AcceptVersionAccess;
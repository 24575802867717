import { LayersConfig } from "../../AppConfig";
import "./FeatureViewerTitle.css";
import LighthouseIcon from "../../assets/lighthouse.svg?react";

interface FeatureViewerTitleProps {
    symbolHtmlElement: HTMLElement | null;
    selectedFeature: __esri.Graphic;
    layerConfig: React.MutableRefObject<LayersConfig | undefined>;
}

const FeatureViewerTitle = (props: FeatureViewerTitleProps) => {
    const { symbolHtmlElement, selectedFeature, layerConfig } = props;

    return (
        <div className="feature-viewer-feature-title">
            {!selectedFeature.attributes.lighthouseData ? (
                <div
                    className="search-item-symbol"
                    dangerouslySetInnerHTML={{ __html: symbolHtmlElement?.outerHTML ?? "" }}
                ></div>
            ) : (
                <LighthouseIcon />
            )}
            <span>{selectedFeature.attributes["sjomerke_nr"] ?? selectedFeature.attributes["objectid"]}</span>
            <span>{selectedFeature.attributes[layerConfig.current?.TitleField ?? "objectid"]}</span>
        </div>
    );
};

export default FeatureViewerTitle;

import VersionManagementService from "@arcgis/core/versionManagement/VersionManagementService";
import { toast } from "react-toastify";


export const handleAddVersionClick = async (newVersionName: string, newVersionDescription: string, versionService: VersionManagementService, reloadVersions: () => Promise<void>): Promise<__esri.VersionManagementServiceVersionInfoExtendedJSON | null> => {
    if (newVersionName && newVersionName !== "") {
        try {
            const newVersionExtendedInfo = await versionService.createVersion({
                versionName: newVersionName,
                description: newVersionDescription,
                access: "public",
            });
            await reloadVersions();
            return newVersionExtendedInfo;
        } catch (e: any) {
            console.log(e);
            if (e?.message === "The version already exists") {
                toast.error("En versjon med dette navnet eksisterer allerede");
            } else {
                toast.error("Noe gikk galt under oppretting av versjon: " + e?.message);
            }
            return null
        }
    }
    return null;
};


export const handlePostVersion = async (versionInfoJson: __esri.VersionInfoJSON, versionService: VersionManagementService) => {
    const vi = versionInfoJson.versionIdentifier;

    try {
        const startReadingResult = await versionService.startReadingWithResult(vi);
        console.log("Start reading result:", startReadingResult);

        const startEditingResult = await versionService.startEditingWithResult(vi);
        console.log("Start editing result:", startEditingResult);

        const reconcileResult = await versionService.reconcile(vi, {
            abortIfConflicts: true,
            conflictDetection: "by-object",
            withPost: true,
        });

        console.log("Reconcile result:");
        console.log(reconcileResult);

        const stopEditingResult = await versionService.stopEditingWithResult(vi, true);
        console.log("Stop editing result:", stopEditingResult);

        const stopReadingResult = await versionService.stopReadingWithResult(vi);
        console.log("Stop reading result:", stopReadingResult);
    } catch (e: any) {
        const stopEditingResult = await versionService.stopEditingWithResult(vi, false);
        console.log("Stop editing result:", stopEditingResult);

        const stopReadingResult = await versionService.stopReadingWithResult(vi);
        console.log("Stop reading result:", stopReadingResult);

        alert(e.message);
    }
};


import Circle from "@arcgis/core/geometry/Circle";
import { BackgroundLight, glassFactor, greenFactor, LightCalculationLightColors, LightCalculationResult, PassThroughMaterial, plasticFactor, redFactor, visibilityFactor, yellowFactor } from "../../constants/LightCalculation";
import { getMapView } from "../../utils/arcgisUtils";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import { metersPerNauticalMile } from "../../utils/constants";
import Polygon from "@arcgis/core/geometry/Polygon";

/**
 * Takes the light character string and returns the shortest active time
 * @param karakter Light character string
 * @returns Shortest active time
 */
export const getShortestActiveTime = (karakter: string): number => {
    try {
        karakter = karakter.replaceAll(',', '.');
        karakter = karakter.replaceAll('(', ' ');
        karakter = karakter.replaceAll(')', ' ');

        const times = karakter.split('+');
        let res = 10000000.0;
        let active = true;

        for (const t of times) {
            if (active) {
                const dt = parseFloat(t);
                if (dt < res) res = dt;
            }
            active = !active;
        }

        return res;
    } catch (ex) {
        return -1;
    }
};


export const formatArcadeForLightLabel = (): string => {
    let arcade = "";

    const mapView = getMapView();
    const lightLayer = mapView?.map.allLayers.find((layer) => layer.id === "Lys") as __esri.FeatureLayer;
    if (!lightLayer) return arcade;
    const lightCharacterDomain = lightLayer.getFieldDomain("fyrliste_karakter") as __esri.CodedValueDomain;
    const lightTimeDomain = lightLayer.getFieldDomain("karakter_tid") as __esri.CodedValueDomain;
    const lightCharacterField = lightLayer.getField("fyrliste_karakter");
    const lightTimeField = lightLayer.getField("karakter_tid");

    if (lightCharacterDomain) {
        lightCharacterDomain.codedValues.forEach((codedValue) => {
            if (arcade.length === 0) {
                arcade += "Decode($feature.fyrliste_karakter";
            }
            if (lightCharacterField.type === "string") {
                arcade += `,'${codedValue.code}','${codedValue.name}'`;
            } else if (lightCharacterField.type === "integer") {
                arcade += `,${codedValue.code},'${codedValue.name}'`;
            }
        });
        if (arcade.length > 0) {
            arcade += ",'')";
        }
    }
    if (arcade.length > 0) {
        arcade += " + ' / '";
    }
    if (lightTimeDomain) {
        lightTimeDomain.codedValues.forEach((codedValue) => {
            if (arcade.endsWith(" + ' / '")) {
                arcade += " + Decode($feature.karakter_tid";
            } else if (arcade.length === 0) {
                arcade += "Decode($feature.karakter_tid";
            }
            if (lightTimeField.type === "string") {
                arcade += `,'${codedValue.code}','${codedValue.name}'`;
            } else if (lightTimeField.type === "integer") {
                arcade += `,${codedValue.code},'${codedValue.name}'`;
            }
        });
        if (lightCharacterDomain.codedValues.length > 0) {
            arcade += ",'')";
        }
    }

    return arcade;
}

/**
 * Calculates the distance at which the light intensity falls below a specified threshold using the Allard method.
 *
 * @param E - The threshold light intensity.
 * @param Transmissivity - The transmissivity of the medium.
 * @param LightIntensity - The initial light intensity.
 * @returns The distance at which the light intensity falls below the specified threshold.
 */
const allardRange = (E: number, Transmissivity: number, LightIntensity: number): number => {
    let dInit = 5;
    let delta: number;
    let d: number;
    let Ec = compAllard(LightIntensity, Transmissivity, dInit);
    d = dInit;
    if (Ec > E) {
        delta = 0.01;
        while (Ec > E) {
            d = d + delta;
            Ec = compAllard(LightIntensity, Transmissivity, d);
        }
    } else {
        delta = -0.01;
        while (Ec <= E) {
            d = d + delta;
            Ec = compAllard(LightIntensity, Transmissivity, d);
        }
    }

    return d;
}

/**
 * Computes the Allard function.
 *
 * @param I - The intensity of the light source.
 * @param T - The transmissivity of the medium.
 * @param D - The distance from the light source.
 * @returns The computed value based on the Allard function.
 */
const compAllard = (I: number, T: number, D: number): number => {
    let res = I * Math.pow(T, D) / Math.pow(D, 2);
    return res;
}

export const lightCalculationLED = (
    shortestActiveTime: number,
    passThroughMaterial: PassThroughMaterial,
    backgroundLight: BackgroundLight,
    intensityWhite?: number,
    percentageWhite?: number,
    intensityRed?: number,
    percentageRed?: number,
    intensityGreen?: number,
    percentageGreen?: number
): LightCalculationResult => {
    let rekkeviddeRod: number;
    let rekkeviddeHvit: number;
    let rekkeviddeGronn: number;

    let lysstyrkeRod: number;
    let lysstyrkeHvit: number;
    let lysstyrkeGronn: number;

    const IoHvit = (intensityWhite ?? 0) * ((percentageWhite ?? 0) / 100);
    const IoRød = (intensityRed ?? 0) * ((percentageRed ?? 0) / 100);
    const IoGrønn = (intensityGreen ?? 0) * ((percentageGreen ?? 0) / 100);

    const tau = shortestActiveTime;

    let IeHvit: number;
    let IeRød: number;
    let IeGrønn: number;
    if (tau <= 0) {
        IeHvit = IoHvit;
        IeRød = IoRød;
        IeGrønn = IoGrønn;
    } else {
        IeHvit = (IoHvit * tau) / (0.2 + tau);
        IeRød = (IoRød * tau) / (0.2 + tau);
        IeGrønn = (IoGrønn * tau) / (0.2 + tau);
    }

    if (passThroughMaterial === PassThroughMaterial.GLASS) {
        IeHvit *= glassFactor;
        IeRød *= glassFactor;
        IeGrønn *= glassFactor;
    } else if (passThroughMaterial === PassThroughMaterial.PLASTIC) {
        IeHvit *= plasticFactor;
        IeRød *= plasticFactor;
        IeGrønn *= plasticFactor;
    }

    let dWhite: number;
    let dRed: number;
    let dGreen: number;
    let E = 1;
    switch (backgroundLight) {
        case BackgroundLight.NONE:
            E = 0.67;
            break;
        case BackgroundLight.MEDIUM:
            E = 6.7;
            break;
        case BackgroundLight.HIGH:
            E = 67.0;
            break;
    }
    dWhite = allardRange(E, visibilityFactor, IeHvit);
    dRed = allardRange(E, visibilityFactor, IeRød);
    dGreen = allardRange(E, visibilityFactor, IeGrønn);

    rekkeviddeHvit = dWhite;
    rekkeviddeRod = dRed;
    rekkeviddeGronn = dGreen;
    lysstyrkeRod = IeRød;
    lysstyrkeHvit = IeHvit;
    lysstyrkeGronn = IeGrønn;

    const rangeWhite = IoHvit === 0.0 ? undefined : rekkeviddeHvit.toFixed(1) + ' Nm';
    const rangeRed = IoRød === 0.0 ? undefined : rekkeviddeRod.toFixed(1) + ' Nm';
    const rangeGreen = IoGrønn === 0.0 ? undefined : rekkeviddeGronn.toFixed(1) + ' Nm';
    const effectiveIntensityWhite = lysstyrkeHvit.toFixed(1);
    const effectiveIntensityRed = lysstyrkeRod.toFixed(1);
    const effectiveIntensityGreen = lysstyrkeGronn.toFixed(1);

    return { rangeWhite, rangeRed, rangeGreen, rangeYellow: undefined, effectiveIntensityWhite, effectiveIntensityRed, effectiveIntensityGreen, effectiveIntensityYellow: undefined };
};

export const getFeatureTable = (tableID: string) => {
    const mapView = getMapView();
    const featureTable = mapView?.map?.findTableById(tableID) as __esri.FeatureLayer;
    if (!featureTable) return null;

    return featureTable;
}

export const getDomainCodeFromValue = (layer: FeatureLayer, fieldName: string, fieldValue: string | null) => {
    let returnValue = fieldValue;
    if (!layer?.fields) return returnValue;
    layer.fields.forEach((fld: any) => {
        if (fld.name === fieldName) {
            const cDomain = fld?.domain;
            if (cDomain)
                cDomain.codedValues.forEach((cVal: any) => {
                    if (cVal.name === fieldValue) returnValue = cVal.code;
                });
        }
    });
    return returnValue;
}

export const lightCalculationIncandescent = (
    candela: number,
    shortestActiveLight: number,
    passThroughMaterial: PassThroughMaterial,
    colorTag: number,
    background: BackgroundLight
): LightCalculationResult => {
    let rekkeviddeRod: number = -1;
    let rekkeviddeHvit: number = -1;
    let rekkeviddeGronn: number = -1;
    let rekkeviddeGul: number = -1;

    let lysstyrkeRod: number = -1;
    let lysstyrkeHvit: number = -1;
    let lysstyrkeGronn: number = -1;
    let lysstyrkeGul: number = -1;

    try {
        const Io = candela;
        const tau = shortestActiveLight;
        let Ie: number;

        if (tau <= 0) {
            Ie = Io;
        } else {
            Ie = (Io * tau) / (0.2 + tau);
        }

        if (passThroughMaterial === PassThroughMaterial.GLASS) {
            Ie *= glassFactor;
        } else if (passThroughMaterial === PassThroughMaterial.PLASTIC) {
            Ie *= plasticFactor;
        }

        let E = 1;
        switch (background) {
            case BackgroundLight.NONE:
                E = 0.67;
                break;
            case BackgroundLight.MEDIUM:
                E = 6.7;
                break;
            case BackgroundLight.HIGH:
                E = 67.0;
                break;
        }

        const d = allardRange(E, visibilityFactor, Ie);
        const dRed = allardRange(E, visibilityFactor, Ie * redFactor);
        const dGreen = allardRange(E, visibilityFactor, Ie * greenFactor);
        const dYellow = allardRange(E, visibilityFactor, Ie * yellowFactor);

        switch (colorTag) {
            case 0: // tre farger
                rekkeviddeHvit = d;
                rekkeviddeRod = dRed;
                rekkeviddeGronn = dGreen;
                lysstyrkeRod = Ie * redFactor;
                lysstyrkeHvit = Ie;
                lysstyrkeGronn = Ie * greenFactor;

                break;

            case 1: // Hvit
                rekkeviddeHvit = d;
                lysstyrkeHvit = Ie;

                break;

            case 3: // Rød
                lysstyrkeRod = Ie * redFactor;
                rekkeviddeRod = dRed;
                break;

            case 4: // Grønn
                lysstyrkeGronn = Ie * greenFactor;
                rekkeviddeGronn = dGreen;
                break;

            case 6: // Gul
                rekkeviddeGul = dYellow;
                lysstyrkeGul = Ie * yellowFactor;

                break;
        }
    } catch (error: any) {
        console.error("Feil i beregning av rekkevidde: " + error);
    }

    const rangeWhite = rekkeviddeHvit !== -1 ? rekkeviddeHvit.toFixed(1) + ' Nm' : undefined;
    const rangeRed = rekkeviddeRod !== -1 ? rekkeviddeRod.toFixed(1) + ' Nm' : undefined;
    const rangeGreen = rekkeviddeGronn !== -1 ? rekkeviddeGronn.toFixed(1) + ' Nm' : undefined;
    const rangeYellow = rekkeviddeGul !== -1 ? rekkeviddeGul.toFixed(1) + ' Nm' : undefined;
    const effectiveIntensityWhite = lysstyrkeHvit !== -1 ? lysstyrkeHvit.toFixed(1) : undefined;
    const effectiveIntensityRed = lysstyrkeRod !== -1 ? lysstyrkeRod.toFixed(1) : undefined;
    const effectiveIntensityGreen = lysstyrkeGronn !== -1 ? lysstyrkeGronn.toFixed(1) : undefined;
    const effectiveIntensityYellow = lysstyrkeGul !== -1 ? lysstyrkeGul.toFixed(1) : undefined;

    return { rangeWhite, rangeRed, rangeGreen, rangeYellow, effectiveIntensityWhite, effectiveIntensityRed, effectiveIntensityGreen, effectiveIntensityYellow };
};

const lightColorMapping: Record<string, LightCalculationLightColors[]> = {
    "W": [LightCalculationLightColors.WHITE],
    "R": [LightCalculationLightColors.RED],
    "G": [LightCalculationLightColors.GREEN],
    "Y": [LightCalculationLightColors.YELLOW],
    "WR": [LightCalculationLightColors.WHITE, LightCalculationLightColors.RED],
    "WG": [LightCalculationLightColors.WHITE, LightCalculationLightColors.GREEN],
    "WY": [LightCalculationLightColors.WHITE, LightCalculationLightColors.YELLOW],
    "RG": [LightCalculationLightColors.RED, LightCalculationLightColors.GREEN],
    "RY": [LightCalculationLightColors.RED, LightCalculationLightColors.YELLOW],
    "GY": [LightCalculationLightColors.GREEN, LightCalculationLightColors.YELLOW],
    "WRG": [LightCalculationLightColors.WHITE, LightCalculationLightColors.RED, LightCalculationLightColors.GREEN],
    "WRY": [LightCalculationLightColors.WHITE, LightCalculationLightColors.RED, LightCalculationLightColors.YELLOW],
    "WGY": [LightCalculationLightColors.WHITE, LightCalculationLightColors.GREEN, LightCalculationLightColors.YELLOW],
    "RGY": [LightCalculationLightColors.RED, LightCalculationLightColors.GREEN, LightCalculationLightColors.YELLOW],
    "WRGY": [LightCalculationLightColors.WHITE, LightCalculationLightColors.RED, LightCalculationLightColors.GREEN, LightCalculationLightColors.YELLOW],
};

export const getCharacterColors = (character: string): LightCalculationLightColors[] => {
    const colors: LightCalculationLightColors[] = [];
    const splitString = character.split(" ");

    splitString.forEach((word: string) => {
        const color = lightColorMapping[word];
        if (color) {
            color.forEach((c) => {
                if (!colors.includes(c)) colors.push(c);
            });
        }
    });

    return colors;
};

export const featureHasLight = (feature: __esri.Graphic): boolean => {
    let hasLight = false;

    if (Object.keys(feature?.attributes).includes("lys_type")) {
        if ([2, 7, 8, 99].includes(feature.attributes["lys_type"])) {
            hasLight = true;
        }
    }
    if (Object.keys(feature?.attributes).includes("lys_karakter")) {
        hasLight = true;
    }

    return hasLight;
};

export const formatRangeNm = (rangeInNm: number | string | undefined, convertToMeters: boolean, includeUnit: boolean): string => {
    if (rangeInNm === undefined || rangeInNm === null) return "";

    if (typeof rangeInNm === 'string') {
        const parts = rangeInNm.split(" ");
        rangeInNm = parseFloat(parts[0]);
    }

    if (convertToMeters) {
        if (includeUnit) {
            return (rangeInNm * metersPerNauticalMile).toFixed(0) + ' m';
        } else {
            return (rangeInNm * metersPerNauticalMile).toFixed(0);
        }
    }

    if (includeUnit) {
        return rangeInNm.toFixed(2) + ' Nm';
    } else {
        return rangeInNm.toFixed(2);
    }
}

export const formatRangeOfRangeNm = (rangeInNm: string | undefined, convertToMeters: boolean, includeUnit: boolean): string => {
    if (rangeInNm === undefined || rangeInNm === null) return "";

    const cleaned = rangeInNm.replaceAll("Nm", "");
    const parts = cleaned.split(" - ");
    const range1 = parseFloat(parts[0]);
    const range2 = parseFloat(parts[1]);

    if (convertToMeters) {
        if (includeUnit) {
            return (
                (range1 * metersPerNauticalMile).toFixed(0) +
                " m" +
                " - " +
                (range2 * metersPerNauticalMile).toFixed(0) +
                " m"
            );
        } else {
            return (
                (range1 * metersPerNauticalMile).toFixed(0) +
                " - " +
                (range2 * metersPerNauticalMile).toFixed(0)
            );
        }
    }

    if (includeUnit) {
        return range1.toFixed(2) + ' Nm' + ' - ' + range2.toFixed(2) + ' Nm';
    } else {
        return range1.toFixed(2) + ' - ' + range2.toFixed(2);
    }
}

export const renderLightInMap = (location: __esri.Point, color: string, size: number, striped?: boolean): void => {
    const mapview = getMapView();
    if (!mapview) return;

    let lightRangeGraphicsLayer = mapview?.map?.findLayerById("lightRangeGraphicsLayer") as __esri.GraphicsLayer
    if (!lightRangeGraphicsLayer) {
        lightRangeGraphicsLayer = new GraphicsLayer({
            id: "lightRangeGraphicsLayer",
        });
        mapview.map.add(lightRangeGraphicsLayer);
    }

    // Create the outer circle
    const circle = new Circle({
        center: location,
        radius: size,
        radiusUnit: "nautical-miles",
        spatialReference: location.spatialReference,
    });

    // Calculate inner circle radius by converting 10px to map units
    const screenPoint = mapview.toScreen(location);
    const pixelPoint = { x: screenPoint.x + 20, y: screenPoint.y };
    const mapPoint = mapview.toMap(pixelPoint);
    const pixelDistance = location.distance(mapPoint);

    // Create the inner circle with radius reduced by the pixel distance
    const innerCircle = new Circle({
        center: location,
        radius: Math.max(0, size - (pixelDistance / metersPerNauticalMile)),
        radiusUnit: "nautical-miles",
        spatialReference: location.spatialReference,
    });

    // Create a ring by cutting the inner circle from the outer circle
    const ring = new Polygon({

        rings: [
            circle.rings[0], // Outer circle boundary
            innerCircle.rings[0].toReversed() // Inner circle boundary
        ],
        spatialReference: location.spatialReference

    })


    switch (color) {
        case "white": {
            const graphic = new Graphic({
                geometry: ring,
                symbol: new SimpleFillSymbol({
                    color: [255, 255, 0, 0.4],
                    style: "solid",
                    outline: {
                        color: [255, 255, 0, 1],
                        width: 1,
                    }
                }),
            });
            console.log(graphic)
            lightRangeGraphicsLayer.add(graphic);
            break;
        }

        case "red": {
            const graphic = new Graphic({
                geometry: ring,
                symbol: new SimpleFillSymbol({
                    color: [255, 0, 0, 1],
                    style: "forward-diagonal",
                    outline: {
                        color: [255, 0, 0, 1],
                        width: 1,
                    }
                }),
            });

            lightRangeGraphicsLayer.add(graphic);
            break;
        }

        case "green": {
            const graphic = new Graphic({
                geometry: ring,
                symbol: new SimpleFillSymbol({
                    color: [0, 255, 0, 1],
                    style: "backward-diagonal",
                    outline: {
                        color: [0, 255, 0, 1],
                        width: 1,
                    }
                }),
            });

            lightRangeGraphicsLayer.add(graphic);
            break;
        }

        case "yellow": {
            const graphic = new Graphic({
                geometry: ring,
                symbol: new SimpleFillSymbol({
                    color: [255, 255, 0, 1],
                    style: "cross",
                    outline: {
                        color: [255, 255, 0, 1],
                        width: 1,
                    }
                }),
            });
            lightRangeGraphicsLayer.add(graphic);
            break;
        }

        case "led-160": {
            const stripedColor = [255, 0, 0, 1];
            const graphic = new Graphic({
                geometry: striped ? circle : ring,
                symbol: new SimpleFillSymbol({
                    color: striped ? stripedColor : [255, 255, 0, 0.4],
                    style: striped ? "forward-diagonal" : "solid",
                    outline: {
                        color: [255, 255, 0],
                        width: 1,
                    }
                }),
            });

            lightRangeGraphicsLayer.add(graphic);
            break;
        }
    }
};

export const clearLightInMap = (): void => {
    const mapview = getMapView();
    if (!mapview) return;

    let lightRangeGraphicsLayer = mapview?.map?.findLayerById("lightRangeGraphicsLayer") as __esri.GraphicsLayer
    if (!lightRangeGraphicsLayer) return;

    lightRangeGraphicsLayer.removeAll();
}
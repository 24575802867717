export const visibilityFactor = 0.74;
export const glassFactor = 0.8;
export const plasticFactor = 0.9;

export const redFactor = 0.29;
export const greenFactor = 0.23;
export const yellowFactor = 0.74;

export enum PassThroughMaterial {
    GLASS = 'Glass',
    PLASTIC = 'Plast',
    NONE = 'Ingen'
}

export enum BackgroundLight {
    NONE = 'Ingen',
    MEDIUM = 'Midlere',
    HIGH = 'Sterk'
}

export interface LightCalculationResult {
    rangeWhite?: string;
    rangeRed?: string;
    rangeGreen?: string;
    rangeYellow?: string;
    effectiveIntensityWhite?: string;
    effectiveIntensityRed?: string;
    effectiveIntensityGreen?: string;
    effectiveIntensityYellow?: string;
}

export enum LightCalculationLightColors {
    WHITE = 'Hvit',
    RED = 'Rød',
    GREEN = 'Grønn',
    YELLOW = 'Gul',
    NONE = 'Ingen'
}

export enum LensOrder {
    FIRST = "1. ordens linse",
    SECOND = "2. ordens linse",
    THIRD = "3. ordens linse",
    FOURTH = "4. ordens linse",
    FIFTH = "5. ordens linse",
    SIXTH = "6. ordens linse"
}

export const lensOrderStringToNumber = (lensOrder: LensOrder | null): number => {
    switch (lensOrder) {
        case null:
            return 0;
        case LensOrder.FIRST:
            return 1;
        case LensOrder.SECOND:
            return 2;
        case LensOrder.THIRD:
            return 3;
        case LensOrder.FOURTH:
            return 4;
        case LensOrder.FIFTH:
            return 5;
        case LensOrder.SIXTH:
            return 6;
    }
}
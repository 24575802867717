import Graphic from "@arcgis/core/Graphic";
import { useContext, useEffect, useRef } from "react";
import { AppConfig, LayersConfig } from "../../AppConfig";
import { AppContext } from "../../Context";
import { GetCodedValueAndAlias } from "../../utils/arcgisUtils";
import FeatureViewerAttributes from "./FeatureViewerAttributes";
import FeatureViewerTitle from "./FeatureViewerTitle";
import FeatureWorkOrderGroup from "./FeatureWorkOrders/FeatureWorkOrderGroup";

interface FeatureViewerContentProps {
    symbolHtmlElement: HTMLElement | null;
    isEditing: boolean;
    editFeatureAttribute: (field: string, value: any) => void;
    showAllAttributes: boolean;
    feature: Graphic | null;
}

const FeatureViewerContent = (props: FeatureViewerContentProps) => {
    const { symbolHtmlElement, isEditing, editFeatureAttribute, showAllAttributes, feature } = props;

    const layerConfig = useRef<LayersConfig>();

    const appContext = useContext(AppContext);
    const selectedFeature = feature ?? appContext?.selectedFeature.value;

    useEffect(() => {
        layerConfig.current = AppConfig.Layers.find((layerConfig) => layerConfig.Name === selectedFeature?.layer.id);
    }, [selectedFeature]);

    if (!selectedFeature) return null;

    return (
        <div className="feature-viewer-content">
            <div className="feature-viewer-feature-box">
                <FeatureViewerTitle
                    symbolHtmlElement={symbolHtmlElement}
                    selectedFeature={selectedFeature}
                    layerConfig={layerConfig}
                />

                <div className="feature-status-panel">
                    {
                        GetCodedValueAndAlias(
                            selectedFeature.layer,
                            layerConfig.current?.StatusField ?? "",
                            selectedFeature.attributes[layerConfig.current?.StatusField ?? "status"]
                        )[1]
                    }
                </div>
                <FeatureViewerAttributes
                    editFeatureAttribute={editFeatureAttribute}
                    isEditing={isEditing}
                    // layerConfig={layerConfig}
                    selectedFeature={selectedFeature}
                    showAllAttributes={showAllAttributes}
                />
                <FeatureWorkOrderGroup />
            </div>
        </div>
    );
};

export default FeatureViewerContent;

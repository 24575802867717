import Collection from "@arcgis/core/core/Collection";
import * as versionManagementAdapterUtils from "@arcgis/core/versionManagement/versionAdapters/utils.js";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/rootReducer";
import { setActiveGuid, setActiveSessionID, setVersionInfo, setVersionsSharedWithMe } from "../../store/versionSlice";
import { getMapView } from "../../utils/arcgisUtils";
import { getSessionID, getVersionsSharedWithMe, isDefaultVersion, isMyVersion } from "../../utils/versioningUtils";

import MagnifyingGlassIcon from "../../assets/header/magnifyingglass.svg?react";
import CloseIcon from "../../assets/versionControl/close.svg?react";
import PlusIcon from "../../assets/versionControl/plusIcon.svg?react";
import RefreshIcon from "../../assets/versionControl/refresh.svg?react";
import SortIcon from "../../assets/versionControl/sort.svg?react";

import Modal from "react-modal";
import { toast } from "react-toastify";
import { AppContext, UserContext, VersionContext } from "../../Context";
import { setVersionControlOpen } from "../../store/appSlice";
import { deobscureString } from "../../utils/helpers";
import Loader from "../genericComponents/Loader/Loader";
import NfsButton from "../genericComponents/NfsButton/NfsButton";
import NfsDropdown from "../genericComponents/NfsDropdown/NfsDropdown";
import TabItem from "../genericComponents/Tabs/TabItem";
import TabItems from "../genericComponents/Tabs/TabItems";
import TabPanel from "../genericComponents/Tabs/TabPanel";
import TabPanels from "../genericComponents/Tabs/TabPanels";
import Tabs from "../genericComponents/Tabs/Tabs";
import AcceptVersionAccess from "./AcceptVersionAccess";
import CreateNewVersion from "./CreateNewVersion";
import "./VersionControl.css";
import { handleAddVersionClick } from "./VersionControlUtils";
import VersionPanel from "./VersionPanel";
import { FeatureLayerNFS } from "../../extensions/FeatureLayerNFS";

const VersionControl = () => {
    const [createVersionModalOpen, setCreateVersionModalOpen] = useState<boolean>(false);
    const [acceptVersionModalOpen, setAcceptVersionModalOpen] = useState<boolean>(false);
    const [versionToAccept, setVersionToAccept] = useState<__esri.VersionInfoExtendedJSON | null>(null);
    const [versionSearchString, setVersionSearchString] = useState<string>("");
    const [versionSort, setVersionSort] = useState<"A-Å" | "Å-A" | "Nyeste" | "Eldste">("A-Å");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const versionControlOpen = useSelector((state: StoreState) => state.app.versionControlOpen);
    const userContext = useContext(UserContext);
    const versionContext = useContext(VersionContext);
    const versionsSharedWithMe = useSelector((state: StoreState) => state.version.versionsSharedWithMe);

    const appContext = useContext(AppContext);
    const selectedFeature = appContext?.selectedFeature.value;

    const dispatch = useDispatch();

    const versionServiceRef = versionContext!.versionServiceRef.value;

    const [versionInfos, setVersionInfos] = useState<__esri.VersionInfoExtendedJSON[]>();

    // const activeSessionID = useSelector((state: StoreState) => state.version.activeSessionID);
    // const activeGuid = useSelector((state: StoreState) => state.version.activeGuid);
    // const readActive = useSelector((state: StoreState) => state.version.readActive);
    // const editActive = useSelector((state: StoreState) => state.version.editActive);
    const versionInfo = useSelector((state: StoreState) => state.version.versionInfo);

    const reloadVersions = async () => {
        setIsLoading(true);
        const tempVersionInfos = await versionServiceRef.current.getVersionInfos();
        const filteredVersionInfos = tempVersionInfos.filter((vi) => vi.access === "public");
        await versionServiceRef.current.load();

        // Make all API calls in parallel but handle failures
        const results = await Promise.allSettled(
            filteredVersionInfos.map((versionInfo) =>
                versionServiceRef.current.getVersionInfoExtended(versionInfo.versionIdentifier)
            )
        );

        const extendedVersionInfos = results
            .filter(
                (result): result is PromiseFulfilledResult<__esri.VersionInfoExtendedJSON> =>
                    result.status === "fulfilled"
            )
            .map((result) => result.value);

        const failures = results.filter((result) => result.status === "rejected");

        if (failures.length > 0) {
            toast.warning(`${failures.length} av ${filteredVersionInfos.length} versjoner kunne ikke lastes.`);
        }

        setVersionInfos(extendedVersionInfos);
        setIsLoading(false);
    };

    const loadService = async () => {
        await versionServiceRef.current.load();
        await reloadVersions();
    };

    const handleSelectVersion = async (vi: __esri.VersionInfoJSON) => {
        const map = getMapView()?.map;
        if (!map) {
            return;
        }
        let layers = map?.allLayers.toArray() as __esri.FeatureLayer[];
        layers = layers?.filter((l) => l.type === "feature");
        if (!layers) {
            return;
        }

        if (selectedFeature !== null && selectedFeature !== undefined) {
            // Confirmation popup that informs the user that the selected feature will be closed.
            // If the user confirms, the selected feature will be closed and the version will be changed.
            // If the user cancels, the version will not be changed.
            if (
                !window.confirm(
                    "Du har et åpent objekt. Hvis du endrer versjon vil dette objektet lukkes. Endringer som ikke er lagret vil gå tapt. Vil du fortsette?"
                )
            ) {
                return;
            }
            appContext?.selectedFeature.set(null);
        }

        let tables = map?.allTables.toArray() as __esri.FeatureLayer[];
        tables = tables?.filter((l) => l.type === "feature");
        layers = layers.concat(tables);
        layers = layers.filter((l) => (l as FeatureLayerNFS).isVersioned);
        const adapters = versionManagementAdapterUtils.createVersionAdapters(layers);
        const adaptersCollection: Collection<__esri.VersionAdapter> = new Collection();
        adaptersCollection.addMany(adapters);

        const currentVersionIdentifier =
            versionInfos?.find((x) => x.versionIdentifier.name === layers[0].gdbVersion)?.versionIdentifier ??
            versionServiceRef.current.defaultVersionIdentifier;

        const changeResult = await versionServiceRef.current.changeVersionWithResult(
            adaptersCollection,
            currentVersionIdentifier,
            vi.versionIdentifier
        );

        let failureCount = 0;
        let failureMessage = "";
        changeResult.forEach((result) => {
            if (result.error) {
                failureCount++;
                failureMessage = result.error.message;
            }
        });
        if (failureCount > 0) {
            toast.error(
                `${failureCount} av kartlagene kunne ikke endre versjon. Last inn siden og prøv igjen. Mottatt feilmelding er: ${failureMessage}`
            );
            return;
        }

        let guid = vi.versionIdentifier.guid;
        dispatch(setVersionInfo(vi));
        window.history.pushState({}, "", window.location.origin + "?version=" + vi.versionIdentifier.name);
        if (!guid) {
            return;
        }
        guid = guid.replace("{", "").replace("}", "");
        dispatch(setActiveGuid(guid));
        const sessionID = getSessionID();
        dispatch(setActiveSessionID(sessionID));
    };

    const deleteVersion = (versionInfo: __esri.VersionInfoJSON) => {
        versionServiceRef.current.getVersionInfos().then((versionInfos) => {
            const versionInfoToDelete = versionInfos.find(
                (x) => x.versionIdentifier.guid === versionInfo.versionIdentifier.guid
            );
            if (!versionInfoToDelete) {
                // alert("Version info not found for this name");
                return;
            }

            versionServiceRef.current.deleteVersion(versionInfoToDelete.versionIdentifier).then(() => {
                reloadVersions();
            });

            versionServiceRef.current.load().then(() => {
                const defaultVersionInfo = versionInfos.find(
                    (x) => x.versionIdentifier.guid === versionServiceRef.current.defaultVersionIdentifier.guid
                );
                if (!defaultVersionInfo) {
                    return;
                }
                handleSelectVersion(defaultVersionInfo);
            });
        });
    };

    const openCreateVersion = () => {
        setCreateVersionModalOpen(true);
    };

    const createNewVersion = async (versionName: string, versionDescription: string) => {
        return await handleAddVersionClick(versionName, versionDescription, versionServiceRef.current, reloadVersions);
    };

    const selectDefaultVersion = async () => {
        const versionInfos = await versionServiceRef.current.getVersionInfos();
        const defaultVersionInfo = versionInfos.find(
            (x) => x.versionIdentifier.guid === versionServiceRef.current.defaultVersionIdentifier.guid
        );
        if (!defaultVersionInfo) {
            return;
        }
        handleSelectVersion(defaultVersionInfo);
    };

    const getDefaultVersion = () => {
        const defaultVersionInfo = versionInfos?.find(
            (x) => x.versionIdentifier.guid === versionServiceRef.current.defaultVersionIdentifier.guid
        );
        if (!defaultVersionInfo) {
            return;
        }
        return defaultVersionInfo;
    };

    const checkForVersionToAccept = () => {
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.searchParams);

        const versionGuid = searchParams.get("sharing");
        const decodedString = deobscureString(versionGuid ?? "");
        const stringParts = decodedString.split(",");
        const decodedGuid = stringParts[0];
        const expiresAt = stringParts[1];

        if (Date.now() > new Date(parseInt(expiresAt)).getTime()) {
            window.history.pushState({}, "", window.location.origin);
            toast.warning("Delingslenken har utløpt");
            return;
        }

        const versionInfo = versionInfos?.find((x) => x.versionIdentifier.guid === "{" + decodedGuid + "}");
        if (!decodedGuid || !versionInfo) {
            return;
        }

        setVersionToAccept(versionInfo);
        if (versionsSharedWithMe?.includes(versionInfo.versionIdentifier.guid)) {
            setVersionToAccept(null);
            window.history.pushState({}, "", window.location.origin);
            toast.info("Du har allerede akseptert denne versjonen");
            return;
        }

        // Accept version
        setAcceptVersionModalOpen(true);
        window.history.pushState({}, "", window.location.origin);
    };

    const checkForUrlVersion = (): boolean => {
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.searchParams);

        const versionName = searchParams.get("version");
        const hasSharing = searchParams.has("sharing");
        if (hasSharing) {
            setTimeout(() => {
                checkForUrlVersion();
            }, 1000);
            return false;
        }
        if (!versionName) {
            selectDefaultVersion();
            return false;
        }

        const tempVersionInfo = versionInfos?.find((x) => x.versionIdentifier.name === versionName);
        if (!tempVersionInfo || tempVersionInfo === null) {
            return false;
        }

        if (versionInfo === null || versionInfo?.versionIdentifier.name !== versionName) {
            handleSelectVersion(tempVersionInfo);
            return true;
        }
        return false;
    };

    const validForSearch = (versionInfo: __esri.VersionInfoExtendedJSON, searchString: string) => {
        if (!searchString) {
            return true;
        }
        if (versionInfo.versionIdentifier.name.toLowerCase().includes(searchString.toLowerCase())) {
            return true;
        }
        if (versionInfo.description?.toLowerCase().includes(searchString.toLowerCase())) {
            return true;
        }
        return false;
    };

    const handleVersionSearchString = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVersionSearchString(e.target.value);
    };

    const filterVersions = (versionInfos?: __esri.VersionInfoExtendedJSON[], isMe?: boolean) => {
        if (!versionInfos) {
            return [];
        }

        const copiedVersionInfos = [...versionInfos];

        if (versionSort === "A-Å") {
            copiedVersionInfos.sort((a, b) => a.versionIdentifier.name.localeCompare(b.versionIdentifier.name));
        } else if (versionSort === "Å-A") {
            copiedVersionInfos.sort((a, b) => b.versionIdentifier.name.localeCompare(a.versionIdentifier.name));
        } else if (versionSort === "Nyeste") {
            copiedVersionInfos.sort(
                (a, b) => new Date(b.modifiedDate ?? 0).getTime() - new Date(a.modifiedDate ?? 0).getTime()
            );
        } else if (versionSort === "Eldste") {
            copiedVersionInfos.sort(
                (a, b) => new Date(a.modifiedDate ?? 0).getTime() - new Date(b.modifiedDate ?? 0).getTime()
            );
        }

        if (isMe) {
            return copiedVersionInfos.filter((vi) => {
                const user = userContext?.user.value;
                if (!user || user === null) {
                    return false;
                }
                if (!isMyVersion(vi, user)) {
                    return false;
                }
                if (!validForSearch(vi, versionSearchString)) {
                    return false;
                }
                if (isDefaultVersion(vi, versionServiceRef.current)) {
                    return false;
                }
                return true;
            });
        } else {
            return copiedVersionInfos.filter((vi) => {
                if (!validForSearch(vi, versionSearchString)) {
                    return false;
                }
                if (isDefaultVersion(vi, versionServiceRef.current)) {
                    return false;
                }
                return true;
            });
        }
    };

    useEffect(() => {
        if (versionInfos) {
            checkForVersionToAccept();
            checkForUrlVersion();
        }
    }, [versionInfos]);

    useEffect(() => {
        loadService();
        const tryGetVersions = async () => {
            const user = userContext?.user.value;
            if (user) {
                const versions = await getVersionsSharedWithMe(user);
                dispatch(setVersionsSharedWithMe(versions));
            } else {
                const intervalId = setInterval(async () => {
                    const user = userContext?.user.value;
                    if (user) {
                        clearInterval(intervalId);
                        const versions = await getVersionsSharedWithMe(user);
                        dispatch(setVersionsSharedWithMe(versions));
                    }
                }, 1000); // Retry every second
            }
        };
        const sharedVersionIntervalID = setInterval(() => {
            tryGetVersions();
        }, 1000 * 30);

        return () => {
            clearInterval(sharedVersionIntervalID);
        }
    }, []);

    useEffect(() => {
        if (versionServiceRef.current.loadStatus === "failed") {
            console.log(versionServiceRef.current.loadError);
        } else if (versionServiceRef.current.loadStatus === "loaded") {
            if (!versionInfo) {
                const foundVersion = checkForUrlVersion();
                if (!foundVersion && versionInfos) {
                    handleSelectVersion({
                        versionIdentifier: versionServiceRef.current.defaultVersionIdentifier,
                    } as __esri.VersionInfoJSON);
                }
            }
        }
    }, [versionServiceRef.current.loadStatus]);

    return (
        <>
            <div>
                <Modal
                    isOpen={acceptVersionModalOpen}
                    shouldCloseOnOverlayClick={true}
                    shouldCloseOnEsc={true}
                    onRequestClose={() => setAcceptVersionModalOpen(false)}
                    style={{
                        overlay: {
                            background: "rgba(37, 37, 37, 0.50)",
                        },
                        content: {
                            top: "50%",
                            left: "50%",
                            right: "auto",
                            bottom: "auto",
                            marginRight: "-50%",
                            transform: "translate(-50%, -50%)",
                            padding: 0,
                            background: "none",
                            border: "none",
                        },
                    }}
                >
                    <AcceptVersionAccess versionInfo={versionToAccept} close={() => setAcceptVersionModalOpen(false)} />
                </Modal>
            </div>
            {versionControlOpen && (
                <div className="version-control-container">
                    <div className="version-control-header">
                        <div className="version-control-header-title">
                            <span>Versjoner NFS 2</span>
                            <button className="empty-button" onClick={() => dispatch(setVersionControlOpen(false))}>
                                <CloseIcon />
                            </button>
                        </div>
                        <div className="version-control-header-actions">
                            <NfsButton onClick={openCreateVersion}>
                                <PlusIcon />
                                <span>Opprett ny versjon</span>
                            </NfsButton>
                            <Modal
                                isOpen={createVersionModalOpen}
                                shouldCloseOnOverlayClick={true}
                                shouldCloseOnEsc={true}
                                onRequestClose={() => setCreateVersionModalOpen(false)}
                                style={{
                                    overlay: {
                                        background: "rgba(37, 37, 37, 0.50)",
                                    },
                                    content: {
                                        top: "50%",
                                        left: "50%",
                                        right: "auto",
                                        bottom: "auto",
                                        marginRight: "-50%",
                                        transform: "translate(-50%, -50%)",
                                        padding: 0,
                                        background: "none",
                                        border: "none",
                                    },
                                }}
                            >
                                <CreateNewVersion
                                    onClose={() => setCreateVersionModalOpen(false)}
                                    createNewVersion={createNewVersion}
                                    versionInfos={versionInfos ?? []}
                                />
                            </Modal>
                            <button
                                className="empty-button"
                                onClick={() => {
                                    reloadVersions();
                                }}
                            >
                                <RefreshIcon width={24} height={24} />
                            </button>
                            <NfsDropdown
                                displayClassName="fit-content no-flex no-border"
                                iconReplacement={<SortIcon />}
                                placeholder="Sorter etter"
                                options={["A-Å", "Å-A", "Nyeste", "Eldste"]}
                                selectedOption={versionSort}
                                setSelectedOption={(option) => {
                                    if (option === null) {
                                        setVersionSort("A-Å");
                                    } else {
                                        setVersionSort(option as "A-Å" | "Å-A" | "Nyeste" | "Eldste");
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="version-control-content">
                        {isLoading && (
                            <Loader
                                color="#000667"
                                overlay="element"
                                targetElement=".version-control-content"
                                size={60}
                            />
                        )}
                        <Tabs className="h-100 w-100">
                            <div className="search-container w-100">
                                <div className="search-input-box w-100">
                                    <input
                                        className="search-input"
                                        type="text"
                                        value={versionSearchString}
                                        onChange={handleVersionSearchString}
                                        placeholder="Søk i versjoner"
                                    />
                                    <MagnifyingGlassIcon />
                                </div>
                            </div>
                            <TabItems>
                                <TabItem label={`Opprettet av meg (${filterVersions(versionInfos, true).length})`} />
                                <TabItem label={`Alle (${filterVersions(versionInfos, false).length})`} />
                            </TabItems>
                            <TabPanels>
                                <TabPanel>
                                    <div className="version-control-panel-container">
                                        {versionInfos && versionInfos?.length > 0 && (
                                            <VersionPanel
                                                key={getDefaultVersion()?.versionIdentifier.guid}
                                                versionInfo={getDefaultVersion()!}
                                                reloadVersions={reloadVersions}
                                                selectVersion={handleSelectVersion}
                                                deleteVersion={deleteVersion}
                                                versionServiceRef={versionServiceRef}
                                                versionsSharedWithMe={versionsSharedWithMe ?? []}
                                            />
                                        )}
                                        {filterVersions(versionInfos, true).map((vi) => {
                                            return (
                                                <VersionPanel
                                                    key={vi.versionIdentifier.guid}
                                                    versionInfo={vi}
                                                    reloadVersions={reloadVersions}
                                                    selectVersion={handleSelectVersion}
                                                    deleteVersion={deleteVersion}
                                                    versionServiceRef={versionServiceRef}
                                                    versionsSharedWithMe={versionsSharedWithMe ?? []}
                                                />
                                            );
                                        })}
                                        <div className="no-versions-message">
                                            {versionSearchString === "" && <span>Du har ingen pågående versjoner</span>}
                                            {versionSearchString !== "" && <span>Ingen versjoner funnet</span>}
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="version-control-panel-container">
                                        {versionInfos && versionInfos?.length > 0 && (
                                            <VersionPanel
                                                key={getDefaultVersion()?.versionIdentifier.guid}
                                                versionInfo={getDefaultVersion()!}
                                                reloadVersions={reloadVersions}
                                                selectVersion={handleSelectVersion}
                                                deleteVersion={deleteVersion}
                                                versionServiceRef={versionServiceRef}
                                                versionsSharedWithMe={versionsSharedWithMe ?? []}
                                            />
                                        )}
                                        {filterVersions(versionInfos, false).map((vi) => {
                                            return (
                                                <VersionPanel
                                                    key={vi.versionIdentifier.guid}
                                                    versionInfo={vi}
                                                    reloadVersions={reloadVersions}
                                                    selectVersion={handleSelectVersion}
                                                    deleteVersion={deleteVersion}
                                                    versionServiceRef={versionServiceRef}
                                                    versionsSharedWithMe={versionsSharedWithMe ?? []}
                                                />
                                            );
                                        })}
                                    </div>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </div>
                </div>
            )}
        </>
    );
};

export default VersionControl;

import { ArcgisMapCustomEvent } from "@arcgis/map-components";
import {
    ArcgisCoordinateConversion,
    ArcgisExpand,
    ArcgisLayerList,
    ArcgisLegend,
    ArcgisLocate,
    ArcgisMap,
    ArcgisMeasurement,
    ArcgisPlacement,
    ArcgisScaleBar,
    ArcgisZoom,
} from "@arcgis/map-components-react";

import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import Point from "@arcgis/core/geometry/Point";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol.js";
import _ from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { AppConfig } from "../../AppConfig";
import boatIcon from "../../assets/boat.svg";

import Basemap from "@arcgis/core/Basemap";
import Graphic from "@arcgis/core/Graphic";

import * as intl from "@arcgis/core/intl.js";
import WebMap from "@arcgis/core/WebMap";
import LayerListItem from "@arcgis/core/widgets/LayerList/ListItem.js";
import LayerListItemPanel from "@arcgis/core/widgets/LayerList/ListItemPanel.js";
import Popup from "@arcgis/core/widgets/Popup.js";
import Slider from "@arcgis/core/widgets/Slider.js";
import { AppContext, LighthouseContext, UserContext } from "../../Context";
import { NmeaData } from "../../types/types";
import { uuidv4 } from "../../utils/helpers";
import Connector from "../../utils/signalr";
import { PopupPortal } from "../popups/PopupPortal";
import { SeamarkPopup } from "../popups/SeamarkPopup";

import VectorTileLayer from "@arcgis/core/layers/VectorTileLayer";
import NfsButton from "../genericComponents/NfsButton/NfsButton";

import { useDispatch, useSelector } from "react-redux";
import AddIcon from "../../assets/add.svg?react";
import LayerListIcon from "../../assets/layerlist.svg?react";
import LightbulbIcon from "../../assets/lightCalculation/lightbulb.svg?react";
import RulerIcon from "../../assets/ruler.svg?react";
import {
    setCreateObjectOpen,
    setFeatureViewerOpen,
    setLayerListOpen,
    setLightCalculatorOpen,
    setLoading,
    setMapScale,
    setMeasureToolOpen,
    setViewLoaded,
} from "../../store/appSlice";
import { StoreState } from "../../store/rootReducer";

import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Viewpoint from "@arcgis/core/Viewpoint.js";
import { setCurrentLighthouseTrueNorth } from "../../store/lighthouseSlice";
import { loadLighthousesClickHandler } from "../../utils/lighthouseUtils";
import { getViewPoint, storeViewPoint } from "../../utils/localStorageUtils";
import { canEditVersion } from "../../utils/versioningUtils";
import {
    lighthouseHandlerAddSector,
    lighthouseHandlerClickSector,
    lighthouseHandlerDrag,
    lighthouseHandlerPointerEditingLayer,
    lighthouseHandlerPointerMove,
    lighthouseOverlayHandler,
} from "../MapTools/LightHouse/LightHouseControl";
import Scale from "../Scale/Scale";
import "./MapControl.css";
import Loader from "../genericComponents/Loader/Loader";
import { FeatureLayerNFS } from "../../extensions/FeatureLayerNFS";
import { toast } from "react-toastify";

const popupRoot = document.createElement("div");

const MapControl = () => {
    const userContext = useContext(UserContext);
    const userName = userContext?.user.value?.email;

    const appContext = useContext(AppContext);
    const lighthouseContext = useContext(LighthouseContext);

    const showSectorColorsRef = useRef(lighthouseContext?.showSectorColors.value!);
    const sectorOpacityRef = useRef(lighthouseContext?.sectorOpacity.value!);
    const arcgisMapRef = useRef<HTMLArcgisMapElement | null>(null);
    const esriHandleRef = useRef<IHandle[]>([]);

    const measurementToolOpen = useSelector((state: StoreState) => state.app.measureToolOpen);
    const layerListOpen = useSelector((state: StoreState) => state.app.layerListOpen);
    const createObjectOpen = useSelector((state: StoreState) => state.app.createObjectOpen);
    const activeVersion = useSelector((state: StoreState) => state.version.versionInfo);
    const lightCalculatorOpen = useSelector((state: StoreState) => state.app.lightCalculatorOpen);
    const trueNorth = useSelector((state: StoreState) => state.lighthouse.currentLighthouseTrueNorth);
    const versionsSharedWithMe = useSelector((state: StoreState) => state.version.versionsSharedWithMe);
    const loading = useSelector((state: StoreState) => state.app.loading);
    const isSectorEditorOpen = useSelector((state: StoreState) => state.lighthouse.isSectorEditorOpen);

    const [canIEditVersion, setCanIEditVersion] = useState(false);

    const dispatch = useDispatch();

    const { connection } = Connector(userName ?? uuidv4());

    const currentSectorLine = useRef<Graphic | null>(null);
    const temporarySectorLine = useRef<Graphic | null>(null);
    const isDraggingSectorLine = useRef(false);
    const isDraggingLightHouseCenter = useRef(false);
    const isCenterSelected = useRef(false);
    const trueNorthRef = useRef(0);
    const lighthouseData = lighthouseContext!.lighthouseDataHook.value;

    const dragStartCursor = useRef("default");

    const debounceViewpointSave = _.debounce((viewpoint: Viewpoint) => {
        storeViewPoint(viewpoint);
    }, 200);

    const openLightCalculator = () => {
        if (!lightCalculatorOpen.open) {
            dispatch(setLightCalculatorOpen({ open: true, loadFeatureValues: false }));
        } else {
            dispatch(setLightCalculatorOpen({ open: false, loadFeatureValues: false }));
        }
    };

    useEffect(() => {
        if (activeVersion) {
            const user = userContext?.user.value;
            canEditVersion(activeVersion, versionsSharedWithMe ?? [], user).then((result) => {
                setCanIEditVersion(result);
                if (createObjectOpen && !result) {
                    dispatch(setCreateObjectOpen(false));
                    toast.error("Du har ikke tilgang til å opprette objekter i denne versjonen.", { autoClose: false });
                }
            });
        }
    }, [activeVersion, versionsSharedWithMe]);

    useEffect(() => {
        connection.on("ReceiveMessage", handleReceiveMessage);

        return () => {
            connection.off("ReceiveMessage", handleReceiveMessage);
        };

        function handleReceiveMessage(_sourceClientID: string, messageType: string, message: string) {
            if (messageType === "LocationUpdate") {
                const locationInfo: NmeaData = JSON.parse(message);
                drawBoatInMap(locationInfo);
            }
        }
    }, [connection]);

    // Use hooks to update the ref values when the context values change
    useEffect(() => {
        lighthouseContext!.lighthouseDataHook.value.current = lighthouseContext!.lighthouseData.value;
    }, [lighthouseContext?.lighthouseData.value]);

    useEffect(() => {
        lighthouseContext!.lighthouseSectorPanelsOpenHook.value.current =
            lighthouseContext!.lighthouseSectorPanelsOpen.value;
    }, [lighthouseContext?.lighthouseSectorPanelsOpen.value]);

    useEffect(() => {
        showSectorColorsRef.current = lighthouseContext?.showSectorColors.value!;
    }, [lighthouseContext?.showSectorColors.value]);

    useEffect(() => {
        sectorOpacityRef.current = lighthouseContext?.sectorOpacity.value!;
    }, [lighthouseContext?.sectorOpacity.value]);

    useEffect(() => {
        trueNorthRef.current = trueNorth;
    }, [trueNorth]);

    const handleViewReady = (event: ArcgisMapCustomEvent<void>) => {
        arcgisMapRef.current = event.target;

        const view = event.target.view;

        let viewpoint = getViewPoint();
        if (!viewpoint) {
            viewpoint = new Viewpoint({
                scale: AppConfig.Scale.DefaultScale,
                targetGeometry: new Point({
                    x: -30953.47490385635,
                    y: 6573400.990637887,
                    spatialReference: view.spatialReference,
                }),
            });
            storeViewPoint(viewpoint);
        }

        dispatch(setMapScale(viewpoint.scale));

        view.viewpoint = viewpoint;

        esriHandleRef.current.push(
            reactiveUtils.when(
                () => view.scale,
                (value) => {
                    if (value < AppConfig.Scale.MaxScale) {
                        view.scale = AppConfig.Scale.MaxScale;
                    } else if (value > AppConfig.Scale.MinScale) {
                        view.scale = AppConfig.Scale.MinScale;
                    }
                    dispatch(setMapScale(value));
                }
            )
        );

        esriHandleRef.current.push(
            reactiveUtils.on(
                () => view.popup?.viewModel,
                "trigger-action",
                (event) => {
                    // If the zoom-out action is clicked, fire the zoomOut() function
                    if (event.action.id === "open") {
                        appContext?.selectedFeature.set(view.popup?.selectedFeature ?? null);
                        dispatch(setFeatureViewerOpen(true));
                    }
                }
            )
        );

        /* esriHandleRef.current.push(
            reactiveUtils.watch(
                () => [view.popup?.visible, view.popup?.features],
                ([visible, features]) => {
                    console.log(visible);
                    console.log(features);
                    if (visible && (features as Graphic[]).length === 0) {
                        view.popup?.close();
                    } else if (!visible && (features as Graphic[]).length > 0) {
                        view.popup?.open({
                            features: features as Graphic[],
                        });
                    }
                }
            )
        ); */

        esriHandleRef.current.push(
            view?.watch("viewpoint", (newValue: Viewpoint) => {
                debounceViewpointSave(newValue);
            })
        );

        esriHandleRef.current.push(
            lighthouseHandlerPointerMove(view, currentSectorLine, isDraggingSectorLine, isCenterSelected)
        );
        esriHandleRef.current.push(lighthouseOverlayHandler(view));
        esriHandleRef.current.push(
            lighthouseHandlerDrag({
                view: view,
                currentLine: currentSectorLine,
                temporaryLine: temporarySectorLine,
                dragStartCursor: dragStartCursor,
                lighthouseData: lighthouseData,
                setLighthouseData: lighthouseContext?.lighthouseData.set!,
                isDraggingLine: isDraggingSectorLine,
                isDraggingCenter: isDraggingLightHouseCenter,
                isCenterSelected: isCenterSelected,
                setIsEditing: lighthouseContext?.isEditing.set,
                trueNorth: trueNorthRef,
                setTrueNorth: (angle) => dispatch(setCurrentLighthouseTrueNorth(angle)),
            })
        );
        esriHandleRef.current.push(lighthouseHandlerPointerEditingLayer(view, isCenterSelected, isDraggingSectorLine));

        esriHandleRef.current.push(
            lighthouseHandlerAddSector(view, lighthouseData, lighthouseContext?.lighthouseData.set)
        );

        esriHandleRef.current.push(
            lighthouseHandlerClickSector(view, lighthouseContext?.lighthouseSectorPanelsOpen.set)
        );

        esriHandleRef.current.push(
            loadLighthousesClickHandler(
                view,
                appContext?.selectedFeature.set,
                lighthouseContext?.lighthouseData.set,
                (loadingStatus: boolean) => dispatch(setLoading(loadingStatus))
            )
        );

        dispatch(setViewLoaded());
    };

    const [webMap, setWebMap] = useState<__esri.WebMap>();

    const drawBoatInMap = (locationInfo: NmeaData) => {
        if (arcgisMapRef.current && locationInfo.Latitude && locationInfo.Longitude) {
            const view = arcgisMapRef.current.view;
            let boatCoordinatesLayer = view.map.findLayerById("boatCoordinates") as GraphicsLayer;
            if (!boatCoordinatesLayer) {
                return;
            }
            boatCoordinatesLayer.removeAll();
            const point = new Point({
                longitude: locationInfo.Longitude,
                latitude: locationInfo.Latitude,
                spatialReference: { wkid: 4326 },
            });
            const boatSymbol = new PictureMarkerSymbol({
                url: boatIcon,
                width: "32px",
                height: "32px",
                angle: locationInfo.Heading ?? 0,
            });
            const dateString = new Date().toLocaleString();
            const pointGraphic = new Graphic({
                geometry: point,
                symbol: boatSymbol,
                popupTemplate: {
                    title: "Båt",
                    content: `Sist oppdatert ${dateString} <br> Fart: ${locationInfo.Speed ?? "ikke oppgitt"
                        } knop <br> Kurs: ${locationInfo.Heading ?? "ikke oppgitt"} grader <br> Horisontal presisjon: ${locationInfo.HorizontalPrecision ?? "ikke oppgitt"
                        } meter <br> Høyde: ${locationInfo.Altitude ?? "ikke oppgitt"} meter <br> Antall satellitter: ${locationInfo.NumberOfSatellites ?? "ikke oppgitt"
                        }`,
                },
            });
            boatCoordinatesLayer.add(pointGraphic);
        }
    };

    const layerListRenderFunction = async function (event: any) {
        const item = event.item as LayerListItem;
        if (item.layer) {
            // Adds a slider for updating a group layer's opacity
            let slider: any = null;
            const baseOpacity = item.layer.opacity || 1;
            if (item.layer.hasOwnProperty("opacity")) {
                slider = new Slider({
                    label: "Gjennomsiktighet",
                    visible: true,
                    min: 0,
                    max: 1,
                    precision: 2,
                    values: [baseOpacity],
                    visibleElements: {
                        labels: true,
                        rangeLabels: true,
                    },
                });

                slider.on("thumb-drag", (event: any) => {
                    const { value } = event;
                    item.layer.opacity = value;
                });

                item.panel = {
                    content: slider,
                    icon: "transparency",
                    open: false,
                    visible: true,
                } as LayerListItemPanel;
            }
        }
    };

    const toggleMeasureTool = () => {
        dispatch(setMeasureToolOpen(!measurementToolOpen));
    };

    const toggleLayerList = () => {
        dispatch(setLayerListOpen(!layerListOpen));
    };

    const toggleCreateObject = () => {
        dispatch(setCreateObjectOpen(!createObjectOpen));
    };

    useEffect(() => {
        if (layerListOpen) {
            document.getElementsByClassName("esri-layer-list")[0]?.classList.add("layer-list-visible");
        } else {
            document.getElementsByClassName("esri-layer-list")[0]?.classList.remove("layer-list-visible");
        }
    }, [layerListOpen]);

    useEffect(() => {
        intl.setLocale("nb");

        const vectorMap = new Basemap({
            baseLayers: [
                new VectorTileLayer({
                    url: "https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheBasis_WGS84/VectorTileServer",
                }),
            ],
        });

        const map = new WebMap({
            basemap: vectorMap,
        });

        for (const configLayer of AppConfig.Layers) {
            let layer: any = null;
            if (configLayer.Type === "FeatureLayer") {
                layer = new FeatureLayer({
                    url: configLayer.Url,
                    id: configLayer.Name,
                    title: configLayer.Name,
                    visible: configLayer.DefaultVisible,
                    popupEnabled: configLayer.HasPopup,
                    outFields: ["*"],
                });
            } else if (configLayer.Type === "MapImageLayerIgnore") {
                layer = new MapImageLayer({
                    url: configLayer.Url,
                    id: configLayer.Name,
                    title: configLayer.Name,
                });

                if (layer) {
                    layer.when(() => {
                        layer.allSublayers.forEach((sublayer: any) => {
                            sublayer.load().then(() => {
                                sublayer.popupEnabled = true;
                                sublayer.popupTemplate = {
                                    outFields: ["*"],
                                };
                            });
                        });
                    });
                }
            }
            if (layer) {
                (layer as FeatureLayerNFS).isVersioned = configLayer.IsVersioned;
                if (configLayer.IsTable) {
                    map.tables.add(layer);
                } else {
                    map.add(layer, configLayer.Order);
                }
            }
        }
        let peilelinjalLayer = map.findLayerById("peilelinjal") as GraphicsLayer;
        if (!peilelinjalLayer) {
            peilelinjalLayer = new GraphicsLayer({ id: "peilelinjal", listMode: "hide" });
            map.add(peilelinjalLayer);
        }

        let boatCoordinatesLayer = map.findLayerById("boatCoordinates") as GraphicsLayer;
        if (!boatCoordinatesLayer) {
            boatCoordinatesLayer = new GraphicsLayer({ id: "boatCoordinates", listMode: "hide" });
            map.add(boatCoordinatesLayer);
        }

        const lighthouseLayer = new GraphicsLayer({ id: "Fyrlykt", title: "Fyrlykt", listMode: "hide" });
        const lighthouseEditingLayer = new GraphicsLayer({
            id: "FyrlyktEditing",
            title: "FyrlyktEditing",
            listMode: "hide",
        });

        map.addMany([lighthouseLayer, lighthouseEditingLayer]);

        setWebMap(map);

        return () => {
            map?.destroy();
            esriHandleRef.current.forEach((handle) => handle.remove());
            esriHandleRef.current = [];
        };
    }, []);

    return (
        <>
            {webMap && (
                <ArcgisMap
                    id="map"
                    onArcgisViewReadyChange={handleViewReady}
                    constraints={{ snapToZoom: false, rotationEnabled: false }}
                    map={webMap}
                    popup={
                        new Popup({
                            defaultPopupTemplateEnabled: true,
                            actions: [{ id: "open", title: "Åpne", type: "button" }],
                            dockEnabled: false,
                            dockOptions: {
                                buttonEnabled: false,
                            },
                        })
                    }
                >
                    <ArcgisPlacement position="top-right">
                        <div className="flex-col small-gap no-esri-shadow">
                            <NfsButton round inverted shadow active={measurementToolOpen} onClick={toggleMeasureTool}>
                                <RulerIcon />
                            </NfsButton>
                            <NfsButton round inverted shadow active={layerListOpen} onClick={toggleLayerList}>
                                <LayerListIcon />
                            </NfsButton>
                            <NfsButton
                                round
                                inverted
                                shadow
                                active={lightCalculatorOpen?.open}
                                onClick={openLightCalculator}
                            >
                                <LightbulbIcon />
                            </NfsButton>
                            {canIEditVersion && !isSectorEditorOpen && (
                                <NfsButton round inverted shadow active={createObjectOpen} onClick={toggleCreateObject}>
                                    <AddIcon />
                                </NfsButton>
                            )}
                        </div>
                    </ArcgisPlacement>
                    <ArcgisLayerList
                        position="top-left"
                        dragEnabled
                        listItemCreatedFunction={layerListRenderFunction}
                        visibilityAppearance="checkbox"
                    ></ArcgisLayerList>
                    <ArcgisZoom position="bottom-right"></ArcgisZoom>
                    <ArcgisLocate position="bottom-right"></ArcgisLocate>
                    <ArcgisMeasurement position="bottom-right"></ArcgisMeasurement>
                    <ArcgisPlacement position="bottom-left">
                        <Scale></Scale>
                    </ArcgisPlacement>
                    <ArcgisScaleBar position="bottom-right"></ArcgisScaleBar>
                    <ArcgisExpand position="bottom-left">
                        <ArcgisLegend></ArcgisLegend>
                    </ArcgisExpand>
                    <ArcgisExpand position="bottom-left">
                        <ArcgisCoordinateConversion position="bottom-left"></ArcgisCoordinateConversion>
                    </ArcgisExpand>
                </ArcgisMap>
            )}
            {/* Leaving for now as a testing tool */}
            {/* {versionControlOpen && (
                <RenderInWindow onClose={() => dispatch(setVersionControlOpen(false))}>
                    <VersionManagement />
                </RenderInWindow>
            )} */}
            <PopupPortal mount={popupRoot}>
                <SeamarkPopup mapView={arcgisMapRef.current?.view!} />
            </PopupPortal>
            {loading && <Loader overlay={"fullscreen"} />}
        </>
    );
};

export default MapControl;

import * as symbolUtils from "@arcgis/core/symbols/support/symbolUtils.js";
import { useContext, useEffect, useRef, useState } from "react";
import LeftArrowIcon from "../../assets/arrow-left.svg?react";
import CloseIcon from "../../assets/close.svg?react";
import LightBulbIcon from "../../assets/lightCalculation/lightBulb_outline.svg?react";
import SectorLinesIcon from "../../assets/sectorLines.svg?react";

import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import { useDispatch, useSelector } from "react-redux";
import { AppConfig, LayersConfig } from "../../AppConfig";
import PaperPlaneIcon from "../../assets/paper-plane.svg?react";
import { AppContext, LighthouseContext, UserContext, VersionContext } from "../../Context";
import { setFeatureViewerIsEditing, setFeatureViewerOpen, setLightCalculatorOpen } from "../../store/appSlice";
import {
    setCurrentLighthouseTrueNorth,
    setIsSectorEditorOpen,
    setIsSectorTableOpen,
} from "../../store/lighthouseSlice";
import { StoreState } from "../../store/rootReducer";
import {
    computeTrueNorth,
    findAndSelectFeature,
    getMapView,
    highlightFeature,
    moveToLayer,
    updateFeatureAttributes,
} from "../../utils/arcgisUtils";
import { canEditVersion, isDefaultVersion } from "../../utils/versioningUtils";
import NfsButton from "../genericComponents/NfsButton/NfsButton";
import { featureHasLight } from "../LightCalculator/lightCalculationUtils";
import { drawSectors } from "../MapTools/LightHouse/lighthouseDrawing";
import "./FeatureViewer.css";
import FeatureViewerContent from "./FeatureViewerContent";
import { toast } from "react-toastify";

const FeatureViewer = () => {
    const dispatch = useDispatch();

    const versionContext = useContext(VersionContext);

    const [panelClass, setPanelClass] = useState<string>("collapsed");
    const [showAllAttributes, setShowAllAttributes] = useState<boolean>(false);
    const [symbolHtmlElement, setSymbolHtmlElement] = useState<HTMLElement | null>(null);
    const [canIEditVersion, setCanIEditVersion] = useState<boolean>(false);

    const isEditing = useSelector((state: StoreState) => state.app.featureViewerIsEditing);
    const featureViewerOpen = useSelector((state: StoreState) => state.app.featureViewerOpen);
    const activeVersion = useSelector((state: StoreState) => state.version.versionInfo);
    const versionsSharedWithMe = useSelector((state: StoreState) => state.version.versionsSharedWithMe);
    const mapScale = useSelector((state: StoreState) => state.app.mapScale);
    const lighthouseTableSelectedSector = useSelector(
        (state: StoreState) => state.lighthouse.lighthouseTableSelectedSector
    );
    const trueNorth = useSelector((state: StoreState) => state.lighthouse.currentLighthouseTrueNorth);
    const isSavingLighthouse = useSelector((state: StoreState) => state.lighthouse.isSavingLighthouse);
    const versionInfo = useSelector((state: StoreState) => state.version.versionInfo);

    const layerConfig = useRef<LayersConfig>();
    const highlightRef = useRef<IHandle>();

    // Updating the selected feature will trigger a re-render
    // Therefore, we need to keep track of the last selected feature
    // to avoid exiting edit mode when the feature is updated
    const lastSelectedFeatureRef = useRef<__esri.Graphic | null | undefined>(null);

    const appContext = useContext(AppContext);
    const userContext = useContext(UserContext);
    const lighthouseContext = useContext(LighthouseContext);
    const selectedFeature = appContext?.selectedFeature.value;
    const setIsDrawing = lighthouseContext?.isDrawing.set;

    // Due to css behavior with animations to display none and vice versa
    // an additional class is needed to allow panel to slide out
    const toggleOpen = () => {
        if (panelClass === "") {
            closePanel();
        } else if (panelClass === "collapsed") {
            openPanel();
        }
    };

    const openPanel = () => {
        if (panelClass === "") {
            return;
        }
        setPanelClass("opening");
        setTimeout(() => {
            setPanelClass("");
        }, 100);
    };

    const closePanel = () => {
        setPanelClass("collapsed");
        dispatch(setFeatureViewerOpen(false));
    };

    const unselectFeature = () => {
        closePanel();
        appContext?.selectedFeature.set(null);
        lastSelectedFeatureRef.current = null;

        if (!isSavingLighthouse) {
            const mapview = getMapView();
            if (!mapview) return;

            const sectorLayer = mapview.map.findLayerById("Lyssektor") as FeatureLayer;
            const lineLayer = mapview.map.findLayerById("Sektorlinje") as FeatureLayer;
            const lightLayer = mapview.map.findLayerById("Lys") as FeatureLayer;
            const lighthouseLayer = mapview.map.findLayerById("Fyrlykt") as GraphicsLayer;

            sectorLayer.definitionExpression = "";
            lineLayer.definitionExpression = "";
            lightLayer.definitionExpression = "";
            lighthouseLayer.removeAll();
        }
    };

    const editFeatureAttribute = (field: string, value: any) => {
        if (!selectedFeature) {
            return;
        }
        const clonedFeature = selectedFeature.clone();
        clonedFeature.attributes[field] = value;

        appContext?.selectedFeature.set(clonedFeature);
    };

    const saveFeature = () => {
        if (!selectedFeature) {
            dispatch(setFeatureViewerIsEditing(false));
            return;
        }

        if (selectedFeature.layer.type === "graphics") {
            const mapview = getMapView();
            if (!mapview) return;
            dispatch(setFeatureViewerIsEditing(false));
            closePanel();
            return;
        }
        updateFeatureAttributes(selectedFeature).then((success: boolean) => {
            if (success) {
                dispatch(setFeatureViewerIsEditing(false));
            } else {
                console.log("Error saving feature");
            }
        });
    };

    const toggleEdit = (edit: boolean) => {
        dispatch(setFeatureViewerIsEditing(edit));
    };

    const openInFDV = (GISOBJID: number) => {
        const gisobjidString = GISOBJID.toString().padStart(5, "0");
        window.open(`${AppConfig.FDV.BaseUrl}${AppConfig.FDV.ObjectPropertiesBase}${gisobjidString}`);
    };

    const openLightCalculation = () => {
        if (selectedFeature) {
            dispatch(setLightCalculatorOpen({ open: true, loadFeatureValues: true }));
            toggleOpen();
        }
    };

    const openSectorEditing = () => {
        if (selectedFeature) {
            dispatch(setIsSectorEditorOpen(true));
            toggleOpen();
        }
    };

    const drawNewLighthouse = () => {
        const mapview = getMapView();
        if (!mapview) return;

        const newFeature = appContext?.selectedFeature.value;
        if (!newFeature) return;

        const lighthouseLayer = mapview.map.findLayerById("Fyrlykt") as GraphicsLayer;
        if (!lighthouseLayer) return;

        computeTrueNorth(selectedFeature?.attributes.lighthouseData.center).then((angle) => {
            dispatch(setCurrentLighthouseTrueNorth(angle));
            drawSectors({
                graphicsLayer: lighthouseLayer,
                data: newFeature.attributes.lighthouseData,
                setIsEditing: lighthouseContext?.isEditing.set,
                sectorsToFill: [],
                opacity: lighthouseContext?.sectorOpacity.value,
                drawNumbers: false,
                trueNorthCorrection: angle,
            });
        });
    };

    useEffect(() => {
        if (activeVersion) {
            const user = userContext?.user.value;
            canEditVersion(activeVersion, versionsSharedWithMe ?? [], user).then((result) => {
                setCanIEditVersion(result);
            });
        }
    }, [activeVersion, versionsSharedWithMe]);

    useEffect(() => {
        if (!canIEditVersion && isEditing) {
            toggleEdit(false);
            toast.error("Du har ikke tilgang til å redigere denne versjonen.", { autoClose: false });
        }
    }, [canIEditVersion]);

    useEffect(() => {
        if (featureViewerOpen) {
            openPanel();
        } else {
            closePanel();
        }
    }, [featureViewerOpen]);

    useEffect(() => {
        if (selectedFeature?.attributes["globalid"] !== lastSelectedFeatureRef.current?.attributes["globalid"]) {
            dispatch(setFeatureViewerIsEditing(false));
        }
        lastSelectedFeatureRef.current = selectedFeature;
        if (selectedFeature) {
            if (selectedFeature.attributes.lighthouseData) {
                dispatch(setFeatureViewerIsEditing(true));
                lighthouseContext?.lighthouseSectorPanelsOpen.set(new Set());
                drawNewLighthouse();
            } else {
                moveToLayer("all", "FyrlyktEditing", "Fyrlykt");
            }
            highlightFeature(selectedFeature).then((handle) => {
                if (handle) {
                    highlightRef.current = handle;
                }
            });
            openPanel();
            layerConfig.current = AppConfig.Layers.find((layerConfig) => layerConfig.Name === selectedFeature.layer.id);

            symbolUtils.getDisplayedSymbol(selectedFeature).then((symbol) => {
                if (symbol) {
                    symbolUtils.renderPreviewHTML(symbol, { size: 12 }).then((symbolElement) => {
                        setSymbolHtmlElement(symbolElement);
                    });
                } else {
                    setSymbolHtmlElement(null);
                }
            });
        } else {
            const mapview = getMapView();
            if (!mapview) return;

            setShowAllAttributes(false);
            dispatch(setFeatureViewerIsEditing(false));
            closePanel();
        }

        dispatch(setIsSectorEditorOpen(false));
        dispatch(setIsSectorTableOpen(false));
        const mapview = getMapView();
        if (!mapview) return;

        const sectorLayer = mapview.map.findLayerById("Lyssektor") as FeatureLayer;
        const lineLayer = mapview.map.findLayerById("Sektorlinje") as FeatureLayer;
        const lightLayer = mapview.map.findLayerById("Lys") as FeatureLayer;
        const lighthouseLayer = mapview.map.findLayerById("Fyrlykt") as GraphicsLayer;
        const lighthouseEditingLayer = mapview.map.findLayerById("FyrlyktEditing") as GraphicsLayer;

        sectorLayer.definitionExpression = "";
        lineLayer.definitionExpression = "";
        lightLayer.definitionExpression = "";
        lighthouseEditingLayer.load().then(() => {
            lighthouseEditingLayer.removeAll();
        });
        lighthouseLayer.load().then(() => {
            lighthouseLayer.removeAll();
        });

        return () => {
            if (highlightRef.current) {
                highlightRef.current.remove();
            }
        };
    }, [selectedFeature]);

    useEffect(() => {
        computeTrueNorth(selectedFeature?.attributes?.lighthouseData?.center).then((angle) => {
            dispatch(setCurrentLighthouseTrueNorth(angle));
        });

        return () => {
            if (highlightRef.current) {
                highlightRef.current.remove();
            }
        };
    }, []);

    useEffect(() => {
        if (!AppConfig.Lighthouse.AdaptiveSectorThickness || !selectedFeature?.attributes.lighthouseData) return;

        // Redraw all lighthouses when map scale changes
        const mapview = getMapView();
        if (!mapview) return;

        const lighthouseLayer = mapview.map.findLayerById("Fyrlykt") as GraphicsLayer;

        const lighthouse = lighthouseContext?.lighthouseData.value;

        if (!lighthouse) return;

        // lighthouseData?.forEach((lighthouse) => {
        // See if there are any graphics objects with the same id as the lighthouse
        const lighthouseGraphic = lighthouseLayer.graphics.find((graphic) => graphic.attributes?.id === lighthouse.id);

        if (!lighthouseGraphic) return;

        const showAllSectors = lighthouseContext?.showSectorColors.value
            ? Array(lighthouseGraphic.attributes.lighthouseData.sectors.length)
                .fill(0)
                .map((_, i) => i)
            : [];

        drawSectors({
            graphicsLayer: lighthouseLayer,
            data: lighthouse,
            setIsEditing: setIsDrawing,
            sectorsToFill: lighthouseTableSelectedSector !== null ? [lighthouseTableSelectedSector] : showAllSectors,
            opacity: lighthouseContext?.sectorOpacity.value,
            drawNumbers: false,
            trueNorthCorrection: trueNorth,
        });
        // });
    }, [mapScale]);

    if (!selectedFeature) {
        return null;
    }

    return (
        <div className={`feature-viewer-container`}>
            <div className={`feature-viewer ${panelClass}`}>
                <div className="feature-viewer-header">
                    <span className="flex-row small-gap w-100">
                        <NfsButton
                            onClick={() => {
                                setShowAllAttributes(!showAllAttributes);
                            }}
                        >
                            {showAllAttributes ? "Vis færre" : "Vis mer"}
                        </NfsButton>
                        {canIEditVersion && (
                            <NfsButton
                                onClick={() => {
                                    findAndSelectFeature((selectedFeature.layer as FeatureLayer), selectedFeature, (graphic) => {
                                        appContext.selectedFeature.set(graphic);
                                    })
                                    toggleEdit(!isEditing);
                                }}
                            >
                                {isEditing ? "Ferdig" : "Rediger"}
                            </NfsButton>
                        )}
                    </span>
                    <button type="button" className="empty-button" onClick={unselectFeature}>
                        <CloseIcon />
                    </button>
                </div>
                <FeatureViewerContent
                    editFeatureAttribute={editFeatureAttribute}
                    isEditing={isEditing}
                    showAllAttributes={showAllAttributes}
                    symbolHtmlElement={symbolHtmlElement}
                    feature={selectedFeature}
                />
                <div className="feature-viewer-tools">
                    {featureHasLight(selectedFeature) && (
                        <button className="feature-viewer-tool" onClick={openLightCalculation}>
                            <LightBulbIcon />
                            <span>Beregn lys</span>
                        </button>
                    )}
                    {!isDefaultVersion(versionInfo ?? null, versionContext?.versionServiceRef.value.current!) &&
                        selectedFeature.attributes.klasse === 3 &&
                        selectedFeature.attributes.lys_type === 99 && (
                            <button className="feature-viewer-tool" onClick={openSectorEditing}>
                                <SectorLinesIcon />
                                <span>Redigér sektorer</span>
                            </button>
                        )}
                </div>
                <div className="feature-viewer-footer">
                    {isEditing && (
                        <>
                            <NfsButton onClick={saveFeature} disabled>
                                <PaperPlaneIcon />
                                Sett til prosjektert
                            </NfsButton>
                            <NfsButton onClick={unselectFeature} outlined>
                                <CloseIcon />
                                Lukk editering
                            </NfsButton>
                        </>
                    )}
                    {!isEditing &&
                        selectedFeature?.attributes["gis_obj_id"] &&
                        selectedFeature?.attributes["gis_obj_id"] > 0 && (
                            <NfsButton
                                onClick={() => {
                                    openInFDV(selectedFeature.attributes["gis_obj_id"]);
                                }}
                            >
                                Åpne i FDV
                            </NfsButton>
                        )}
                </div>
            </div>
            <button type="button" className={`feature-viewer-collapse-button ${panelClass}`} onClick={toggleOpen}>
                <LeftArrowIcon />
            </button>
        </div>
    );
};

export default FeatureViewer;

import { createSlice } from "@reduxjs/toolkit";

interface TriggersState {
    triggerAlertsRefresh: number;
}

const initialState: TriggersState = {
    triggerAlertsRefresh: new Date().getTime(),
};

const triggersSlice = createSlice({
    name: "scale",
    initialState,
    reducers: {
        triggerAlertsRefresh(state) {
            state.triggerAlertsRefresh = new Date().getTime();
        },
    },
});

export default triggersSlice.reducer;
export const { triggerAlertsRefresh } = triggersSlice.actions;
export type { TriggersState };

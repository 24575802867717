import Basemap from "@arcgis/core/Basemap";
import Point from "@arcgis/core/geometry/Point";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import MapView from "@arcgis/core/views/MapView";
import WebMap from "@arcgis/core/WebMap";
import { ArcgisMapCustomEvent } from "@arcgis/map-components";
import { ArcgisMap } from "@arcgis/map-components-react";
import { useContext, useEffect, useState } from "react";
import { AppContext, LighthouseContext } from "../../../Context";
import { getMapView } from "../../../utils/arcgisUtils";
import { deepCopy } from "../../../utils/helpers";
import { DefaultSectorValues, LighthouseData } from "../../../utils/lighthouseUtils";
import { drawSectors } from "./lighthouseDrawing";
import "./SectorRose.css";
import VectorTileLayer from "@arcgis/core/layers/VectorTileLayer";
import { useSelector } from "react-redux";
import { AppConfig } from "../../../AppConfig";
import { StoreState } from "../../../store/rootReducer";

const SectorRoseMap = () => {
    const [map, setMap] = useState<WebMap>();
    const [scale, setScale] = useState<number>(0);
    const [view, setView] = useState<MapView>();
    const [lighthouseData, setLighthouseData] = useState<LighthouseData>();

    const appContext = useContext(AppContext);
    const lighthouseContext = useContext(LighthouseContext);

    const trueNorth = useSelector((state: StoreState) => state.lighthouse.currentLighthouseTrueNorth);

    useEffect(() => {
        const mapView = getMapView();
        if (!mapView) return;

        const vectorMap = new Basemap({
            spatialReference: mapView.map.basemap.spatialReference,
            baseLayers: [
                new VectorTileLayer({
                    url: "https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheBasis_WGS84/VectorTileServer",
                    opacity: 0,
                }),
            ],
        });

        const sectorRoseLayer = new GraphicsLayer({ id: "SectorRose", title: "Sektorrose", visible: true });

        const embeddedMap = new WebMap({ basemap: vectorMap });
        embeddedMap.add(sectorRoseLayer);
        setMap(embeddedMap);

        adaptLighthouseData(sectorRoseLayer);

        return () => {
            embeddedMap.remove(sectorRoseLayer);
            sectorRoseLayer.destroy();
            embeddedMap.destroy();
            setMap(undefined);
        };
    }, []);

    useEffect(() => {
        const mapViewEmbedded = getMapView("sector-rose-map");
        if (!mapViewEmbedded) return;

        const sectorRoseLayer = mapViewEmbedded.map.findLayerById("SectorRose") as GraphicsLayer;

        adaptLighthouseData(sectorRoseLayer);
    }, [lighthouseContext?.lighthouseData.value, view]);

    const adaptLighthouseData = (sectorRoseLayer: GraphicsLayer) => {
        sectorRoseLayer.load().then(() => {
            const modifiedLighthouseData = deepCopy(
                appContext?.selectedFeature.value?.attributes.lighthouseData
            ) as LighthouseData;
            modifiedLighthouseData.id = "SectorRose";
            modifiedLighthouseData.center = new Point({ x: 0, y: 0, spatialReference: view?.spatialReference });
            modifiedLighthouseData.sectors = modifiedLighthouseData.sectors.map((sector) => {
                const newSector = { ...sector };
                newSector.startLineLength = DefaultSectorValues.defaultInnerRadius;
                newSector.endLineLength = DefaultSectorValues.defaultInnerRadius;
                newSector.innerRadius = DefaultSectorValues.defaultInnerRadius;
                return newSector;
            });

            sectorRoseLayer.removeAll();
            drawSectors({
                graphicsLayer: sectorRoseLayer,
                data: modifiedLighthouseData,
                setIsEditing: lighthouseContext?.isEditing.set,
                sectorsToFill: [],
                opacity: 1,
                drawNumbers: true,
                trueNorthCorrection: trueNorth,
                overrideSectorWidth: true,
            });
            setLighthouseData(modifiedLighthouseData);
        });
    };

    const handleViewReady = (event: ArcgisMapCustomEvent<void>) => {
        if (!lighthouseData) return;

        const eventView = event.target.view;
        setView(eventView);

        // Disable all zooming and panning
        eventView.on("key-down", (event) => {
            const prohibitedKeys = ["+", "-", "Shift", "_", "="];
            const keyPressed = event.key;
            if (prohibitedKeys.indexOf(keyPressed) !== -1) {
                event.stopPropagation();
            }
        });

        eventView.on("mouse-wheel", (event) => {
            event.stopPropagation();
        });

        eventView.on("double-click", (event) => {
            event.stopPropagation();
        });

        eventView.on("double-click", ["Control"], (event) => {
            event.stopPropagation();
        });

        eventView.on("drag", (event) => {
            event.stopPropagation();
        });

        eventView.on("drag", ["Shift"], (event) => {
            event.stopPropagation();
        });

        eventView.on("drag", ["Shift", "Control"], (event) => {
            event.stopPropagation();
        });

        const center = new Point({ x: 0, y: 0, spatialReference: eventView.spatialReference });
        const maxExtentLength = DefaultSectorValues.defaultInnerRadius + AppConfig.Lighthouse.DefaultSectorThickness;

        eventView.goTo(center);
        const extent = eventView.extent.clone();

        extent.centerAt(center);
        extent.xmin = center.x - maxExtentLength;
        extent.xmax = center.x + maxExtentLength;
        extent.ymin = center.y - maxExtentLength;
        extent.ymax = center.y + maxExtentLength;

        eventView.extent = extent;

        eventView.ui.components = [];

        setScale(eventView.scale);
    };

    return (
        map && (
            <ArcgisMap
                id="sector-rose-map"
                map={map}
                onArcgisViewReadyChange={handleViewReady}
                constraints={{
                    rotationEnabled: false,
                    minScale: scale,
                    maxScale: scale,
                    snapToZoom: false,
                }}
            ></ArcgisMap>
        )
    );
};

export default SectorRoseMap;

import Point from "@arcgis/core/geometry/Point";
import Polygon from "@arcgis/core/geometry/Polygon";
import Polyline from "@arcgis/core/geometry/Polyline";
import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import * as symbolUtils from "@arcgis/core/symbols/support/symbolUtils.js";
import TextSymbol from "@arcgis/core/symbols/TextSymbol";
import { AppConfig } from "../../../AppConfig";
import { getMapView } from "../../../utils/arcgisUtils";
import { clamp } from "../../../utils/helpers";
import { DefaultSectorValues, LighthouseData, SectorColor, SectorFallbackColor } from "../../../utils/lighthouseUtils";

interface DrawSectorsParams {
    graphicsLayer: GraphicsLayer;
    data: LighthouseData;
    setIsEditing: ((value: boolean) => void) | undefined;
    sectorsToFill?: number[];
    opacity?: number;
    drawNumbers?: boolean;
    trueNorthCorrection?: number;
    overrideSectorWidth?: boolean;
}

export const drawSectors = ({
    graphicsLayer,
    data,
    setIsEditing,
    sectorsToFill,
    opacity,
    drawNumbers,
    trueNorthCorrection = 0,
    overrideSectorWidth = false,
}: DrawSectorsParams) => {
    const mapview = getMapView();
    if (!mapview) return;

    graphicsLayer.removeAll();

    setIsEditing?.(true);

    // Fetch the sector line symbology
    const sectorLineLayer = mapview.map.findLayerById("Sektorlinje");
    const dummyLineGraphic = new Graphic({ layer: sectorLineLayer });
    const sectorLineSymbol = symbolUtils.getDisplayedSymbol(dummyLineGraphic) as Promise<SimpleLineSymbol>;
    const fallbackLineSymbol = new SimpleLineSymbol({
        color: [0, 0, 0, 1],
        width: 0.2,
        join: "round",
        style: "solid",
        miterLimit: 2,
    });

    // Fetch the sector fill symbology
    const sectorColorSymbology = mapview.map.findLayerById("Lyssektor");
    const dummySectorRed = new Graphic({
        layer: sectorColorSymbology,
        attributes: { lys_farge: parseInt(SectorColor.RED) },
    });
    const dummySectorGreen = new Graphic({
        layer: sectorColorSymbology,
        attributes: { lys_farge: parseInt(SectorColor.GREEN) },
    });
    const dummySectorYellow = new Graphic({
        layer: sectorColorSymbology,
        attributes: { lys_farge: parseInt(SectorColor.WHITE) },
    });
    const sectorRedSymbol = symbolUtils.getDisplayedSymbol(dummySectorRed);
    const sectorGreenSymbol = symbolUtils.getDisplayedSymbol(dummySectorGreen);
    const sectorYellowSymbol = symbolUtils.getDisplayedSymbol(dummySectorYellow);

    const sectorOutline = {
        color: [0, 0, 0, 0],
        width: 1,
    };

    const sectorDarkSymbol = new SimpleFillSymbol({
        color: SectorFallbackColor.DARK,
        outline: sectorOutline,
    });
    const fallbackRedSymbol = new SimpleFillSymbol({
        color: SectorFallbackColor.RED,
        outline: sectorOutline,
    });
    const fallbackGreenSymbol = new SimpleFillSymbol({
        color: SectorFallbackColor.GREEN,
        outline: sectorOutline,
    });
    const fallbackYellowSymbol = new SimpleFillSymbol({
        color: SectorFallbackColor.WHITE,
        outline: sectorOutline,
    });

    let previousEndAngle = 0;
    const firstAngle = data.sectors[0].startAngle ?? 0;

    let displayIndex = -1;

    data.sectors.forEach((sector, index) => {
        if (sector.color !== SectorColor.DARK) displayIndex++;

        const startAngle = sector.startAngle ?? previousEndAngle;
        const endAngle =
            index < data.sectors.length - 1 ? data.sectors[index + 1].startAngle ?? 360 + firstAngle : 360 + firstAngle;
        const innerRadius = sector.innerRadius ?? DefaultSectorValues.defaultInnerRadius;
        const sectorThickness = AppConfig.Lighthouse.AdaptiveSectorThickness
            ? clamp(
                  mapview.viewpoint.scale / 50,
                  AppConfig.Lighthouse.MinSectorThickness ?? AppConfig.Lighthouse.DefaultSectorThickness,
                  AppConfig.Lighthouse.MaxSectorThickness ?? innerRadius
              )
            : AppConfig.Lighthouse.DefaultSectorThickness;
        const outerRadius =
            innerRadius + (overrideSectorWidth ? AppConfig.Lighthouse.DefaultSectorThickness : sectorThickness); // in meters

        let sectorSymbol;
        let sectorFallbackSymbol;

        switch (sector.color) {
            case SectorColor.RED:
                sectorSymbol = sectorRedSymbol;
                sectorFallbackSymbol = fallbackRedSymbol;
                break;
            case SectorColor.GREEN:
                sectorSymbol = sectorGreenSymbol;
                sectorFallbackSymbol = fallbackGreenSymbol;
                break;
            case SectorColor.WHITE:
                sectorSymbol = sectorYellowSymbol;
                sectorFallbackSymbol = fallbackYellowSymbol;
                break;
            case SectorColor.DARK:
            default:
                sectorSymbol = Promise.resolve(sectorDarkSymbol);
                sectorFallbackSymbol = sectorDarkSymbol;
                break;
        }

        const lineEndIndex = index < data.sectors.length - 1 ? index + 1 : 0;
        const startLineLength = sector.startLineLength ?? DefaultSectorValues.defaultLineLength;
        const endLineLength =
            sector.endLineLength ?? data.sectors[lineEndIndex].startLineLength ?? DefaultSectorValues.defaultLineLength;

        if (sector.color) {
            const sectorGraphic = drawSingleSector({
                center: data.center,
                innerRadius: innerRadius,
                outerRadius: outerRadius,
                startAngle: startAngle,
                endAngle: endAngle,
                startLineLength: startLineLength,
                endLineLength: endLineLength,
                symbol: sectorSymbol,
                fallbackSymbol: sectorFallbackSymbol,
                index: index,
                lighthouseId: data.id,
                globalid: sector.globalid,
                trueNorthCorrection: trueNorthCorrection,
                fill: sectorsToFill?.includes(index),
                opacity: sector.color === SectorColor.DARK ? 0 : opacity,
            });
            sectorGraphic.then((sectorGraphic) => {
                if (
                    !graphicsLayer.graphics.find(
                        (graphic) => graphic.attributes.objectType === "sector" && graphic.attributes.index === index
                    )
                ) {
                    graphicsLayer.add(sectorGraphic);
                }
            });
        }

        if (drawNumbers && sector.color !== SectorColor.DARK) {
            const labelPoint = new Point({
                x:
                    data.center.x +
                    ((innerRadius * 2) / 3) *
                        -Math.cos(
                            (-(startAngle - trueNorthCorrection + (endAngle - startAngle) / 2 + 270) * Math.PI) / 180
                        ),
                y:
                    data.center.y +
                    ((innerRadius * 2) / 3) *
                        -Math.sin(
                            (-(startAngle - trueNorthCorrection + (endAngle - startAngle) / 2 + 270) * Math.PI) / 180
                        ),
                spatialReference: data.center.spatialReference,
            });

            const textGraphic = new Graphic({
                geometry: labelPoint,
                symbol: new TextSymbol({
                    color: [0, 0, 0, 1],
                    haloColor: [255, 255, 255, 1],
                    haloSize: 1,
                    text: (displayIndex + 1).toString(),
                    xoffset: 0,
                    yoffset: 0,
                    font: {
                        size: 12,
                        family: "sans-serif",
                    },
                }),
                attributes: {
                    objectType: "text",
                    index: index,
                    lighthouseId: data.id,
                },
            });

            graphicsLayer.add(textGraphic);
        }

        previousEndAngle = endAngle;
    });

    if (data.sectors.length > 1) {
        // Draw all lines after, i.e. on top of, sectors
        data.sectors.forEach((sector, index) => {
            const startLineLength = sector.startLineLength ?? DefaultSectorValues.defaultLineLength;

            // Draw lines between sectors
            if (index < data.sectors.length) {
                const lineStartAngle = data.sectors[index].startAngle ?? 0;
                const lineGraphicStart = drawSingleLine({
                    center: data.center,
                    angle: lineStartAngle,
                    length: startLineLength,
                    index: index,
                    lighthouseId: data.id,
                    sectorId: sector.globalid,
                    sectorLineSymbol: sectorLineSymbol,
                    fallbackSymbol: fallbackLineSymbol,
                    trueNorthCorrection: trueNorthCorrection,
                });

                lineGraphicStart.then((lineGraphic) => {
                    if (
                        !graphicsLayer.graphics.find(
                            (graphic) => graphic.attributes.objectType === "line" && graphic.attributes.index === index
                        )
                    ) {
                        graphicsLayer.add(lineGraphic);
                    }
                });
            }
        });
    }

    // Fetch the lighthouse center symbology
    const centerLayer = mapview.map.findLayerById("Lys");
    const dummyCenterGraphic = new Graphic({ layer: centerLayer, attributes: { klasse: 1 } });
    const centerSymbol = symbolUtils.getDisplayedSymbol(dummyCenterGraphic) as Promise<SimpleMarkerSymbol>;
    const fallbackCenterSymbol = new SimpleMarkerSymbol({
        style: "circle",
        color: [0, 0, 0, 1],
        size: 8,
    });

    drawCenter(data.center, centerSymbol, fallbackCenterSymbol, data.id).then((centerGraphic) => {
        if (!graphicsLayer.graphics.find((graphic) => graphic.attributes.objectType === "center")) {
            graphicsLayer.add(centerGraphic);
        }
    });

    setIsEditing?.(false);
};

const drawCenter = async (
    center: Point,
    symbol: Promise<SimpleMarkerSymbol>,
    fallbackSymbol: SimpleMarkerSymbol,
    id: string
) => {
    const centerGraphic = new Graphic({
        geometry: center,
        symbol: (await symbol) ?? fallbackSymbol,
        attributes: {
            objectType: "center",
            lighthouseId: id,
        },
    });

    return centerGraphic;
};

interface SectorParams {
    center: Point;
    innerRadius: number;
    outerRadius: number;
    startAngle: number;
    endAngle: number;
    startLineLength: number;
    endLineLength: number;
    symbol: Promise<__esri.Symbol>;
    fallbackSymbol: SimpleFillSymbol;
    index: number;
    lighthouseId: string;
    globalid: string;
    trueNorthCorrection: number;
    fill?: boolean;
    opacity?: number;
}

const drawSingleSector = async ({
    center,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    startLineLength,
    endLineLength,
    symbol,
    fallbackSymbol,
    index,
    lighthouseId,
    globalid,
    trueNorthCorrection,
    fill,
    opacity,
}: SectorParams) => {
    const points = [];
    const numPoints = 100;
    const angleStep = (endAngle - startAngle) / numPoints;
    const rotationOffset = 270;

    // Create a new sector extending all the way to the center
    // Outer arc
    for (let i = 0; i <= numPoints; i++) {
        const angle = startAngle + i * angleStep + rotationOffset - trueNorthCorrection;
        const finalOuterRadius = fill ? Math.max(startLineLength, endLineLength) : outerRadius;
        const x = center.x + finalOuterRadius * -Math.cos(-(angle * Math.PI) / 180);
        const y = center.y + finalOuterRadius * -Math.sin(-(angle * Math.PI) / 180);

        points.push([x, y]);
    }

    // Inner arc (reverse direction)
    for (let i = numPoints; i >= 0; i--) {
        const angle = startAngle + i * angleStep + rotationOffset - trueNorthCorrection;
        const x = center.x + innerRadius * -Math.cos(-(angle * Math.PI) / 180);
        const y = center.y + innerRadius * -Math.sin(-(angle * Math.PI) / 180);
        points.push([x, y]);
    }

    const polygon = new Polygon({
        rings: [points],
        spatialReference: center.spatialReference,
    });

    const sectorGraphic = new Graphic({
        geometry: polygon,
        symbol: (await symbol) ?? fallbackSymbol,
        attributes: {
            objectType: "sector",
            index: index,
            lighthouseId: lighthouseId,
            globalid: globalid,
        },
    });
    sectorGraphic.symbol.color.a = opacity ?? 0.5;

    return sectorGraphic;
};

interface LineParams {
    center: Point;
    angle: number;
    length: number;
    index: number;
    lighthouseId: string;
    sectorId: string;
    sectorLineSymbol: Promise<SimpleLineSymbol>;
    fallbackSymbol: SimpleLineSymbol;
    trueNorthCorrection: number;
}

const drawSingleLine = async ({
    center,
    angle,
    length,
    index,
    lighthouseId,
    sectorId,
    sectorLineSymbol,
    fallbackSymbol,
    trueNorthCorrection,
}: LineParams) => {
    const finalAngle = angle + 270 - trueNorthCorrection;
    const x = center.x + length * -Math.cos(-(finalAngle * Math.PI) / 180);
    const y = center.y + length * -Math.sin(-(finalAngle * Math.PI) / 180);

    const polyline = new Polyline({
        paths: [
            [
                [center.x, center.y],
                [x, y],
            ],
        ],
        spatialReference: center.spatialReference,
    });

    const symbol = (await sectorLineSymbol) ?? fallbackSymbol;

    const lineGraphic = new Graphic({
        geometry: polyline,
        symbol: symbol,
        attributes: {
            objectType: "line",
            index: index,
            lighthouseId: lighthouseId,
            sectorId: sectorId,
        },
    });

    return lineGraphic;
};

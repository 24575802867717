import { useEffect, useMemo, useRef, useState } from "react";
import SearchVM from "@arcgis/core/widgets/Search/SearchViewModel.js";
import LayerSearchSource from "@arcgis/core/widgets/Search/LayerSearchSource.js";
import { AppConfig } from "../../AppConfig";
import Map from "@arcgis/core/Map";

import MagnifyingGlassIcon from "../../assets/header/magnifyingglass.svg?react";

import "./Search.css";
import _ from "lodash";

interface SearchProps {
    setSearchResults: (results: __esri.SearchViewModelSearchResponseResults[]) => void;
}

const Search = (props: SearchProps) => {


    const [searchTerm, setSearchTerm] = useState<string>("");

    const searchBarRef = useRef<HTMLInputElement | null>(null);
    const searchRef = useRef<SearchVM | null>(null);


    const initSearch = () => {
        const map = document.querySelector("arcgis-map") as HTMLArcgisMapElement;
        if (map) {

            const sources: LayerSearchSource[] = [];

            for (const configLayer of AppConfig.Layers) {
                const layer = (map.map as Map).findLayerById(configLayer.Name);
                if (layer) {
                    const source = new LayerSearchSource({
                        layer: layer,
                        searchFields: configLayer.SearchFields,
                        outFields: ["*"],
                    });
                    sources.push(source);
                }
            }

            searchRef.current = new SearchVM({
                view: map.view,
                maxResults: 10000,
                minSuggestCharacters: 3,
                sources: sources,
                includeDefaultSources: false,
                autoSelect: false,
                goToOverride: () => { }
            });
        } else {
            setTimeout(() => {
                initSearch();
            }, 500);
        }
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    }

    // Create stable reference to debounced function
    const debounceSearch = useMemo(
        () => _.debounce((text: string) => {
            if (searchRef.current) {
                searchRef.current.search(text).then((results) => {
                    props.setSearchResults(results.results);
                });
            }
        }, 500),
        [props.setSearchResults, searchRef]
    );

    useEffect(() => {
        if (searchTerm.length > 2) {
            console.log("Debouncing search");
            debounceSearch(searchTerm);
        } else if (searchRef.current) {
            searchRef.current.clear();
            props.setSearchResults([]);
        }

        // Clean up debounce on unmount
        return () => {
            debounceSearch.cancel();
        };
    }, [searchTerm, debounceSearch]);


    useEffect(() => {
        console.log("Init search");
        initSearch();

        return () => {
            searchRef.current?.destroy();
        }
    }, []);

    return (
        <div ref={searchBarRef} className="search-container">
            <div className="search-input-box">
                <input className="search-input" type="text" value={searchTerm} onChange={handleSearchChange} placeholder="Søk i kartet" />
                <MagnifyingGlassIcon />
            </div>
        </div>
    )
}

export default Search;
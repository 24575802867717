import "../../../style/generics.css";
import { useNumberOfDigits } from "../../../utils/helpers";
import { Sector } from "../../../utils/lighthouseUtils";
import ErrorMessage from "../../genericComponents/ErrorMessage/ErrorMessage";
import InputField from "../../genericComponents/InputField/InputField";
import "./SectorAttribute.css";

interface SectorNumberAttributeProps {
    label: string;
    sector: Sector;
    attributeName: string;
    defaultValue: number;
    setValue: (value: number, sectorIndex: number, attributeName: string) => void;
    errorMessage?: string;
    onFocus?: (value: string) => void;
    onBlur?: (value: string) => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    valueMultiplier?: number;
    decimalDigits?: number;
    unit?: string;
}

const SectorNumberAttribute = (props: SectorNumberAttributeProps) => {
    const {
        label,
        sector,
        attributeName,
        defaultValue,
        setValue,
        errorMessage,
        onFocus,
        onBlur,
        valueMultiplier,
        decimalDigits,
        unit,
    } = props;

    const value = (sector as any)[attributeName] ?? defaultValue;

    return (
        <div>
            <div className="sector-attribute">
                <span className="sector-label">{label}</span>
                <InputField
                    value={useNumberOfDigits(value / (valueMultiplier ?? 1), decimalDigits ?? 2).toString()}
                    inputFieldId={`${sector.index}-${attributeName}`}
                    label=""
                    onChange={(newValue) => {
                        setValue(parseFloat(newValue) * (valueMultiplier ?? 1), sector.index, attributeName);
                    }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onMouseEnter={props.onMouseEnter}
                    onMouseLeave={props.onMouseLeave}
                    type="number"
                    hasError={Boolean(errorMessage)}
                />
                {unit ? <span className="sector-unit">{unit}</span> : null}
            </div>

            {errorMessage ? <ErrorMessage message={errorMessage} light /> : null}
        </div>
    );
};

export default SectorNumberAttribute;

import { AppConfig } from '../../AppConfig';
import { WorkPaginatedResult, GetWorkOrdersParams } from '../../types/workOrderTypes';
import { getAGEToken } from '../authenticate';

export async function getWorkOrdersForObject({
    page = 1,
    pageSize = 20,
    assignedTo,
    gisObjId,
    statusCodes
}: GetWorkOrdersParams): Promise<WorkPaginatedResult> {
    const baseUrl = AppConfig.NFSAPI.BaseApiUrl + 'work/search';
    const params = new URLSearchParams();

    params.append('page', page.toString());
    params.append('pageSize', pageSize.toString());

    if (assignedTo) params.append('assignedTo', assignedTo);
    if (gisObjId) params.append('gisObjId', gisObjId.toString());
    if (statusCodes) params.append('statusCodes', statusCodes);

    const url = `${baseUrl}?${params.toString()}`;

    const token = await getAGEToken();

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data: WorkPaginatedResult = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching work orders:', error);
        throw error;
    }
}

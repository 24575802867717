import { useState } from "react";
import SectorIcon from "../../../assets/sectors.svg?react";
import Collapsible from "../../genericComponents/Collapsible/Collapsible";
import "./SectorRose.css";
import SectorRoseMap from "./SectorRoseMap";

const SectorRose = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="feature-viewer-feature-box">
            <Collapsible title="Sektorrose" icon={<SectorIcon />} isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="embedded-map">
                    <SectorRoseMap />
                </div>
            </Collapsible>
        </div>
    );
};

export default SectorRose;

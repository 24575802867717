import { AppConfig } from "../AppConfig";
import { getAGEToken } from "./authenticate";

export const getUsers = async () => {
    let usersUrl = AppConfig.ArcGIS.BaseUrl + "/sharing/rest/portals/self/users";
    const paramString = "&excludeSystemUsers=true&num=1000";
    const token = await getAGEToken();

    let users: __esri.PortalUser[] = [];
    await fetch(usersUrl + `?f=json${paramString}`,
        {
            method: 'POST',
            headers: {
                'X-Esri-Authorization': 'Bearer ' + token
            }
        }).then(async (response) => {
            await response.json().then((data) => {
                if (data.users) {
                    users = data.users;
                }
            });
        }).catch((error) => {
            console.error(error);
        });
    return users;
};
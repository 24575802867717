import Layer from "@arcgis/core/layers/Layer";
import "./FeatureField.css";
import { GetCodedValueAndAlias, updateFeatureAttributes } from "../../utils/arcgisUtils";
import { formatDate } from "../../utils/helpers";
import NfsDropdown from "../genericComponents/NfsDropdown/NfsDropdown";
import InputField from "../genericComponents/InputField/InputField";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import { readOnlyFields } from "../../constants/NauticalObjects";
import { useContext } from "react";
import { AppContext } from "../../Context";

interface FeatureFieldProps {
    isEditing: boolean;
    onChange: (value: any) => void;
    field: string;
    layer: Layer;
    attributes: __esri.Graphic["attributes"];
}

const FeatureField = (props: FeatureFieldProps) => {
    const appContext = useContext(AppContext);
    const selectedFeature = appContext?.selectedFeature.value;

    const codedValue = GetCodedValueAndAlias(props.layer, props.field, props.attributes[props.field])[1];

    const renderEditableField = () => {
        if (readOnlyFields.includes(props.field)) {
            return renderField();
        }

        if (!props.layer.hasOwnProperty("fields")) {
            return renderField();
        }

        const fieldInfo = (props.layer as FeatureLayer)?.fields.find((field) => field.name === props.field);

        if (!fieldInfo) {
            return renderField();
        }

        if (!fieldInfo.editable) {
            return renderField();
        }

        if (
            fieldInfo.hasOwnProperty("domain") &&
            fieldInfo.domain !== null &&
            fieldInfo.domain.type === "coded-value"
        ) {
            const domainOptions = fieldInfo.domain.codedValues.map(
                (codedValue: { name: string; code: any }) => codedValue.code
            );

            return (
                <NfsDropdown
                    options={domainOptions}
                    selectedOption={props.attributes[props.field] ?? domainOptions[0]}
                    displayValueFunction={(value: number | string) =>
                        GetCodedValueAndAlias(props.layer, props.field, value)[1]
                    }
                    setSelectedOption={(value) => {
                        props.onChange(value);
                        const updateObject = selectedFeature?.clone();
                        if (!updateObject) return;
                        updateObject.attributes = {};
                        updateObject.attributes[props.field] = value;
                        updateObject.attributes["objectid"] = props.attributes["objectid"];
                        updateObject.attributes["globalid"] = props.attributes["globalid"];
                        selectedFeature!.attributes[props.field] = value;
                        updateFeatureAttributes(updateObject);
                    }}
                    placeholder={"Ikke definert"}
                    allowEmpty={fieldInfo.nullable}
                />
            );
        }

        let fieldType = null;

        if (fieldInfo.type === "string") {
            fieldType = "text";
        } else if (fieldInfo.type === "double" || fieldInfo.type === "integer") {
            fieldType = "number";
        }

        if (fieldType !== null) {
            return (
                <InputField
                    inputFieldId={props.field}
                    label=""
                    value={props.attributes[props.field] ?? ""}
                    type={fieldType}
                    onChange={(value) => {
                        props.onChange(value);
                    }}
                    onBlur={async () => {
                        const updateObject = selectedFeature?.clone();
                        if (!updateObject) return;
                        updateObject.attributes = {};
                        updateObject.attributes[props.field] = props.attributes[props.field];
                        updateObject.attributes["objectid"] = props.attributes["objectid"];
                        updateObject.attributes["globalid"] = props.attributes["globalid"];
                        selectedFeature!.attributes[props.field] = props.attributes[props.field];
                        await updateFeatureAttributes(updateObject);
                        appContext?.selectedFeature.set(selectedFeature!.clone());
                    }}
                />
            );
        }

        return <span>editing field</span>;
    };

    const renderField = () => {
        if (!props.layer.hasOwnProperty("fields")) {
            return <span className="feature-viewer-value">{codedValue}</span>;
        }

        const fieldInfo = (props.layer as FeatureLayer)?.fields.find((field) => field.name === props.field);

        if (!fieldInfo) {
            return <span className="feature-viewer-value">{codedValue}</span>;
        }

        if (fieldInfo.type === "date") {
            return <span className="feature-viewer-value">{formatDate(codedValue as number)}</span>;
        }

        return <span className="feature-viewer-value">{codedValue}</span>;
    };

    return (
        <div className="feature-field">
            {!props.isEditing && <span className="feature-viewer-value">{codedValue}</span>}
            {props.isEditing && renderEditableField()}
        </div>
    );
};

export default FeatureField;

import "./ErrorMessage.css";
import ErrorIcon from "../../../assets/error.svg?react";

interface ErrorMessageProps {
    message: string;
    light?: boolean;
}

const ErrorMessage = (props: ErrorMessageProps) => {
    const { message, light } = props;

    return (
        <div className={`error-message-${light ? "light" : "normal"}`}>
            {light ? null : <ErrorIcon />}
            {message}
        </div>
    );
};

export default ErrorMessage;

import { combineReducers } from "redux";
import app, { AppState } from "./appSlice";
import version, { VersionState } from "./versionSlice";
import search, { SearchState } from "./searchSlice";
import scale, { ScaleState } from "./scaleSlice";
import lighthouse, { LighthouseState } from "./lighthouseSlice";
import triggers, { TriggersState } from "./triggersSlice";

const rootReducer = combineReducers({
    app,
    version,
    search,
    scale,
    lighthouse,
    triggers
});

export type StoreState = {
    app: AppState;
    version: VersionState;
    search: SearchState;
    scale: ScaleState;
    lighthouse: LighthouseState;
    triggers: TriggersState;
};

export default rootReducer;


import { alterVersion, getVersionName, getVersionSharedWith, removeVersionAccess } from "../../utils/versioningUtils";
import { obscureString } from "../../utils/helpers";
import NfsButton from "../genericComponents/NfsButton/NfsButton";

import CloseIcon from "../../assets/versionControl/close.svg?react";
import CopyIcon from "../../assets/versionControl/copy.svg?react";

import "./VersionAdmin.css";
import { useEffect, useState } from "react";
import NfsDropdown from "../genericComponents/NfsDropdown/NfsDropdown";
import { getUsers } from "../../utils/portalUtils";
import Tabs from "../genericComponents/Tabs/Tabs";
import TabItems from "../genericComponents/Tabs/TabItems";
import TabItem from "../genericComponents/Tabs/TabItem";
import TabPanels from "../genericComponents/Tabs/TabPanels";
import TabPanel from "../genericComponents/Tabs/TabPanel";
import ConfirmationPopover from "../genericComponents/confirmationPopover/confirmationPopover";
import { toast } from "react-toastify";

interface VersionAdminProps {
    version: __esri.VersionInfoJSON;
    close: () => void;
    reloadVersions: () => void;
}

const VersionAdmin = (props: VersionAdminProps) => {

    const [validForHours, setValidForHours] = useState<number | undefined>(undefined);
    const [shareLink, setShareLink] = useState<string>("");
    const [users, setUsers] = useState<__esri.PortalUser[]>([]);
    const [newOwner, setNewOwner] = useState<__esri.PortalUser | null>(null);
    const [versionSharedWith, setVersionSharedWith] = useState<__esri.Graphic[]>([]);

    const createShareLink = (validForHours?: number) => {
        const domain = window.location.origin;
        let versionGuid = props.version.versionIdentifier.guid;
        if (versionGuid) {
            versionGuid = versionGuid.replace("{", "").replace("}", "");
        }

        if (!versionGuid || !domain) {
            return "Kunne ikke generere delingslenke";
        }
        let sharingString = versionGuid;

        if (validForHours) {
            const validUntil = new Date();
            validUntil.setHours(validUntil.getHours() + validForHours);
            sharingString += `,${validUntil.getTime()}`;
        }
        const obscuredString = obscureString(sharingString);

        const shareLink = `${domain}/?sharing=${obscuredString}`;
        return shareLink;
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            toast.success("Kopiert til utklippstavle");
        });
    }

    const transferOwnership = async () => {
        if (newOwner) {
            const trimmedGuid = props.version.versionIdentifier.guid.replace("{", "").replace("}", "");
            const success = await alterVersion(trimmedGuid, newOwner.username);
            if (success) {
                toast.success("Eierskap overført");
            } else {
                toast.error("Kunne ikke overføre eierskap");
            }
        }
        props.reloadVersions();
        props.close();
    }

    const removeAccess = async (accessVersionGlobalId: string) => {
        const result = await removeVersionAccess(accessVersionGlobalId)
        if (result) {
            toast.success("Tilgang fjernet");
        } else {
            toast.error("Kunne ikke fjerne tilgang");
        }
        getVersionSharedWith(props.version).then((versionSharedWith) => {
            setVersionSharedWith(versionSharedWith);
        });
    }

    useEffect(() => {
        setShareLink(createShareLink(validForHours));
    }, [validForHours]);

    useEffect(() => {
        setShareLink(createShareLink());
        getUsers().then((users) => {
            setUsers(users);
        });
        getVersionSharedWith(props.version).then((versionSharedWith) => {
            setVersionSharedWith(versionSharedWith);
        });
    }, []);

    return (
        <div className="version-admin">
            <div className="version-admin-header">
                <h2>Administrer versjon - {getVersionName(props.version)}</h2>
                <button className="empty-button" onClick={props.close}>
                    <CloseIcon />
                </button>
            </div>
            <div className="version-admin-content">
                <Tabs className="h-100 w-100">
                    <TabItems>
                        <TabItem label="Versjonsdeling" />
                        <TabItem label={`Administrer tilgang ${versionSharedWith.length > 0 ? '(' + versionSharedWith.length + ')' : ""}`} />
                        <TabItem label="Eier bytte" />
                    </TabItems>
                    <TabPanels>
                        <TabPanel>
                            <div className="version-admin-content-sharing">
                                <h3>Her kan du generere lenke for å invitere andre brukere til å få skrivetilgang til din versjon.</h3>
                                <p>Alle du deler denne lenken med vil kunne gjøre endringer i din versjon. <br /> Du kan velge hvor lenge lenken skal være gyldig. <br />En bruker som har akseptert invitasjonen vil fremdeles ha skrivetilgang etter lenken er utløpt.</p>
                                <NfsDropdown
                                    options={["1 time", "8 timer", "24 timer"]}
                                    allowEmpty
                                    placeholder="Ingen utløpsdato"
                                    selectedOption={validForHours ? `${validForHours} timer` : null}
                                    setSelectedOption={(option) => {
                                        if (option === null) {
                                            setValidForHours(undefined);
                                        } else {
                                            const hours = parseInt(option.toString().split(" ")[0]);
                                            setValidForHours(hours);
                                        }
                                    }}
                                >

                                </NfsDropdown>
                                <div className="w-100 flex-row center small-gap">
                                    <div className="version-admin-sharing-url">
                                        <span>{shareLink}</span>
                                    </div>
                                    <NfsButton
                                        className="fit-content"
                                        outlined
                                        onClick={() => { copyToClipboard(shareLink); }}
                                    >
                                        <CopyIcon width={24} height={24} />
                                        Kopier
                                    </NfsButton>
                                </div>
                                {/* <button onClick={createShareLink}>Del versjon</button> */}
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="version-admin-sharing">
                                <h3>Her kan du administrere tilgangen til denne versjonen</h3>
                                <p>Se hvilke brukere som har tilgang til din versjon og administrer tilgangen.</p>
                                {versionSharedWith.length > 0 &&
                                    <div className="version-admin-sharing-list">
                                        <h4>Delt med:</h4>
                                        {versionSharedWith.map((access) => {
                                            return (
                                                <div key={access.attributes.globalid} className="version-admin-sharing-user">
                                                    <span>Epost: {access.attributes.epost}</span>
                                                    <span>Brukernavn: {access.attributes.opprettet_av}</span>
                                                    <NfsButton className="no-flex ml-auto" onClick={() => { removeAccess(access.attributes.globalid) }} outlined>Fjern tilgang</NfsButton>
                                                </div>
                                            );
                                        })}
                                    </div>
                                }
                                {versionSharedWith.length === 0 &&
                                    <div className="version-admin-sharing-list">
                                        <h4>Denne versjonen er foreløpig ikke delt med noen</h4>
                                    </div>
                                }
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="version-admin-content-transfer">
                                <h3>Her kan du overføre eierskap av denne versjonen</h3>
                                <p>Overfør eierskapet til en annen bruker. <br /> Den nye eieren vil få alle rettigheter til å administrere versjonen.</p>
                                <div className="w-100 flex-row center small-gap">
                                    <NfsDropdown
                                        options={users.map((user) => user.username)}
                                        allowEmpty
                                        placeholder="Velg ny eier"
                                        selectedOption={newOwner ? newOwner.username : null}
                                        setSelectedOption={(option) => {
                                            const user = users.find((user) => user.username === option);
                                            setNewOwner(user || null);
                                        }}
                                    />
                                    <ConfirmationPopover
                                        title="Overfør eierskap?"
                                        message="Er du sikker på at du vil overføre eierskapet av denne versjonen?"
                                        onConfirm={transferOwnership}
                                        onCancel={() => { }}
                                        cancelText="Avbryt"
                                        confirmText="Overfør"
                                    >
                                        <div className="nfs-button">
                                            Overfør eierskap
                                        </div>
                                    </ConfirmationPopover>
                                </div>
                            </div>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </div>
            <div className="version-admin-footer">

            </div>
        </div>
    );
};

export default VersionAdmin;
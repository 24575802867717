import { Popover } from "react-tiny-popover";
import "./NfsDropdown.css";
import { ReactNode, useEffect, useRef, useState } from "react";
import ArrowDownIcon from "../../../assets/arrow-down.svg?react";

interface NfsDropdownProps {
    options: string[];
    selectedOption: string | null;
    setSelectedOption: (option: string | null) => void;
    placeholder: string;
    allowEmpty?: boolean;
    displayValueFunction?: (option: number | string) => any;
    displayClassName?: string;
    iconReplacement?: ReactNode;
    disabled?: boolean;
    readonly?: boolean;
    searchable?: boolean;
}

const NfsDropdown = (props: NfsDropdownProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const [searchValue, setSearchValue] = useState("");
    const [filteredOptions, setFilteredOptions] = useState<string[]>(props.options);

    const searchFieldRef = useRef<HTMLInputElement>(null);

    const toggleIsOpen = () => {
        if (props.disabled || props.readonly) {
            setIsOpen(false);
            return;
        }
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (props.searchable) {
            if (isOpen) {
                setTimeout(() => {
                    searchFieldRef.current?.focus({ preventScroll: true });
                }, 100);
            }
        }
    }, [props.searchable, isOpen]);

    useEffect(() => {
        if (searchValue === "") {
            setFilteredOptions(props.options);
            return;
        }
        const filtered = props.options.filter((option) => option.toLowerCase().includes(searchValue.toLowerCase()));
        setFilteredOptions(filtered);
    }, [searchValue, props.options]);

    return (
        <Popover
            isOpen={isOpen}
            onClickOutside={() => setIsOpen(false)}
            positions={["bottom", "top"]}
            align="start"
            containerClassName="nfs-dropdown-panel"
            content={
                <>
                    {props.searchable && (
                        <input
                            autoFocus
                            ref={searchFieldRef}
                            type="text"
                            className="nfs-dropdown-search"
                            value={searchValue}
                            placeholder="Søk..."
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                            }}
                        />
                    )}
                    <div className="nfs-dropdown-panel">
                        {props.allowEmpty && props.selectedOption !== null && (
                            <button
                                className="nfs-dropdown-option"
                                onClick={() => {
                                    props.setSelectedOption(null);
                                    setIsOpen(false);
                                }}
                            >
                                {props.placeholder}
                            </button>
                        )}
                        {filteredOptions.map((option) => {
                            if (option === props.selectedOption) {
                                return null;
                            }
                            return (
                                <button
                                    key={option}
                                    className="nfs-dropdown-option"
                                    onClick={() => {
                                        props.setSelectedOption(option);
                                        setIsOpen(false);
                                    }}
                                >
                                    {!props.displayValueFunction && option}
                                    {props.displayValueFunction?.(option)}
                                </button>
                            );
                        })}
                    </div>
                </>
            }
        >
            <button
                className={`nfs-dropdown ${props.displayClassName ?? ""}`}
                disabled={props.disabled}
                onClick={toggleIsOpen}
            >
                <span className="nfs-dropdown-text">
                    {props.selectedOption !== null ? (
                        <>
                            {!props.displayValueFunction && props.selectedOption}
                            {props.displayValueFunction?.(props.selectedOption)}
                        </>
                    ) : (
                        props.placeholder
                    )}
                </span>
                {props.iconReplacement ? (
                    props.iconReplacement
                ) : (
                    <>{props.options.length > 1 && !props.readonly && <ArrowDownIcon />}</>
                )}
            </button>
        </Popover>
    );
};

export default NfsDropdown;

import Graphic from "@arcgis/core/Graphic";
import { useEffect } from "react";
import { highlightFeature } from "./arcgisUtils";
import { metersPerNauticalMile } from "./constants";

/**
 * useClickedOutside Hook
 * @usage : useClickedOutside(ref, () => setIsOpen(false));
 * @description : Custom hook used for components to handle when user clicked outside the ref parameter.
 * @parameters : ref is a reference to the JSX element of the component.
 * onClickOutside is the function we want to call when clicked outside given component.
 */
export function useClickedOutside(ref: React.RefObject<HTMLDivElement>, onClickOutside: () => void) {
    useEffect(() => {
        function handleClickedOutside(event: MouseEvent): void {
            // trigger the provided function if this element was not clicked
            if (ref.current && !ref.current.contains(event.target as Node)) {
                onClickOutside();
            }
        }
        document.addEventListener("mousedown", handleClickedOutside);
        return () => {
            // clean up
            document.removeEventListener("mousedown", handleClickedOutside);
        };
    });
}

export const uuidv4 = () => {
    const str = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
    return `{${str.toUpperCase()}}`;
};

/**
 * Takes and object and optional zoom level to zoom to the object on the map.
 * @param object
 * @param zoomLevel
 * @returns
 */
export const zoomToObject = async (
    object: __esri.Graphic | __esri.Feature | __esri.Geometry,
    scale?: number,
    zoomLevel?: number,
    highlight?: boolean
): Promise<__esri.Handle | undefined> => {
    const map = document.getElementsByTagName("arcgis-map")[0];
    if (!map) {
        return;
    }
    let highlightHandle;
    await map.view
        .goTo(
            {
                target: object,
                scale: scale,
                zoom: zoomLevel,
            },
            {
                animate: true,
                duration: 1000,
                easing: "ease-in-out",
            }
        )
        .then(async () => {
            if (object instanceof Graphic) {
                if (highlight) {
                    highlightHandle = await highlightFeature(object);
                }
            }
        });
    return highlightHandle;
};

export const formatDate = (timestamp: number, withTime?: boolean) => {
    if (!timestamp) {
        return "";
    }
    const date = new Date(timestamp);
    const time = withTime ? ` ${date.getHours()}:${date.getMinutes()}` : "";
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${time}`;
};

export const isNumber = (value: string) => {
    return !isNaN(Number(value));
};

export const formatScaleString = (scale: number) => {
    return `1:${scale.toFixed(0)}`;
};

export const parseScaleString = (scale: string) => {
    return parseInt(scale.replace("1:", ""));
};

export const metersToNauticalMiles = (meters: string) => {
    const parts = meters.split(" ");
    const unit = parts[parts.length - 1];

    meters = meters.replace(/[a-zA-Z\s]/g, "");
    const metersNumber = parseFloat(meters);
    if (isNaN(metersNumber)) {
        throw new Error("Invalid input: meters must be a number.");
    }

    if (unit === "Nm") {
        return meters;
    }
    if (unit === "m") {
        return (metersNumber / metersPerNauticalMile).toFixed(4).replace(".", ",") + " Nm";
    }
    if (unit === "km") {
        return ((metersNumber * 1000) / metersPerNauticalMile).toFixed(4).replace(".", ",") + " Nm";
    }
};

export const nauticalMilesToMeters = (nauticalMiles: string) => {
    const parts = nauticalMiles.split(" ");
    const length = parts[0];

    const nauticalMilesNumber = parseFloat(length.replace(",", "."));
    if (isNaN(nauticalMilesNumber)) {
        throw new Error("Invalid input: nautical miles must be a number.");
    }

    return nauticalMilesNumber * metersPerNauticalMile + " m";
};

export const clamp = (value: number, min: number, max: number, tolerance: number = 0) => {
    return Math.min(Math.max(value, min + tolerance), max - tolerance);
};

export const clampWithCircleRollover = (value: number, min: number, max: number, tolerance: number = 0) => {
    // Value should be between 0 and max, OR between min and 360
    const center = (min - max) / 2;
    if (value < center) {
        return clamp(value, 0 + tolerance, max - tolerance);
    } else {
        return clamp(value, min + tolerance, 360 - tolerance);
    }
};

export const useNumberOfDigits = (value: number | string, digits: number = 2) => {
    if (typeof value === "string") {
        value = parseFloat(value);
    }
    return parseFloat(value.toFixed(digits));
};

export const deepCopy = (obj: any) => {
    return JSON.parse(JSON.stringify(obj));
};

export const hasAccessToGroups = (self: any, groupIds: string[]) => {
    if (!self || !self.groups) {
        return false;
    }
    return self.groups.some((group: any) => groupIds.includes(group.id));
};

export const hasAccessToRoles = (self: any, roles: string[]) => {
    if (!self || !self.roleId) {
        return false;
    }
    return roles.includes(self.roleId);
};

// Function to obscure the string
export const obscureString = (inputString: string) => {
    const XOR_KEY = 42; // Define the XOR key
    // First layer: Reverse the string
    const reversedString = inputString.split("").reverse().join("");

    // Second layer: Base64 encode
    const base64Encoded = btoa(reversedString);

    // Third layer: XOR each character with the key
    const xorEncoded = [...base64Encoded].map((char) => String.fromCharCode(char.charCodeAt(0) ^ XOR_KEY)).join("");

    // URL encode the result
    const urlEncoded = encodeURIComponent(xorEncoded);

    return urlEncoded;
};

// Function to deobscure the string
export const deobscureString = (obscuredString: string) => {
    const XOR_KEY = 42; // Define the XOR key
    // URL decode the string
    const urlDecoded = decodeURIComponent(obscuredString);

    // Reverse the XOR operation
    const xorDecoded = [...urlDecoded].map((char) => String.fromCharCode(char.charCodeAt(0) ^ XOR_KEY)).join("");

    // Decode Base64
    const base64Decoded = atob(xorDecoded);

    // Reverse the string to get the original
    const originalString = base64Decoded.split("").reverse().join("");

    return originalString;
};

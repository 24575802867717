import "./Toggle.css";

interface ToggleProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
    small?: boolean;
}

const Toggle = (props: ToggleProps) => {
    const { checked, onChange } = props;

    return (
        <label className={`switch ${props.small ? "small" : ""}`}>
            <input type="checkbox" checked={checked} onChange={() => onChange(!checked)} />
            <span className={`slider round ${props.small ? "small" : ""}`}></span>
        </label>
    );
};

export default Toggle;

import { createRoot } from "react-dom/client";
import Modal from "react-modal";
import App from "./App.tsx";
import "./index.css";
import { Provider } from "react-redux";
import React from "react";
import storeSetup from "./store/storeSetup.ts";

Modal.setAppElement("#root");

createRoot(document.getElementById("root")!).render(
    <Provider store={storeSetup}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Provider>
);
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import * as symbolUtils from "@arcgis/core/symbols/support/symbolUtils.js";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppConfig, LayersConfig } from "../../../AppConfig";
import { AppContext, LighthouseContext } from "../../../Context";
import DataTableIcon from "../../../assets/datatable.svg?react";
import { setIsSectorEditorOpen, setIsSectorTableOpen } from "../../../store/lighthouseSlice";
import { StoreState } from "../../../store/rootReducer";
import { getMapView } from "../../../utils/arcgisUtils";
import { getRelationshipId, Sector, SectorColor } from "../../../utils/lighthouseUtils";
import FeatureViewerTitle from "../../FeatureViewer/FeatureViewerTitle";
import ErrorMessage from "../../genericComponents/ErrorMessage/ErrorMessage";
import NfsButton from "../../genericComponents/NfsButton/NfsButton";
import RangeSlider from "../../genericComponents/RangeSlider/RangeSlider";
import Toggle from "../../genericComponents/Toggle/Toggle";
import "./SectorEditorContent.css";
import SectorMenu from "./SectorMenu";
import SectorRose from "./SectorRose";
import { drawSectors } from "./lighthouseDrawing";

const SectorEditorContent = () => {
    const appContext = useContext(AppContext);
    const lighthouseContext = useContext(LighthouseContext);

    const selectedFeature = appContext?.selectedFeature.value;
    const lighthouseDataState = lighthouseContext?.lighthouseData.value;
    const setIsDrawing = lighthouseContext?.isDrawing.set;
    const isEditing = lighthouseContext?.isEditing.value;

    const dispatch = useDispatch();
    const isShowingIALAWarning = useSelector((state: StoreState) => state.lighthouse.isShowingIALAWarning);
    const isSectorTableOpen = useSelector((state: StoreState) => state.lighthouse.isSectorTableOpen);
    const lighthouseTableSelectedSector = useSelector(
        (state: StoreState) => state.lighthouse.lighthouseTableSelectedSector
    );
    const mapScale = useSelector((state: StoreState) => state.app.mapScale);
    const trueNorth = useSelector((state: StoreState) => state.lighthouse.currentLighthouseTrueNorth);

    const [symbolHtmlElement, setSymbolHtmlElement] = useState<HTMLElement | null>(null);
    const [hideOthers, setHideOthers] = useState(false);

    const layerConfig = useRef<LayersConfig>();

    const hideFeatures = async () => {
        const view = getMapView();
        if (!view) return;

        const sectorTable = view.map.findTableById("Lyssektor") as __esri.FeatureLayer;
        const sectorLayer = view.map.findLayerById("Lyssektor") as __esri.FeatureLayer;
        const lineLayer = view.map.findLayerById("Sektorlinje") as __esri.FeatureLayer;
        const lightLayer = view.map.findLayerById("Lys") as __esri.FeatureLayer;

        const sectorTableToLightRelation = getRelationshipId(lightLayer);
        const sectorTableToSectorGeometryRelation = getRelationshipId(sectorLayer);
        const sectorTableToLineGeometryRelation = getRelationshipId(lineLayer);

        console.log(selectedFeature);

        // Hide all static sectors and lines - we are drawing our own graphics that can be edited
        const allSectorDatas = await lightLayer.queryRelatedFeatures({
            outFields: ["*"],
            relationshipId: sectorTableToLightRelation,
            objectIds: [selectedFeature?.attributes.objectid],
        });

        const allSectorFeatures = allSectorDatas[selectedFeature?.attributes.objectid].features;

        const allSectorGeometries = (await sectorTable.queryRelatedFeatures({
            outFields: ["*"],
            relationshipId: sectorTableToSectorGeometryRelation,
            objectIds: allSectorFeatures.map((graphic: __esri.Graphic) => graphic.attributes.objectid),
        })) as object;

        const allSectorObjectIds = Object.values(allSectorGeometries).map(
            (sectorGeometries) => sectorGeometries.features[0].attributes.objectid
        );

        const allLineGeometries = (await sectorTable.queryRelatedFeatures({
            outFields: ["*"],
            relationshipId: sectorTableToLineGeometryRelation,
            objectIds: allSectorFeatures.map((graphic: __esri.Graphic) => graphic.attributes.objectid),
        })) as object;

        const allLineObjectIds = Object.values(allLineGeometries)
            .map((lineGeometries) =>
                lineGeometries.features.map((feature: __esri.Graphic) => feature.attributes.objectid)
            )
            .flat();

        sectorLayer.definitionExpression = `objectid not in (${allSectorObjectIds.join(",")})`;
        lineLayer.definitionExpression = `objectid not in (${allLineObjectIds.join(",")})`;
        lightLayer.definitionExpression = `objectid <> ${selectedFeature?.attributes.objectid}`;
    };

    useEffect(() => {
        const mapview = getMapView();
        if (!mapview) return;

        const lighthouseLayer = mapview.map.findLayerById("Fyrlykt") as GraphicsLayer;
        const graphics = lighthouseLayer.graphics.filter(
            (graphic) => graphic.attributes.lighthouseId === selectedFeature?.attributes.lighthouseData.id
        );

        lighthouseLayer.removeMany(graphics.toArray());

        hideFeatures();

        return () => {
            const view = getMapView();
            if (!view) return;

            const sectorLayer = view.map.findLayerById("Lyssektor") as __esri.FeatureLayer;
            const lineLayer = view.map.findLayerById("Sektorlinje") as __esri.FeatureLayer;
            const lightLayer = view.map.findLayerById("Lys") as __esri.FeatureLayer;

            sectorLayer.definitionExpression = "";
            lineLayer.definitionExpression = "";
            lightLayer.definitionExpression = "";
        };
    }, []);

    useEffect(() => {
        if (!lighthouseDataState || !selectedFeature) return;

        selectedFeature.attributes.lighthouseData = lighthouseDataState;
        appContext.selectedFeature.set(appContext.selectedFeature.value);

        const mapview = getMapView();
        if (!mapview) return;

        const lighthouseEditingLayer = mapview.map.findLayerById("FyrlyktEditing") as GraphicsLayer;

        const showAllSectors = lighthouseContext.showSectorColors.value
            ? Array(lighthouseDataState.sectors.length)
                  .fill(0)
                  .map((_, i) => i)
            : [];

        drawSectors({
            graphicsLayer: lighthouseEditingLayer,
            data: lighthouseDataState,
            setIsEditing: setIsDrawing,
            sectorsToFill: lighthouseTableSelectedSector !== null ? [lighthouseTableSelectedSector] : showAllSectors,
            opacity: lighthouseContext.sectorOpacity.value,
            drawNumbers: false,
            trueNorthCorrection: trueNorth,
        });
    }, [lighthouseDataState, selectedFeature, lighthouseTableSelectedSector, mapScale]);

    useEffect(() => {
        const mapview = getMapView();
        if (!mapview) return;

        const lighthouseEditingLayer = mapview.map.findLayerById("FyrlyktEditing") as GraphicsLayer;

        const showAllSectors = lighthouseContext!.showSectorColors.value
            ? Array(selectedFeature?.attributes.lighthouseData.sectors.length)
                  .fill(0)
                  .map((_, i) => i)
            : [];

        drawSectors({
            graphicsLayer: lighthouseEditingLayer,
            data: selectedFeature?.attributes.lighthouseData,
            setIsEditing: setIsDrawing,
            sectorsToFill: lighthouseTableSelectedSector !== null ? [lighthouseTableSelectedSector] : showAllSectors,
            opacity: lighthouseContext!.sectorOpacity.value,
            drawNumbers: false,
            trueNorthCorrection: trueNorth,
        });
    }, [
        lighthouseContext?.showSectorColors.value,
        lighthouseContext?.sectorOpacity.value,
        lighthouseTableSelectedSector,
        mapScale,
    ]);

    useEffect(() => {
        if (isEditing) return;
        if (!selectedFeature?.attributes.lighthouseData) return;

        const mapview = getMapView();
        if (!mapview) return;

        const lighthouseEditingLayer = mapview.map.layers.find(
            (layer) => layer.id === "FyrlyktEditing"
        ) as GraphicsLayer;

        lighthouseEditingLayer.load().then(() => {
            const graphics = lighthouseEditingLayer.graphics;

            updateSectors(graphics);
        });
    }, [isEditing]);

    useEffect(() => {
        if (!selectedFeature) {
            dispatch(setIsSectorTableOpen(false));
            dispatch(setIsSectorEditorOpen(false));
            return;
        }

        layerConfig.current = AppConfig.Layers.find((layerConfig) => layerConfig.Name === selectedFeature.layer.id);

        symbolUtils.getDisplayedSymbol(selectedFeature).then((symbol) => {
            if (symbol) {
                symbolUtils.renderPreviewHTML(symbol, { size: 12 }).then((symbolElement) => {
                    setSymbolHtmlElement(symbolElement);
                });
            } else {
                setSymbolHtmlElement(null);
            }
        });
    }, [selectedFeature]);

    const updateSectors = (graphics: __esri.Collection<__esri.Graphic>) => {
        const mapview = getMapView();
        if (!mapview) return;

        const lighthouseEditingLayer = mapview.map.findLayerById("FyrlyktEditing") as GraphicsLayer;

        lighthouseDataState?.sectors.forEach((sector, index) => {
            const sectorLine = graphics.find((graphic) => {
                return graphic.attributes?.index === sector.index && graphic.attributes?.objectType === "line";
            });
            if (!sectorLine) return;

            const newSectorLine = sectorLine.clone();
            newSectorLine.attributes.index = sector.index;
            lighthouseEditingLayer.remove(sectorLine);
            lighthouseEditingLayer.add(newSectorLine);

            sector.index = index;
        });
    };

    const toggleIsSectorTableOpen = () => {
        dispatch(setIsSectorTableOpen(!isSectorTableOpen));
    };

    const setOtherLighthousesVisibility = (value: boolean) => {
        const mapview = getMapView();
        if (!mapview) return;

        const layerSectors = mapview.map.findLayerById("Lyssektor") as GraphicsLayer;
        const layerSectorLines = mapview.map.findLayerById("Sektorlinje") as GraphicsLayer;
        const layerLight = mapview.map.findLayerById("Lys") as GraphicsLayer;

        layerSectors.visible = !value;
        layerSectorLines.visible = !value;
        layerLight.visible = !value;

        setHideOthers(value);
    };

    let displayIndex = -1;
    if (!selectedFeature) return null;

    return (
        <div className="feature-viewer-content">
            <div className="sector-editor-header">
                <FeatureViewerTitle
                    symbolHtmlElement={symbolHtmlElement}
                    selectedFeature={appContext?.selectedFeature.value!}
                    layerConfig={layerConfig}
                />
                <NfsButton onClick={toggleIsSectorTableOpen} round inverted small active={isSectorTableOpen}>
                    <DataTableIcon />
                </NfsButton>
            </div>
            <SectorRose />
            <div className="flex-row small-gap align-center space-between">
                <span>Skjul alle andre lykter</span>
                <Toggle checked={hideOthers} onChange={setOtherLighthousesVisibility} />
            </div>
            <div className="flex-row small-gap align-center space-between">
                <span>Utvid sektorfarger i kart</span>
                <Toggle
                    checked={lighthouseContext!.showSectorColors.value}
                    onChange={lighthouseContext!.showSectorColors.set}
                />
            </div>
            <RangeSlider
                value={lighthouseContext!.sectorOpacity.value}
                onChange={lighthouseContext!.sectorOpacity.set}
            />
            {isShowingIALAWarning ? <ErrorMessage message="Sektorinndelingen er ikke i henhold til IALA." /> : null}
            {selectedFeature?.attributes.lighthouseData?.sectors.map((sector: Sector, index: number) => {
                if (sector.color !== SectorColor.DARK) displayIndex++;
                return (
                    <SectorMenu
                        key={`${selectedFeature?.attributes.lighthouseData.id}_${index}`}
                        sectorIndex={index}
                        displayIndex={displayIndex}
                    />
                );
            })}
        </div>
    );
};

export default SectorEditorContent;

import Point from "@arcgis/core/geometry/Point";
import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer.js";
import UniqueValueRenderer from "@arcgis/core/renderers/UniqueValueRenderer.js";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol.js";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol.js";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol.js";
import EditorVM from "@arcgis/core/widgets/Editor/EditorViewModel.js";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import AddIcon from "../../../assets/add.svg?react";
import AllIcon from "../../../assets/all.svg?react";
import LastUsedIcon from "../../../assets/last-used.svg?react";
import { AppContext, UserContext } from "../../../Context";
import { setCreateObjectOpen, setFeatureViewerIsEditing } from "../../../store/appSlice";
import { evaluateArcadeExpression, findAndSelectFeature, getMapView, setFeatureAttribute } from "../../../utils/arcgisUtils";
import FloatingWindow from "../../genericComponents/FloatingWindow/FloatingWindow";
import TabItem from "../../genericComponents/Tabs/TabItem";
import TabItems from "../../genericComponents/Tabs/TabItems";
import TabPanel from "../../genericComponents/Tabs/TabPanel";
import TabPanels from "../../genericComponents/Tabs/TabPanels";
import Tabs from "../../genericComponents/Tabs/Tabs";
import AllObjectTypesPanel from "./AllObjectTypesPanel";
import "./CreateObjectControl.css";
import LatestObjectTypesPanel from "./LatestObjectTypesPanel";

const CreateObjectControl = () => {
    const dispatch = useDispatch();
    const createToolRef = useRef(null);
    const [activeLayerId, setActiveLayerId] = useState<string | null>(null);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [newFeature, setNewFeature] = useState<Graphic | null>(null);
    const [renderSymbol, setRenderSymbol] = useState<__esri.Symbol | undefined>(undefined);

    const createObjectVMRef = useRef<EditorVM | null>(null);
    const appContext = useContext(AppContext);
    const userContext = useContext(UserContext);
    const userName = userContext?.user.value?.fullName;

    const closeTool = () => {
        const mapview = getMapView();
        if (!mapview) return;

        dispatch(setCreateObjectOpen(false));
    };

    useEffect(() => {
        const mapview = getMapView();
        if (!mapview || !createObjectVMRef.current || !activeLayerId) return;

        const layer = mapview.map.findLayerById(activeLayerId);
        if (!layer) return;

        const geometryType = (layer as FeatureLayer).geometryType;
        if (geometryType === "multipatch") return;

        const renderer = (layer as FeatureLayer).renderer;
        if (renderer.type === "simple") {
            const symbol = (renderer as SimpleRenderer).symbol;
            setRenderSymbol(symbol);
        } else if (renderer.type === "unique-value") {
            const uniqueValueRenderer = renderer as UniqueValueRenderer;
            if (uniqueValueRenderer.field === null) {
                evaluateArcadeExpression(uniqueValueRenderer.valueExpression, newFeature).then((value) => {
                    const valueInfo = uniqueValueRenderer.uniqueValueInfos.find((info) => info.value === value);
                    const valueInfoSymbol = valueInfo?.symbol;
                    setRenderSymbol(valueInfoSymbol);
                });
            } else {
                const value = newFeature?.attributes[uniqueValueRenderer.field];
                const valueInfo = uniqueValueRenderer.uniqueValueInfos.find((info) => info.value === value.toString());
                const valueInfoSymbol = valueInfo?.symbol;
                setRenderSymbol(valueInfoSymbol);
            }
        } else {
            return;
        }
    }, [newFeature]);

    useEffect(() => {
        const mapview = getMapView();
        if (!mapview || !createObjectVMRef.current || !activeLayerId) return;

        const layer = mapview.map.findLayerById(activeLayerId) as FeatureLayer;
        if (!layer) return;

        const geometryType = layer.geometryType;

        if (geometryType === "point") {
            createObjectVMRef.current.sketchViewModel.pointSymbol = renderSymbol as SimpleMarkerSymbol;
        } else if (geometryType === "polyline") {
            createObjectVMRef.current.sketchViewModel.polylineSymbol = renderSymbol as SimpleLineSymbol;
        } else if (geometryType === "polygon") {
            createObjectVMRef.current.sketchViewModel.polygonSymbol = renderSymbol as SimpleFillSymbol;
        } else {
            return;
        }

        createObjectVMRef.current.sketchViewModel.create(geometryType);

        createObjectVMRef.current?.on("sketch-create", (event) => {
            if (event.detail.state === "complete") {
                const graphic = event.detail.graphic;

                if (newFeature !== null) {
                    graphic.attributes = newFeature.attributes;
                    graphic.layer = newFeature.layer;
                    setFeatureAttribute(graphic, "opprettet_av", userName);
                    setFeatureAttribute(graphic, "modifisert_av", userName);
                    setFeatureAttribute(graphic, "opprettet_dato", new Date().toISOString());
                    setFeatureAttribute(graphic, "modifisert_dato", new Date().toISOString());
                    setFeatureAttribute(graphic, "latitude", (graphic.geometry as Point)?.latitude?.toFixed(8));
                    setFeatureAttribute(graphic, "longitude", (graphic.geometry as Point)?.longitude?.toFixed(8));
                }

                dispatch(setCreateObjectOpen(false));
                appContext?.selectedFeature.set(graphic);
                dispatch(setFeatureViewerIsEditing(true));

                layer
                    .applyEdits({
                        addFeatures: [graphic],
                    })
                    .then(async (editsResult) => {
                        setFeatureAttribute(graphic, "objectid", editsResult.addFeatureResults[0].objectId);
                        setFeatureAttribute(graphic, "globalid", editsResult.addFeatureResults[0].globalId);
                        appContext?.selectedFeature.set(graphic.clone());
                        (createObjectVMRef.current?.sketchViewModel.layer as GraphicsLayer).removeAll();


                        await layer.applyEdits({
                            updateFeatures: [graphic],
                        });
                        findAndSelectFeature(layer, graphic, (graphic) => {
                            appContext?.selectedFeature.set(graphic);
                        });
                    });
            }
        });
    }, [renderSymbol]);

    useEffect(() => {
        const mapview = getMapView();
        if (!mapview) return;

        const editorVM = new EditorVM({
            view: mapview
        });
        createObjectVMRef.current = editorVM;

        return () => {
            if (createObjectVMRef.current) {
                createObjectVMRef.current.destroy();
                createObjectVMRef.current = null;
            }
        }
    }, []);

    return editMode ? null : (
        <FloatingWindow
            className={"floating-window-create-object"}
            closeWindow={closeTool}
            toolRef={createToolRef}
            title="Opprett objekt"
            Icon={AddIcon}
        >
            <Tabs>
                <TabItems>
                    <TabItem label="Alle" Icon={AllIcon} />
                    <TabItem label="Sist brukt" Icon={LastUsedIcon} />
                </TabItems>
                <TabPanels>
                    <TabPanel>
                        <AllObjectTypesPanel
                            setActiveLayerId={setActiveLayerId}
                            setEditMode={setEditMode}
                            setNewFeature={setNewFeature}
                        />
                    </TabPanel>
                    <TabPanel>
                        <LatestObjectTypesPanel
                            setActiveLayerId={setActiveLayerId}
                            setEditMode={setEditMode}
                            setNewFeature={setNewFeature}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </FloatingWindow>
    );
};

export default CreateObjectControl;

import RadioButton from "./RadioButton";
import "./RadioButtonGroup.css";

interface RadioButtonGroupProps {
    options: string[];
    selectedIndex: number;
    onChange: (selectedIndex: number) => void;
}

const RadioButtonGroup = (props: RadioButtonGroupProps) => {
    const { options, selectedIndex, onChange } = props;

    return (
        <div className="radio-button-group">
            {options.map((option, index) => (
                <RadioButton
                    key={option}
                    label={option}
                    checked={selectedIndex === index}
                    onChange={() => onChange(index)}
                />
            ))}
        </div>
    );
};

export default RadioButtonGroup;

import { UserAccount } from "../UserAccount/UserAccount";
import "./Header.css";
import DarkModeToggle from "./DarkModeToggle";
import Search from "./Search";
import logo from "../../assets/tempLogo.png";

import ClipboardIcon from "../../assets/versionControl/clipboard.svg?react";
import { getVersionName } from "../../utils/versioningUtils";
import { useDispatch, useSelector } from "react-redux";
import { setVersionControlOpen } from "../../store/appSlice";
import { StoreState } from "../../store/rootReducer";
import NfsButton from "../genericComponents/NfsButton/NfsButton";
import { AppConfig } from "../../AppConfig";
import { getAGEToken } from "../../utils/authenticate";
// import Alerts from "../Alerts/Alerts";

interface HeaderProps {
    setSearchResults: (results: __esri.SearchViewModelSearchResponseResults[]) => void;
}

const Header = (props: HeaderProps) => {
    const dispatch = useDispatch();

    const versionInfo = useSelector((state: StoreState) => state.version.versionInfo);

    const openVersionManagement = () => {
        dispatch(setVersionControlOpen(true));
    };

    const showVersionName = (fullLength: boolean) => {
        let versionName = "";
        if (versionInfo) {
            versionName = getVersionName(versionInfo);
            if (versionName.length > 20 && !fullLength) {
                versionName = versionName.substring(0, 30) + "...";
            }
        }
        return versionName;
    };

    const downloadBoatClient = async () => {
        const url = AppConfig.NFSAPI.BaseApiUrl + "boatclient/files/install-NFS.zip?token=" + (await getAGEToken());
        window.open(url);
    };

    return (
        <div className="header-container">
            <div className="header-logo">
                <img src={logo} alt="Nautisk Fagsystem" />
                <span className="header-title">NAUTISK FAGSYSTEM</span>
            </div>
            <div className="header-menu">
                {/* Version: {AppConfig.Version} - {AppConfig.Environment} */}
                {/* <button onClick={toggleVersionManagement}>Toggle version management</button> */}
                <Search setSearchResults={props.setSearchResults} />
                <button title={showVersionName(true)} className="header-version-button" onClick={openVersionManagement}>
                    <ClipboardIcon />
                    <span>{showVersionName(false)}</span>
                </button>
            </div>
            <div className="header-actions">
                <NfsButton outlined={true} onClick={downloadBoatClient}>
                    Båtklient
                </NfsButton>
                <DarkModeToggle />
                {/* <Alerts /> */}
                <UserAccount />
            </div>
        </div>
    );
};

export default Header;

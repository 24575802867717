import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import * as symbolUtils from "@arcgis/core/symbols/support/symbolUtils.js";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AppConfig, CreateObjectDefaultValuesConfig } from "../../../AppConfig";
import LightIcon from "../../../assets/light.svg?react";
import LighthouseIcon from "../../../assets/lighthouse.svg?react";
import { AppContext, LighthouseContext } from "../../../Context";
import { setCreateObjectOpen, setFeatureViewerIsEditing, setLoading } from "../../../store/appSlice";
import { setCurrentLighthouseTrueNorth, setIsSavingLighthouse } from "../../../store/lighthouseSlice";
import { computeTrueNorth, getMapView, moveToLayer } from "../../../utils/arcgisUtils";
import { deepCopy, uuidv4 } from "../../../utils/helpers";
import {
    fillLighthouseAttributes,
    LighthouseData,
    loadLighthouses,
    saveSectorData,
} from "../../../utils/lighthouseUtils";
import NfsButton from "../../genericComponents/NfsButton/NfsButton";
import "./ObjectTypeButton.css";

interface ObjectTypeButtonProps {
    title: string;
    layerName: string;
    presetAttributes: CreateObjectDefaultValuesConfig[];
    setActiveLayerId: (layerId: string) => void;
    setEditMode: (editMode: boolean) => void;
    setNewFeature: (feature: Graphic) => void;
}

const ObjectTypeButton = (props: ObjectTypeButtonProps) => {
    const { title, layerName, presetAttributes, setActiveLayerId, setEditMode, setNewFeature } = props;
    const dispatch = useDispatch();

    const [symbolHtmlElement, setSymbolHtmlElement] = useState<HTMLElement | null>(null);
    const dummyFeature = useRef<Graphic | null>(null);

    const appContext = useContext(AppContext);
    const lighthouseContext = useContext(LighthouseContext);

    const nonSourceIcon = title === "Fyrlykt" ? <LighthouseIcon /> : <LightIcon />;

    useEffect(() => {
        const mapview = getMapView();
        if (!mapview) return;

        const layer = mapview.map.findLayerById(layerName) as FeatureLayer | GraphicsLayer;
        // Adding extra security in case of db changes or other errors
        if (!layer) return;

        dummyFeature.current = new Graphic({
            attributes:
                layer.type === "feature"
                    ? layer?.fields?.map((field) => field.name).reduce((acc, field) => ({ ...acc, [field]: null }), {})
                    : {},
            layer: layer,
        });

        presetAttributes?.forEach((presetAttribute) => {
            dummyFeature.current!.attributes[presetAttribute.FieldName] = presetAttribute.Value;
        });

        if (title !== "Fyrlykt") {
            symbolUtils.getDisplayedSymbol(dummyFeature.current).then((symbol) => {
                if (symbol) {
                    symbolUtils.renderPreviewHTML(symbol, { size: 12 }).then((symbolElement) => {
                        setSymbolHtmlElement(symbolElement);
                    });
                }
            });
        }
    }, []);

    const onClick = async () => {
        const mapview = getMapView();
        if (!mapview) return;

        setActiveLayerId(layerName);
        setEditMode(true);

        if (layerName === "Fyrlykt") {
            if (dummyFeature.current) {
                // Move everything already in the LighthouseEditing layer to the Lighthouse layer
                await moveToLayer("all", "FyrlyktEditing", "Fyrlykt");

                const newFeature = dummyFeature.current.clone();

                fillLighthouseAttributes(newFeature);

                const lighthouseId = uuidv4();

                const newLighthouseData = {
                    id: lighthouseId,
                    center: mapview.center,
                    sectors: deepCopy(AppConfig.Lighthouse.DefaultSectors),
                    setState: lighthouseContext?.lighthouseDataHook.set,
                } as LighthouseData;

                newLighthouseData.sectors.forEach((sector) => {
                    sector.lighthouseId = lighthouseId;
                    sector.globalid = uuidv4();
                });

                newFeature.attributes.lighthouseData = newLighthouseData;
                newFeature.attributes.klasse = 3;
                newFeature.attributes.lys_type = 99;
                appContext?.selectedFeature.set(newFeature);
                lighthouseContext?.lighthouseData.set(newLighthouseData);
                dummyFeature.current = newFeature;

                const trueNorth = await computeTrueNorth(newLighthouseData.center);
                dispatch(setCurrentLighthouseTrueNorth(trueNorth));

                saveSectorData(
                    newLighthouseData,
                    newFeature,
                    trueNorth,
                    (isSaving) => dispatch(setIsSavingLighthouse(isSaving)),
                    (loadingStatus) => dispatch(setLoading(loadingStatus))
                ).then((objectid) => {
                    if (objectid === undefined) return;

                    const lightLayer = mapview.map.findLayerById("Lys") as FeatureLayer;
                    loadLighthouses(
                        objectid,
                        lightLayer,
                        appContext!.selectedFeature.set,
                        lighthouseContext!.lighthouseData.set,
                        (loadingStatus) => dispatch(setLoading(loadingStatus))
                    );
                });
            }
            dispatch(setCreateObjectOpen(false));
            dispatch(setFeatureViewerIsEditing(true));
        }

        if (!dummyFeature.current) return;

        setNewFeature(dummyFeature.current);

        let latestObjectTypes = JSON.parse(localStorage.getItem("latestObjectTypes") ?? "[]") as string[];
        if (!latestObjectTypes) return;

        if (latestObjectTypes.includes(title)) {
            latestObjectTypes.splice(latestObjectTypes.indexOf(title), 1);
        }

        latestObjectTypes = [title, ...latestObjectTypes];
        localStorage.setItem("latestObjectTypes", JSON.stringify(latestObjectTypes));
    };

    return (
        <NfsButton className="create-object-type-button empty" key={title} onClick={onClick}>
            {symbolHtmlElement ? (
                <div dangerouslySetInnerHTML={{ __html: symbolHtmlElement?.outerHTML ?? "" }}></div>
            ) : (
                nonSourceIcon
            )}
            {title}
        </NfsButton>
    );
};

export default ObjectTypeButton;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppConfig } from "../../AppConfig";
import AddIcon from "../../assets/add.svg?react";
import { StoreState } from "../../store/rootReducer";
import { setUserDefinedScales } from "../../store/scaleSlice";
import { formatScaleString, parseScaleString } from "../../utils/helpers";
import NfsButton from "../genericComponents/NfsButton/NfsButton";
import NfsDropdown from "../genericComponents/NfsDropdown/NfsDropdown";
import AddScale from "./AddScale";
import RemoveScale from "./RemoveScale";
import "./Scale.css";

const Scale = () => {
    const defaultScales = AppConfig.Scale.Scales;
    const userDefinedScales = useSelector((state: StoreState) => state.scale.userDefinedScales);
    const [selectedOption, setSelectedOption] = useState<number | null>(AppConfig.Scale.DefaultScale);
    const viewLoaded = useSelector((state: StoreState) => state.app.viewLoaded);
    const [addScaleVisible, setAddScaleVisible] = useState<boolean>(false);
    const mapScale = useSelector((state: StoreState) => state.app.mapScale);
    const dispatch = useDispatch();

    const allScales = defaultScales.concat(userDefinedScales).sort((a, b) => a - b);

    const setScale = (option: string | null) => {
        const mapView = document.querySelector("arcgis-map")?.view;
        if (!mapView) return;

        mapView?.goTo({
            scale: parseScaleString(option ?? ""),
        });
        setSelectedOption(parseScaleString(option ?? ""));
    };

    const toggleAddScaleVisible = () => {
        setAddScaleVisible(!addScaleVisible);
    };

    useEffect(() => {
        const scalesFromLocalStorage = JSON.parse(localStorage.getItem("userDefinedScales") ?? "[]");
        dispatch(setUserDefinedScales(scalesFromLocalStorage.map((scale: string) => parseInt(scale))));
    }, []);

    useEffect(() => {
        const mapView = document.querySelector("arcgis-map")?.view;

        if (!mapView) return;
    }, [viewLoaded, userDefinedScales]);

    useEffect(() => {
        const intScale = Math.floor(mapScale);
        if (!allScales.includes(intScale)) {
            setSelectedOption(null);
        } else {
            setSelectedOption(intScale);
        }
    }, [mapScale, viewLoaded, userDefinedScales]);

    return (
        <div className="scale flex-row">
            <NfsDropdown
                options={allScales.map((scale) => `1:${scale}`)}
                selectedOption={formatScaleString(selectedOption ?? mapScale)}
                setSelectedOption={setScale}
                placeholder={"Velg målestokk"}
            ></NfsDropdown>
            <NfsButton
                onClick={() => {
                    toggleAddScaleVisible();
                }}
            >
                <AddIcon />
            </NfsButton>

            {selectedOption !== null && userDefinedScales.includes(selectedOption) ? (
                <RemoveScale selectedOption={selectedOption} setScale={setScale} allScales={allScales} />
            ) : null}

            {addScaleVisible ? <AddScale toggleAddScaleVisible={toggleAddScaleVisible} setScale={setScale} /> : null}
        </div>
    );
};

export default Scale;

import { createSlice } from "@reduxjs/toolkit";

interface VersionState {
    activeSessionID: string;
    activeGuid: string;
    readActive: boolean;
    editActive: boolean;
    versionInfo: __esri.VersionInfoJSON | null;
    versionsSharedWithMe: string[] | null;
}

const initialState: VersionState = {
    activeSessionID: "",
    activeGuid: "",
    readActive: false,
    editActive: false,
    versionInfo: null,
    versionsSharedWithMe: [],
};

const versionSlice = createSlice({
    name: "version",
    initialState,
    reducers: {
        setActiveSessionID(state, action) {
            state.activeSessionID = action.payload;
        },
        setActiveGuid(state, action) {
            state.activeGuid = action.payload;
        },
        setReadActive(state, action) {
            state.readActive = action.payload;
        },
        setEditActive(state, action) {
            state.editActive = action.payload;
        },
        setVersionInfo(state, action) {
            state.versionInfo = action.payload;
        },
        setVersionsSharedWithMe(state, action) {
            state.versionsSharedWithMe = action.payload;
        }
    },
});

export default versionSlice.reducer;
export const {
    setActiveSessionID,
    setActiveGuid,
    setReadActive,
    setEditActive,
    setVersionInfo,
    setVersionsSharedWithMe,
} = versionSlice.actions;
export type { VersionState };

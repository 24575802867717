import { createSlice } from "@reduxjs/toolkit";
import { AppConfig } from "../AppConfig";

interface AppState {
    darkmode: boolean;
    measureToolOpen: boolean;
    layerListOpen: boolean;
    createObjectOpen: boolean;
    viewLoaded: boolean;
    featureViewerOpen: boolean;
    featureViewerIsEditing: boolean;
    versionControlOpen: boolean;
    lightCalculatorOpen: { open: boolean; loadFeatureValues: boolean };
    mapScale: number;
    loading: boolean;
}

const initialState: AppState = {
    darkmode: false,
    measureToolOpen: false,
    layerListOpen: false,
    createObjectOpen: false,
    viewLoaded: false,
    featureViewerOpen: false,
    featureViewerIsEditing: false,
    versionControlOpen: false,
    lightCalculatorOpen: { open: false, loadFeatureValues: false },
    mapScale: AppConfig.Scale.DefaultScale,
    loading: false,
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        toggleDarkmode(state) {
            state.darkmode = !state.darkmode;
        },
        setMeasureToolOpen(state, action) {
            state.measureToolOpen = action.payload;
        },
        setLayerListOpen(state, action) {
            state.layerListOpen = action.payload;
        },
        setCreateObjectOpen(state, action) {
            state.createObjectOpen = action.payload;
        },
        setViewLoaded(state) {
            state.viewLoaded = true;
        },
        setFeatureViewerOpen(state, action) {
            state.featureViewerOpen = action.payload;
        },
        setFeatureViewerIsEditing(state, action) {
            state.featureViewerIsEditing = action.payload;
        },
        setVersionControlOpen(state, action) {
            state.versionControlOpen = action.payload;
        },
        setLightCalculatorOpen(state, action) {
            state.lightCalculatorOpen = action.payload;
        },
        setMapScale(state, action) {
            state.mapScale = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
    },
});

export default appSlice.reducer;
export const {
    toggleDarkmode,
    setViewLoaded,
    setMeasureToolOpen,
    setLayerListOpen,
    setCreateObjectOpen,
    setFeatureViewerOpen,
    setFeatureViewerIsEditing,
    setVersionControlOpen,
    setLightCalculatorOpen,
    setMapScale,
    setLoading,
} = appSlice.actions;
export type { AppState };


import { useEffect, useRef, useState } from "react";
import "./SearchResults.css";
import NfsDropdown from "../genericComponents/NfsDropdown/NfsDropdown";
import LeftArrowIcon from "../../assets/arrow-left.svg?react";
import SearchItem from "./SearchItem";
import { GetCodedValueAndAlias, IsFeatureInMapExtent } from "../../utils/arcgisUtils";

interface SearchResultsProps {
    searchResults: __esri.SearchViewModelSearchResponseResults[];
}

const SearchResults = (props: SearchResultsProps) => {

    const [searchSources, setSearchSources] = useState<string[]>([]);
    const [selectedSource, setSelectedSource] = useState<string | null>(null);
    const [panelClass, setPanelClass] = useState<string>("collapsed");

    const [allStatuses, setAllStatuses] = useState<{ value: string | number, codedValue: string | number }[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
    const [filterByExtent, setFilterByExtent] = useState<boolean>(false);

    const highlightedFeaturesRef = useRef<__esri.Handle[]>([]);


    // Due to css behavior with animations to display none and vice versa
    // an additional class is needed to allow panel to slide out
    const toggleOpen = () => {
        if (panelClass === "") {
            setPanelClass("collapsed");
        } else if (panelClass === "collapsed") {
            setPanelClass("opening");
            setTimeout(() => {
                setPanelClass("");
            }, 100);
        }
    }

    const clearHighlightedFeatures = () => {
        highlightedFeaturesRef.current.forEach(handle => {
            handle.remove();
        });
        highlightedFeaturesRef.current = [];
    }

    const storeHighlightHandle = (handle: __esri.Handle | null) => {
        clearHighlightedFeatures();
        if (handle) {
            highlightedFeaturesRef.current.push(handle);
        }
    }

    useEffect(() => {
        if (props.searchResults.length === 0) {
            setPanelClass("collapsed");
            return;
        } else {
            setPanelClass("");
        }

        const sources = props.searchResults
            .filter(result => result.results.length > 0)
            .map(result => result.source.layer.title + " (" + result.results.length + ")");
        setSearchSources(sources);
        for (const element of props.searchResults) {
            if (element.results.length > 0) {
                setSelectedSource(element.source.layer.title + " (" + element.results.length + ")");
                break;
            }
        }

    }, [props.searchResults]);
    useEffect(() => {
        const statusMap = new Map<string | number, { value: string | number, codedValue: string | number }>();
        props.searchResults.forEach(result => {
            result.results.forEach(result => {
                const statusValue = result.feature.attributes["nfs_status"] ?? "ingen status";
                const codedValue = GetCodedValueAndAlias(result.feature.layer, "nfs_status", statusValue)[1];
                statusMap.set(statusValue, { value: statusValue, codedValue });
            });
        });
        setAllStatuses(Array.from(statusMap.values()));
    }, [selectedSource]);

    if (props.searchResults.length === 0) {
        return null;
    }

    return (
        <div className="search-results-container">
            <div className={`search-results ${panelClass}`}>
                <div className="search-filter">
                    <span className="search-results-source">Søkeresultat:</span>
                    <div className="search-results-actions">
                        <label style={{ flex: "auto" }} className="flex-col">
                            Type:
                            <NfsDropdown
                                options={searchSources}
                                selectedOption={selectedSource}
                                setSelectedOption={setSelectedSource}
                                placeholder="Alle"
                                allowEmpty
                            />
                        </label>
                        <label style={{ flex: "auto" }} className="flex-col">
                            Status:
                            <NfsDropdown
                                options={allStatuses.map(status => status.codedValue as string)}
                                selectedOption={selectedStatus}
                                setSelectedOption={setSelectedStatus}
                                placeholder="Alle"
                                allowEmpty
                            />
                        </label>
                    </div>
                    <label className="search-filter-label">
                        <input type="checkbox" checked={filterByExtent} onChange={() => setFilterByExtent(!filterByExtent)} />{' '}
                        Innenfor kartutsnitt
                    </label>
                </div>
                <div className="search-content">
                    {
                        props.searchResults.map((results) => {
                            if (!selectedSource?.includes(results.source.layer.title) && selectedSource !== null) {
                                return null;
                            }
                            return results.results.map((result: any) => {
                                if (GetCodedValueAndAlias(results.source.layer, "nfs_status", result.feature.attributes["nfs_status"])[1] !== selectedStatus && selectedStatus !== null) {
                                    return null;
                                }

                                if (filterByExtent) {
                                    if (!IsFeatureInMapExtent(result.feature)) {
                                        return null;
                                    }
                                }

                                return <SearchItem key={result.name + result.key + result.sourceIndex} result={result} storeHighlightHandle={storeHighlightHandle} />
                            })

                        })
                    }
                    <span className="search-content-empty">
                        Ingen resultater funnet
                    </span>
                </div>
            </div>
            <button className={`search-results-collapse-button ${panelClass}`} onClick={toggleOpen}>
                <LeftArrowIcon />
            </button>
        </div>
    )
}

export default SearchResults;
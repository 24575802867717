import Portal from "@arcgis/core/portal/Portal.js";
import OAuthInfo from "@arcgis/core/identity/OAuthInfo.js";
import esriId from "@arcgis/core/identity/IdentityManager.js";
import { AppConfig } from "../AppConfig";
import esriConfig from "@arcgis/core/config.js";
import esriRequest from "@arcgis/core/request.js";

const portalUrl = AppConfig.ArcGIS.BaseUrl;

const arcgisPortal = new Portal({
    url: portalUrl,
});

const info = new OAuthInfo({
    portalUrl: portalUrl,
    appId: AppConfig.ArcGIS.AppClientId,
    popup: false,
});

const getPortalUser = async () => {
    await arcgisPortal.load();
    return arcgisPortal.user;
};

export const signOut = async () => {
    esriId.destroyCredentials();
    window.location.reload();
};

export const getUser = async (selfSetter?: (self: any) => void) => {
    esriId.registerOAuthInfos([info]);
    esriId.on("credential-create", () => {
        getSelf().then((res: __esri.RequestResponse) => {
            if (selfSetter && res.data) {
                selfSetter(res.data);
            }
        });
    });
    await signIn();
    return await getPortalUser();
};

const checkCurrentStatus = async () => {
    try {
        const credential = await esriId.checkSignInStatus(`${info.portalUrl}/sharing`);
        return credential;
    } catch (error) {
        console.warn(error);
    }
};

const signIn = async () => {
    try {
        const credential = (await checkCurrentStatus()) || (await fetchCredentials());
        return credential;
    } catch (error) {
        const credential = await fetchCredentials();
        return credential;
    }
};

const fetchCredentials = async () => {
    try {
        const credential = await esriId.getCredential(`${info.portalUrl}/sharing`);
        return credential;
    } catch (error) {
        console.warn(error);
    }
};

export const getAGEToken = async (): Promise<string> => {
    try {
        const credential = await fetchCredentials();
        if (credential) {
            esriConfig.apiKey = credential.token;
            return credential.token;
        }
    } catch (error) {
        console.warn(error);
    }
    return "";
};

export const getSelf = async () => {
    return esriRequest(`${info.portalUrl}/sharing/rest/community/self`, {
        method: 'auto',
        responseType: 'json',
        query: {
            f: 'json',
        }
    });
}
import { useEffect, useState } from "react";
import InputField from "../../genericComponents/InputField/InputField";
import "./SectorTable.css";

interface SectorTableInputFieldProps {
    index: number;
    initialValue: string;
    saveDescription: (index: number, description: string) => void;
    onFocus: () => void;
    onBlur: () => void;
}

const SectorTableInputField = (props: SectorTableInputFieldProps) => {
    const { index, initialValue, saveDescription, onFocus, onBlur } = props;

    const [value, setValue] = useState("");

    useEffect(() => {
        setValue(initialValue ?? "");
    }, []);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <InputField
            inputFieldId={`sector-description-${index}`}
            label=""
            type="text"
            value={value}
            onChange={(newValue) => {
                setValue(newValue);
            }}
            onFocus={() => {
                onFocus();
            }}
            onBlur={() => {
                onBlur();
                saveDescription(index, value);
            }}
            maxLength={200}
        />
    );
};

export default SectorTableInputField;

import * as arcade from "@arcgis/core/arcade.js";
import Geometry from "@arcgis/core/geometry/Geometry.js";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import * as projection from "@arcgis/core/geometry/projection.js";
import SpatialReference from "@arcgis/core/geometry/SpatialReference.js";
import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import MapView from "@arcgis/core/views/MapView";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils.js";
import { AppConfig } from "../AppConfig";

export const GetCodedValueAndAlias = (layer: any, fieldName: string, fieldValue: number | string) => {
    let returnValue = [fieldName, fieldValue];
    if (!layer?.fields) return returnValue;
    layer.fields.forEach((fld: any) => {
        if (fld.name === fieldName) {
            returnValue[0] = fld?.alias;
            const cDomain = fld?.domain;
            if (cDomain)
                cDomain.codedValues.forEach((cVal: any) => {
                    if (cVal.code === fieldValue) returnValue[1] = cVal.name;
                });
        }
    });
    return returnValue;
};

export const IsFeatureInMapExtent = (feature: __esri.Graphic) => {
    const map = document.getElementsByTagName("arcgis-map")[0];
    if (!map) return false;

    const inExtent = geometryEngine.contains(map.extent, feature.geometry);

    return inExtent;
};

export const getMapView = (id?: string) => {
    const maps = Array.from(document.getElementsByTagName("arcgis-map"));
    const map = maps.filter((map) => map.id === (id ?? "map"))[0];
    if (!map) {
        return;
    }
    const mapview = map.view;
    if (!mapview) {
        return;
    }
    return mapview;
};

export const highlightFeature = async (
    feature: __esri.Graphic,
    layer?: __esri.Layer,
    mapViewId?: string
): Promise<IHandle | void> => {
    const mapview = getMapView(mapViewId);
    if (!mapview) return;

    layer = layer ?? feature?.layer;
    if (layer instanceof FeatureLayer || layer instanceof GraphicsLayer) {
        return await mapview.whenLayerView(layer).then((layerView) => {
            return (layerView as __esri.FeatureLayerView | __esri.GraphicsLayerView).highlight(feature);
        });
    }
};

export const unHighlightGraphic = async (feature: __esri.Graphic, layer: __esri.GraphicsLayer) => {
    const mapview = getMapView();
    if (!mapview) return;

    const featureCopy = feature.clone();
    layer.remove(feature);
    layer.add(featureCopy);
};

export const setFeatureAttribute = (feature: Graphic, attributeName: string, attributeValue: any) => {
    const keys = Object.keys(feature.attributes);
    if (keys.includes(attributeName)) {
        feature.attributes[attributeName] = attributeValue;
    }
};

export const updateFeatureAttributes = async (updatedFeature: __esri.Graphic): Promise<boolean> => {
    const success = false;
    const result = await (updatedFeature.layer as FeatureLayer).applyEdits({ updateFeatures: [updatedFeature] });

    if (result.updateFeatureResults[0].error) {
        console.error("Error updating feature: ", result.updateFeatureResults[0].error);
    } else {
        console.log("Feature updated successfully");
        return true;
    }

    return success;
};

/* export const getPlaceNameFromPoint = (point: Geometry) => {
    getAGEToken().then((token) => {
        console.log(token);
        const geocodingServiceUrl = "https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer";
        const params = {
            location: point as Point,
        };

        locator.locationToAddress(geocodingServiceUrl, params).then((response) => {
            console.log(response);
        });
    });
}; */

export const evaluateArcadeExpression = async (expression: string, feature: Graphic | null) => {
    if (!feature) return;

    const profile = {
        variables: [
            {
                name: "$feature",
                type: "feature",
            } as __esri.ProfileVariable,
        ],
    };

    const executor = await arcade.createArcadeExecutor(expression, profile);
    return executor.execute({ $feature: feature });
};

export const moveToLayer = async (featureId: string, fromLayerId: string, toLayerId: string) => {
    if (fromLayerId === toLayerId) return;

    const mapview = getMapView();
    if (!mapview) return;

    const fromLayer = mapview.map.findLayerById(fromLayerId) as GraphicsLayer;
    const toLayer = mapview.map.findLayerById(toLayerId) as GraphicsLayer;

    fromLayer.load().then(() => {
        toLayer.load().then(() => {
            let graphics = fromLayer.graphics;

            if (featureId !== "all") {
                graphics = fromLayer.graphics.filter((graphic) => graphic.attributes["id"] === featureId);
            }

            toLayer.addMany(graphics.toArray());
            fromLayer.removeMany(graphics.toArray());
        });
    });
};

export const getDomainValues = (layer: FeatureLayer, fieldName: string) => {
    const domain = layer.getFieldDomain(fieldName) as __esri.CodedValueDomain;
    if (!domain) return [];
    return domain.codedValues;
};

export const canUseGeodesic = (mapview: MapView) => {
    return mapview.spatialReference.isWGS84 || mapview.spatialReference.isWebMercator;
};

export const projectToWebMercator = async (geometry: Geometry) => {
    await projection.load();
    return projection.project(geometry, new SpatialReference({ wkid: 3857 }));
};

export const computeTrueNorth = async (point: __esri.Point | null): Promise<number> => {
    if (!point) return 0;

    const pointWebMercator = (await projectToWebMercator(point)) as __esri.Point;

    const lonLat = webMercatorUtils.xyToLngLat(pointWebMercator.x, pointWebMercator.y);

    const zeroMeridian = (AppConfig.Projection.CenterMeridian * Math.PI) / 180;
    const latitude = ((point.latitude ?? lonLat[1]) * Math.PI) / 180;
    const longitude = ((point.longitude ?? lonLat[0]) * Math.PI) / 180;

    const trueNorthAngle = Math.atan(Math.tan(zeroMeridian - longitude) * Math.sin(latitude));
    const trueNorthAngleDegrees = -(trueNorthAngle * 180) / Math.PI;
    return trueNorthAngleDegrees;
};


export const getLayerName = (layerId: number) => {
    const mapView = document.getElementsByTagName("arcgis-map")[0];
    let layerName = "";
    if (mapView) {
        let allLayers = mapView.map.allLayers;
        allLayers = allLayers.concat(mapView.map.allTables);
        for (const l of allLayers) {
            if (l.type === "feature") {
                if ((l as __esri.FeatureLayer).layerId === layerId) {
                    layerName = (l as __esri.FeatureLayer).title;
                }
            }
        };
    }
    return layerName;
};

export const findAndSelectFeature = (layer: FeatureLayer, graphic: Graphic, selectFeature: (graphic: Graphic) => void) => {
    // Dumb hack to get symbol change and highlight to work on new features
    layer
        .queryFeatures({
            objectIds: [graphic.attributes.objectid],
            outFields: ["*"],
            returnGeometry: true,
        })
        .then((result) => {
            selectFeature(result.features[0])
        });
};
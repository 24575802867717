import Point from "@arcgis/core/geometry/Point";
import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import MapView from "@arcgis/core/views/MapView";
import { AppConfig } from "../AppConfig";
import { drawSectors } from "../components/MapTools/LightHouse/lighthouseDrawing";
import { getMapView, setFeatureAttribute } from "./arcgisUtils";
import { metersPerNauticalMile } from "./constants";
import { clamp, clampWithCircleRollover, uuidv4 } from "./helpers";

export enum SectorColor {
    GREEN = "4",
    WHITE = "1",
    RED = "3",
    DARK = "0",
}

export enum SectorFallbackColor {
    GREEN = "#38a800FF",
    WHITE = "#ffd710FF",
    RED = "#ff0000FF",
    DARK = "#00000000",
}

export interface Sector {
    globalid: string;
    lighthouseId: string;
    color?: SectorColor;
    startAngle?: number;
    innerRadius?: number;
    startLineLength?: number;
    endLineLength?: number;
    index: number;
    description?: string;
}

export interface LighthouseData {
    id: string;
    center: Point;
    sectors: Sector[];
    setState: ((value: LighthouseData) => void) | undefined;
}

export class DefaultSectorValues {
    static readonly defaultInnerRadius = 600;
    static readonly defaultLineLength = 1000;
}

export const deleteSector = (lighthouseData: LighthouseData, sectorIndex: number) => {
    const newLighthouseData = { ...lighthouseData };
    newLighthouseData.sectors.splice(sectorIndex, 1);

    // Update sector.index for all sectors after the deleted sector
    newLighthouseData.sectors.forEach((sector, index) => {
        sector.index = index;
    });

    return newLighthouseData;
};

export const createSector = (lighthouseData: LighthouseData, sectorIndex: number) => {
    const newLighthouseData = { ...lighthouseData };
    const currentSector = newLighthouseData.sectors[sectorIndex];
    const nextSector =
        newLighthouseData.sectors[(sectorIndex + 1) % newLighthouseData.sectors.length] ?? newLighthouseData.sectors[0];

    const startAngle = currentSector.startAngle ?? 0;
    let endAngle = nextSector.startAngle ?? 360;

    if (endAngle < startAngle) {
        endAngle += 360;
    }

    newLighthouseData.sectors.splice(sectorIndex + 1, 0, {
        globalid: uuidv4(),
        lighthouseId: lighthouseData.id,
        color: SectorColor.WHITE,
        startAngle:
            lighthouseData.sectors.length === 1 ? (startAngle + 180) % 360 : (endAngle - startAngle) / 2 + startAngle,
        innerRadius: DefaultSectorValues.defaultInnerRadius,
        startLineLength: DefaultSectorValues.defaultLineLength,
        endLineLength: DefaultSectorValues.defaultLineLength,
        index: nextSector.index,
        description: "",
    });

    newLighthouseData.sectors.forEach((sector, index) => {
        sector.index = index;
    });

    return newLighthouseData;
};

export const sanitizeSectors = (data: LighthouseData, currentIndex: number) => {
    const prevIndex = currentIndex === 0 ? data.sectors.length - 1 : currentIndex - 1;
    const nextIndex = currentIndex === data.sectors.length - 1 ? 0 : currentIndex + 1;
    const prevSector = data.sectors[prevIndex];
    const currentSector = data.sectors[currentIndex];
    const nextSector = data.sectors[nextIndex];

    const prevRadius = prevSector?.innerRadius ?? DefaultSectorValues.defaultInnerRadius;
    const currentRadius = currentSector?.innerRadius ?? DefaultSectorValues.defaultInnerRadius;
    const nextRadius = nextSector?.innerRadius ?? DefaultSectorValues.defaultInnerRadius;

    const startLineLength = currentSector.startLineLength ?? DefaultSectorValues.defaultLineLength;
    const endLineLength = nextSector.startLineLength ?? DefaultSectorValues.defaultLineLength;

    // First limit the sector line lengths to absolute max and min values
    currentSector.startLineLength = clamp(
        startLineLength,
        AppConfig.Lighthouse.MinSectorLineLength,
        AppConfig.Lighthouse.MaxSectorLineLength
    );

    // Limit the sector radius to the smaller of the start and end line lengths
    if (currentRadius > Math.min(startLineLength, endLineLength)) {
        currentSector.innerRadius = Math.min(startLineLength, endLineLength);
    }

    // Limit the start line length to the greater of the previous or current radius
    if (currentSector.startLineLength < Math.max(prevRadius, currentSector.innerRadius!)) {
        currentSector.startLineLength = Math.max(prevRadius, currentSector.innerRadius!);
    }

    // Limit the end line length to the greater of the current or next radius
    if (nextSector.startLineLength! < Math.max(currentSector.innerRadius!, nextRadius)) {
        nextSector.startLineLength = Math.max(currentSector.innerRadius!, nextRadius);
    }

    let prevAngle = prevSector.startAngle ?? 0;
    let currentAngle = currentSector.startAngle ?? 0;
    let nextAngle = nextSector.startAngle ?? 360;

    // Constrain all angles to be between 0 and 360
    currentAngle = (currentAngle + 360) % 360;
    prevAngle = (prevAngle + 360) % 360;
    nextAngle = (nextAngle + 360) % 360;

    // Clamp the current sector angle to be between the previous and next sector angles.
    // Also handle special cases for crossing the 0/360 degree line.
    if (currentSector.index === 0 || currentSector.index === data.sectors.length - 1) {
        currentSector.startAngle = clampWithCircleRollover(currentAngle, prevAngle, nextAngle);
    } else if (currentSector.index === 1 && currentIndex === 0) {
        const prevPrevAngle = data.sectors[(prevIndex - 1) % data.sectors.length].startAngle ?? 0;
        prevSector.startAngle = clampWithCircleRollover(prevAngle, prevPrevAngle, currentAngle);
    } else if (currentIndex === data.sectors.length - 1 && currentSector.index !== data.sectors.length - 1) {
        const nextNextAngle = data.sectors[(nextIndex + 1) % data.sectors.length].startAngle ?? 0;
        nextSector.startAngle = clampWithCircleRollover(nextAngle, currentAngle, nextNextAngle);
    } else {
        currentSector.startAngle = clamp(currentAngle, prevAngle, nextAngle);
    }

    // Sort sectors by angle
    data.sectors.sort((a, b) => {
        return a.startAngle! - b.startAngle!;
    });

    return data;
};

export const satisfiesIALA = (lighthouse: LighthouseData) => {
    if (!lighthouse) return false;

    for (let i = 0; i < lighthouse.sectors.length; i++) {
        const sector = lighthouse.sectors[i];
        const nextSector = lighthouse.sectors[(i + 1) % lighthouse.sectors.length];

        // After a green sector, there must be a white sector
        if (sector.color === SectorColor.GREEN && nextSector.color !== SectorColor.WHITE) {
            return false;
        }

        // After a white sector, there must be a red sector
        if (sector.color === SectorColor.WHITE && nextSector.color !== SectorColor.RED) {
            return false;
        }

        // After a red sector, there must be a green sector or a dark sector
        if (
            sector.color === SectorColor.RED &&
            nextSector.color !== SectorColor.GREEN &&
            nextSector.color !== SectorColor.DARK
        ) {
            return false;
        }

        // After a dark sector, there must be a green sector
        if (sector.color === SectorColor.DARK && nextSector.color !== SectorColor.GREEN) {
            return false;
        }
    }
    return true;
};

export const fillLighthouseAttributes = (newFeature: Graphic) => {
    const mapview = getMapView();
    if (!mapview) return;

    const lysLayer = mapview.map.findLayerById("Lys") as FeatureLayer;
    const lysAttributes = lysLayer.fields
        .map((field) => field.name)
        .reduce((acc, field) => ({ ...acc, [field]: null }), {});
    newFeature.attributes = { ...lysAttributes, ...newFeature.attributes };

    const fyrlyktAttributesConfig = AppConfig.CreateObject.find(
        (config) => config.GroupName === "Lys"
    )?.GroupEntries.find((entry) => entry.Title === "Fyrlykt");

    if (fyrlyktAttributesConfig) {
        fyrlyktAttributesConfig.FieldValues.forEach((attribute) => {
            newFeature.attributes[attribute.FieldName] = attribute.Value;
        });
    }
    newFeature.layer = lysLayer;
};

export const getRelationshipId = (layer: FeatureLayer) => {
    const mapView = getMapView();
    if (!mapView) return;

    const sectorTable = mapView.map.findTableById("Lyssektor") as FeatureLayer;
    const layerTitleAndRelationshipName = {
        Lyssektor: "Lyssektor_F",
        Sektorlinje: "Lyssektor_L",
        Lys: "Lys",
    };

    return sectorTable.relationships.find(
        (relationship) =>
            relationship.name ===
            layerTitleAndRelationshipName[layer.title as keyof typeof layerTitleAndRelationshipName]
    )?.id;
};

export const loadLighthousesClickHandler = (
    view: MapView,
    setSelectedFeature: ((value: __esri.Graphic | null) => void) | undefined,
    setLighthouseData: ((value: LighthouseData) => void) | undefined,
    setLoading: ((value: boolean) => void) | undefined
) => {
    const sectorLayer = view.map.findLayerById("Lyssektor") as __esri.FeatureLayer;
    const lineLayer = view.map.findLayerById("Sektorlinje") as __esri.FeatureLayer;

    return view.on("click", async (event) => {
        if (!view) return;

        // Check if any part of the lighthouse is clicked
        const hitTestResponse = await view.hitTest(event, { include: [sectorLayer, lineLayer /* lightLayer */] });
        if (hitTestResponse.results.length === 0) return;

        const hitGraphic = (hitTestResponse.results[0] as __esri.GraphicHit).graphic;
        const objectid = hitGraphic.attributes.objectid;
        const layer = hitGraphic.layer as __esri.FeatureLayer;

        loadLighthouses(objectid, layer, setSelectedFeature, setLighthouseData, setLoading);
    });
};

export const loadLighthouses = async (
    objectid: number,
    layer: FeatureLayer,
    setSelectedFeature: ((value: __esri.Graphic | null) => void) | undefined,
    setLighthouseData: ((value: LighthouseData) => void) | undefined,
    setLoading: ((value: boolean) => void) | undefined
) => {
    const view = getMapView();
    if (!view) return;

    setLoading?.(true);

    const sectorTable = view.map.findTableById("Lyssektor") as __esri.FeatureLayer;
    const lightLayer = view.map.findLayerById("Lys") as __esri.FeatureLayer;

    const relation = layer.relationships.find((relationship) => relationship.name === "Lyssektor");

    // Depending on which object type was clicked (sector, line, or light), find the related sector data
    const sectorTableData = await layer.queryRelatedFeatures({
        outFields: ["*"],
        relationshipId: relation?.id, //layerIdAndRelationshipId[hitGraphic.layer.id as keyof typeof layerIdAndRelationshipId],
        objectIds: [objectid],
    });

    const fknavinst = sectorTableData[objectid].features[0].attributes.fknavinst;

    // Find the sector geometry
    const sectors = await sectorTable.queryFeatures({
        where: `fknavinst = '${fknavinst}'`,
        outFields: ["*"],
    });
    // Get all the sector attributes (line lengths, color, radius, description etc.)
    const sectorAttributes = sectors.features.map((f) => f.attributes);

    sectorAttributes.sort((a, b) => a.sektor_nr - b.sektor_nr);

    // Find relationship IDs
    const sectorTableToLightRelation = getRelationshipId(lightLayer);

    // Get the lighthouse geometry (we need this to get the center of the lighthouse)
    const lighthouseObject = await sectorTable.queryRelatedFeatures({
        outFields: ["*"],
        relationshipId: sectorTableToLightRelation,
        objectIds: [sectorAttributes[0].objectid],
        returnGeometry: true,
    });

    const lighthouseGeometry = lighthouseObject[sectorAttributes[0].objectid].features[0].geometry;

    // Construct the lighthouse data object
    const lighthouseData = {
        id: fknavinst,
        center: new Point({
            x: lighthouseGeometry.x,
            y: lighthouseGeometry.y,
            spatialReference: view.spatialReference,
        }),
        sectors: sectorAttributes
            .map((sector, index) => {
                return {
                    globalid: sector.globalid,
                    lighthouseId: fknavinst,
                    color: sector.lys_farge.toString(),
                    startAngle: sector.retning_sektorlinje_1,
                    endAngle: sector.retning_sektorlinje_2, // We need this temporarily to compute the dark sectors
                    innerRadius: sector.sektor_radius * metersPerNauticalMile,
                    startLineLength: sector.lengde_sektorlinje_1 * metersPerNauticalMile,
                    index: index,
                    description: sector.beskrivelse,
                };
            })
            .sort((a, b) => a.startAngle - b.startAngle), // Sort the sectors by angle to avoid graphics overlap
        setState: setLighthouseData,
    };

    const sectorsIncludingDark: any = [];

    lighthouseData.sectors.forEach((sector, index) => {
        const nextIndex = (index + 1) % lighthouseData.sectors.length;

        sectorsIncludingDark.push(sector);

        // Add a dark sector if the next sector does not start where the current sector ends
        if (lighthouseData.sectors[nextIndex].startAngle !== sector.endAngle) {
            sectorsIncludingDark.push({
                globalid: uuidv4(),
                lighthouseId: fknavinst,
                color: SectorColor.DARK,
                startAngle: sector.endAngle,
                endAngle: lighthouseData.sectors[nextIndex].startAngle,
                innerRadius: DefaultSectorValues.defaultInnerRadius,
                startLineLength: sector.startLineLength,
                index: index + 1,
                description: "",
            });
        }
    });

    sectorsIncludingDark.sort((a: Sector, b: Sector) => a.startAngle! - b.startAngle!);

    // Update all sector indices
    sectorsIncludingDark.forEach((sector: Sector, index: number) => {
        sector.index = index;
    });

    // Remove the endAngle attribute from all sectors
    const finalSectors = sectorsIncludingDark.map(
        ({ endAngle, ...allOtherAttributes }: { endAngle: number; [key: string]: any }) => {
            return allOtherAttributes;
        }
    );

    lighthouseData.sectors = finalSectors;

    // Update the lighthouse data object with the new sectors
    const lighthouseFeature = lighthouseObject[sectorAttributes[0].objectid].features[0];
    lighthouseFeature.attributes.lighthouseData = lighthouseData;
    lighthouseFeature.layer = lightLayer;

    // Set the selected feature and lighthouse data in the state
    setSelectedFeature?.(lighthouseFeature);
    setLighthouseData?.(lighthouseData);

    setLoading?.(false);
};

export const saveSectorData = async (
    lighthouseData: LighthouseData,
    selectedFeature: Graphic,
    trueNorth: number,
    setIsSavingLighthouse: (isSaving: boolean) => void,
    setLoading: (isLoading: boolean) => void
) => {
    const mapView = getMapView();
    if (!mapView) return;

    setIsSavingLighthouse(true);
    setLoading(true);

    const sectorTable = mapView.map.findTableById("Lyssektor") as FeatureLayer;
    const sectorLayer = mapView.map.findLayerById("Lyssektor") as FeatureLayer;
    const lineLayer = mapView.map.findLayerById("Sektorlinje") as FeatureLayer;
    const lighthouseLayer = mapView.map.findLayerById("Fyrlykt") as GraphicsLayer;
    const lightLayer = mapView.map.findLayerById("Lys") as FeatureLayer;

    const existingSectors = await sectorTable.queryFeatures({
        where: `fknavinst = '${lighthouseData.id}'`,
        outFields: ["*"],
        returnGeometry: true,
    });

    const existingLights = await lightLayer.queryFeatures({
        where: `globalid = '${lighthouseData.id}'`,
        outFields: ["*"],
        returnGeometry: true,
    });

    const sectorText = {
        1: "W",
        3: "R",
        4: "G",
        0: "D", // Include this to make Typescript happy
    };

    let lightGlobalId = "";
    let lightObjectId = null;
    let lys = null;

    await lighthouseLayer.load();
    const lightGeometry = lighthouseLayer.graphics.find(
        (graphic) => graphic.attributes.lighthouseId === lighthouseData.id && graphic.attributes.objectType === "center"
    ).geometry;

    selectedFeature.geometry = lightGeometry;

    // Save light object
    if (existingLights.features.length === 0) {
        const editsResult = await lightLayer.applyEdits({
            addFeatures: [selectedFeature],
        });
        lightGlobalId = editsResult.addFeatureResults[0].globalId;
        lightObjectId = editsResult.addFeatureResults[0].objectId;
        setFeatureAttribute(selectedFeature, "objectid", lightObjectId);
        setFeatureAttribute(selectedFeature, "globalid", lightGlobalId);
    } else {
        const editsResult = await lightLayer.applyEdits({
            updateFeatures: [selectedFeature],
        });
        lightGlobalId = editsResult.updateFeatureResults[0].globalId;
        lightObjectId = editsResult.updateFeatureResults[0].objectId;
    }

    const tableSectorsToAdd = [];
    const sectorGeometriesToAdd = [];
    const lineGeometriesToAdd = [];
    const tableSectorsToUpdate = [];
    const sectorGeometriesToUpdate = [];
    const lineGeometriesToUpdate = [];
    const tableSectorsToDelete = [];
    const sectorGeometriesToDelete = [];
    const lineGeometriesToDelete = [];

    drawSectors({
        graphicsLayer: lighthouseLayer,
        data: lighthouseData,
        setIsEditing: () => {},
        sectorsToFill: [],
        opacity: 1,
        trueNorthCorrection: trueNorth,
        overrideSectorWidth: true,
    });

    // Remove dark sectors from the lighthouse data. These shall not be published to the database.
    const lighthouseDataWithoutDarkSectors = lighthouseData.sectors.filter(
        (sector) => sector.color !== SectorColor.DARK
    );
    lighthouseDataWithoutDarkSectors.forEach((sector, index) => {
        sector.index = index;
    });

    for (const sector of existingSectors.features) {
        const lighthouseDataSector = lighthouseDataWithoutDarkSectors.find(
            (s) => s.globalid === sector.attributes.globalid
        );

        if (lighthouseDataSector) {
            const currentOriginalSectorIndex = lighthouseData.sectors.findIndex(
                (s) => s.globalid === lighthouseDataSector.globalid
            );
            const nextSector = lighthouseData.sectors[(currentOriginalSectorIndex + 1) % lighthouseData.sectors.length];

            // Set new attributes from lighthouseData
            sector.attributes.lys_farge = parseInt(lighthouseDataSector.color?.toString() ?? "1");
            sector.attributes.retning_sektorlinje_1 = lighthouseDataSector.startAngle;
            sector.attributes.retning_sektorlinje_2 = nextSector.startAngle;
            sector.attributes.sektor_radius =
                (lighthouseDataSector.innerRadius ?? DefaultSectorValues.defaultInnerRadius) / metersPerNauticalMile;
            sector.attributes.lengde_sektorlinje_1 =
                (lighthouseDataSector.startLineLength ?? DefaultSectorValues.defaultLineLength) / metersPerNauticalMile;
            sector.attributes.lengde_sektorlinje_2 =
                (nextSector.startLineLength ?? DefaultSectorValues.defaultLineLength) / metersPerNauticalMile;
            sector.attributes.beskrivelse = lighthouseDataSector.description;
            sector.attributes.sektor_tekst = sectorText[lighthouseDataSector.color!];
            sector.attributes.sektor_nr = lighthouseDataSector.index + 1;

            tableSectorsToUpdate.push(sector);

            //////////

            // Find geometry for corresponding sectors in the graphics layer
            await lighthouseLayer.load();
            const sectorGeometryMap = lighthouseLayer.graphics.find(
                (graphic) =>
                    graphic.attributes.globalid === sector.attributes.globalid &&
                    graphic.attributes.objectType === "sector"
            );

            // Find data from database for corresponding sectors
            const sectorGeometryDB = await sectorTable.queryRelatedFeatures({
                outFields: ["*"],
                relationshipId: getRelationshipId(sectorLayer),
                objectIds: [sector.attributes.objectid],
                returnGeometry: true,
            });

            const sectorGeometryDBFeature = sectorGeometryDB[sector.attributes.objectid].features[0];

            // Apply edits done in the graphics layer to the database
            sectorGeometryDBFeature.geometry = sectorGeometryMap.geometry;
            sectorGeometryDBFeature.attributes.lys_farge = parseInt(lighthouseDataSector.color?.toString() ?? "1");
            sectorGeometriesToUpdate.push(sectorGeometryDBFeature);

            //////////

            // Find geometry, in the graphics layer, for the one sector line corresponding to the sector
            const lineGraphic1 = lighthouseLayer.graphics.find(
                (graphic) =>
                    graphic.attributes.objectType === "line" &&
                    graphic.attributes.sectorId === sector.attributes.globalid
            );

            // Find geometry for the line corresponding to the NEXT sector. This is equivalent to the second sector line of the current sector
            const lineGraphic2 = lighthouseLayer.graphics.find(
                (graphic) =>
                    graphic.attributes.objectType === "line" &&
                    graphic.attributes.index === (lineGraphic1.attributes.index + 1) % lighthouseData.sectors.length
            );

            // Find data from database for corresponding sector lines
            const sectorLines = await sectorTable.queryRelatedFeatures({
                outFields: ["*"],
                relationshipId: getRelationshipId(lineLayer),
                objectIds: [sector.attributes.objectid],
            });

            // Apply edits done in the graphics layer to the database
            const sectorLineFeatures = sectorLines[sector.attributes.objectid].features;
            sectorLineFeatures[0].geometry = lineGraphic1.geometry;
            sectorLineFeatures[1].geometry = lineGraphic2.geometry;
            lineGeometriesToUpdate.push(...sectorLineFeatures);
        } else {
            const sectorLines = await sectorTable.queryRelatedFeatures({
                outFields: ["*"],
                relationshipId: getRelationshipId(lineLayer),
                objectIds: [sector.attributes.objectid],
            });
            const sectorGraphicDB = await sectorTable.queryRelatedFeatures({
                outFields: ["*"],
                relationshipId: getRelationshipId(sectorLayer),
                objectIds: [sector.attributes.objectid],
            });

            tableSectorsToDelete.push(sector);
            sectorGeometriesToDelete.push(...sectorGraphicDB[sector.attributes.objectid].features);
            lineGeometriesToDelete.push(...sectorLines[sector.attributes.objectid].features);
        }
    }

    for (const [index, sector] of lighthouseDataWithoutDarkSectors.entries()) {
        const existingSector = existingSectors.features.find((s) => s.attributes.globalid === sector.globalid);

        if (!existingSector) {
            const newSector = new Graphic({
                attributes: {
                    globalid: sector.globalid,
                    fknavinst: lightGlobalId,
                    lys_farge: parseInt(sector.color?.toString() ?? "1"),
                    retning_sektorlinje_1: sector.startAngle,
                    retning_sektorlinje_2:
                        lighthouseData.sectors[(index + 1) % lighthouseData.sectors.length].startAngle,
                    sektor_radius:
                        (sector.innerRadius ?? DefaultSectorValues.defaultInnerRadius) / metersPerNauticalMile,
                    lengde_sektorlinje_1:
                        (sector.startLineLength ?? DefaultSectorValues.defaultLineLength) / metersPerNauticalMile,
                    lengde_sektorlinje_2:
                        (lighthouseData.sectors[(index + 1) % lighthouseData.sectors.length].startLineLength ??
                            DefaultSectorValues.defaultLineLength) / metersPerNauticalMile,
                    beskrivelse: sector.description,
                    sektor_tekst: sectorText[sector.color!],
                    lys: lys,
                    sektor_nr: index + 1,
                },
            });

            tableSectorsToAdd.push(newSector);

            await lighthouseLayer.load();
            const sectorGeometryMap = lighthouseLayer.graphics.find(
                (graphic) => graphic.attributes.globalid === sector.globalid
            );

            const sectorGeometryDB = new Graphic({
                attributes: {
                    fksektorid: sector.globalid,
                    lys_farge: sector.color,
                },
                geometry: sectorGeometryMap.geometry,
            });

            sectorGeometriesToAdd.push(sectorGeometryDB);

            const lineGraphic1 = lighthouseLayer.graphics.find(
                (graphic) => graphic.attributes.objectType === "line" && graphic.attributes.sectorId === sector.globalid
            );

            const lineGraphic2 = lighthouseLayer.graphics.find(
                (graphic) =>
                    graphic.attributes.objectType === "line" &&
                    graphic.attributes.index === (lineGraphic1.attributes.index + 1) % lighthouseData.sectors.length
            );

            const newLine1 = new Graphic({
                geometry: lineGraphic1.geometry,
                attributes: {
                    fksektorid: sector.globalid,
                },
            });

            const newLine2 = new Graphic({
                geometry: lineGraphic2.geometry,
                attributes: {
                    fksektorid: sector.globalid,
                },
            });

            lineGeometriesToAdd.push(...[newLine1, newLine2]);
        }
    }

    // Apply all edits to the database
    await sectorTable.applyEdits(
        {
            addFeatures: tableSectorsToAdd,
            updateFeatures: tableSectorsToUpdate,
            deleteFeatures: tableSectorsToDelete,
        },
        { globalIdUsed: true }
    );

    await sectorLayer.applyEdits({
        addFeatures: sectorGeometriesToAdd,
        updateFeatures: sectorGeometriesToUpdate,
        deleteFeatures: sectorGeometriesToDelete,
    });

    await lineLayer.applyEdits({
        addFeatures: lineGeometriesToAdd,
        updateFeatures: lineGeometriesToUpdate,
        deleteFeatures: lineGeometriesToDelete,
    });

    setIsSavingLighthouse(false);
    setLoading(false);

    return lightObjectId;
};

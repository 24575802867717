import { useRef, useState } from "react";
import { Popover } from "react-tiny-popover";

import "./confirmationPopover.css";
import NfsButton from "../NfsButton/NfsButton";

interface ConfirmationPopoverProps {
    children: React.ReactNode;
    onConfirm: () => void;
    onCancel: () => void;
    confirmText?: string;
    cancelText?: string;
    message: string;
    title: string;
    warning?: boolean;
}

const ConfirmationPopover = (props: ConfirmationPopoverProps) => {

    const [open, setOpen] = useState(false);
    const popoverRef = useRef<HTMLDivElement>(null);

    return (
        <Popover isOpen={open}
            onClickOutside={() => setOpen(false)}
            boundaryInset={25}
            content={(
                <div ref={popoverRef} className="confirmation-container">
                    <div className="confirmation-title">{props.title}</div>
                    <span className="confirmation-message">{props.message}</span>
                    <div className="confirmation-actions">
                        <NfsButton onClick={() => { props.onConfirm(); setOpen(false); }}>
                            {props?.confirmText ?? "Bekreft"}
                        </NfsButton>
                        <NfsButton outlined inverted onClick={() => { props.onCancel(); setOpen(false); }} >
                            {props?.cancelText ?? "Avbryt"}
                        </NfsButton>
                    </div>
                </div>
            )}
        >
            <button className="empty-button" onClick={() => setOpen(true)}>{props.children}</button>
        </Popover>
    )

}

export default ConfirmationPopover;
import React from "react";
import { LighthouseData } from "./utils/lighthouseUtils";
import VersionManagementService from "@arcgis/core/versionManagement/VersionManagementService";

export interface UserContextType {
    user: {
        set: (value: __esri.PortalUser | null) => void;
        value: __esri.PortalUser | null;
    };
    userSelf: {
        set: (value: any) => void;
        value: any;
    };
}

export interface VersionContextType {
    currentVersion: {
        set: (value: __esri.VersionInfoJSON) => void;
        value: __esri.VersionInfoJSON;
    };
    mapView: {
        set: (value: __esri.MapView) => void;
        value: __esri.MapView;
    };
    showVersionMgmt: {
        set: (value: boolean) => void;
        value: boolean;
    };
    versionServiceRef: {
        set: (value: React.MutableRefObject<VersionManagementService>) => void;
        value: React.MutableRefObject<VersionManagementService>;
    };
}

export interface AppContextType {
    selectedFeature: {
        set: (value: __esri.Graphic | null) => void;
        value: __esri.Graphic | null;
    };
}

export interface MenuContextType {}

export interface LighthouseContextType {
    lighthouseData: {
        set: (value: LighthouseData) => void;
        value: LighthouseData;
    };

    lighthouseDataHook: {
        set: (value: React.MutableRefObject<LighthouseData>) => void;
        value: React.MutableRefObject<LighthouseData>;
    };

    lighthouseSectorPanelsOpen: {
        set: (value: Set<number>) => void;
        value: Set<number>;
    };

    lighthouseSectorPanelsOpenHook: {
        set: (value: React.MutableRefObject<Set<number>>) => void;
        value: React.MutableRefObject<Set<number>>;
    };

    showSectorColors: {
        set: (value: boolean) => void;
        value: boolean;
    };

    sectorOpacity: {
        set: (value: number) => void;
        value: number;
    };

    isDrawing: {
        set: (value: boolean) => void;
        value: boolean;
    };

    isEditing: {
        set: (value: boolean) => void;
        value: boolean;
    };
}

export interface JobContextType {
    selectedJob: {
        set: (value: __esri.Graphic | null) => void;
        value: __esri.Graphic | null;
    };
}

export interface MenuContextType {}

export const UserContext = React.createContext<UserContextType | undefined>(undefined);
export const VersionContext = React.createContext<VersionContextType | undefined>(undefined);
export const MenuContext = React.createContext<MenuContextType | undefined>(undefined);
export const AppContext = React.createContext<AppContextType | undefined>(undefined);
export const JobContext = React.createContext<JobContextType | undefined>(undefined);

export const LighthouseContext = React.createContext<LighthouseContextType | undefined>(undefined);

import { uuidv4 } from "../../../utils/helpers";
import "./RadioButton.css";

interface RadioButtonProps {
    label: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
}

const RadioButton = (props: RadioButtonProps) => {
    const { label, checked, onChange } = props;

    const id = uuidv4();

    return (
        <div className="radio-button">
            <input type="radio" checked={checked} onChange={(e) => onChange(e.target.checked)} id={id} />
            <label htmlFor={id}>{label}</label>
        </div>
    );
};

export default RadioButton;

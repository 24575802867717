import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import { useContext, useEffect, useRef, useState } from "react";
import AddIcon from "../../../assets/add.svg?react";
import TrashCanIcon from "../../../assets/trash-can.svg?react";
import { AppContext, LighthouseContext } from "../../../Context";
import { getMapView, highlightFeature, unHighlightGraphic } from "../../../utils/arcgisUtils";
import { metersPerNauticalMile } from "../../../utils/constants";
import {
    createSector,
    DefaultSectorValues,
    deleteSector,
    sanitizeSectors,
    SectorColor,
} from "../../../utils/lighthouseUtils";
import Collapsible from "../../genericComponents/Collapsible/Collapsible";
import NfsButton from "../../genericComponents/NfsButton/NfsButton";
import RadioButtonGroup from "../../genericComponents/RadioButton/RadioButtonGroup";
import Spacer from "../../genericComponents/Spacer/Spacer";
import SectorColorAttribute from "./SectorColorAttribute";
import SectorColorIndicator from "./SectorColorIndicator";
import SectorDescriptionAttribute from "./SectorDescriptionAttribute";
import SectorNumberAttribute from "./SectorNumberAttribute";

interface SectorMenuProps {
    sectorIndex: number;
    displayIndex: number;
}

const SectorMenu = (props: SectorMenuProps) => {
    const { sectorIndex, displayIndex } = props;

    const highlightSectorHandleRef = useRef<IHandle | void>(undefined);
    const highlightSectorRoseHandleRef = useRef<IHandle | void>(undefined);
    const highlightSectorLineHoverHandleRef = useRef<IHandle | void>(undefined);
    const highlightSectorLineEditingHandleRef = useRef<IHandle | void>(undefined);
    const highlightSectorLineHoverRoseHandleRef = useRef<IHandle | void>(undefined);
    const highlightSectorLineEditingRoseHandleRef = useRef<IHandle | void>(undefined);
    const [highlightSectorLineHoverIndex, setHighlightSectorLineHoverIndex] = useState<number | null>(null);
    const [highlightSectorLineEditingIndex, setHighlightSectorLineEditingIndex] = useState<number | null>(null);

    const [unitSelectedIndex, setUnitSelectedIndex] = useState(0);

    const appContext = useContext(AppContext);
    const lighthouseContext = useContext(LighthouseContext);
    const lighthouseData = appContext?.selectedFeature.value?.attributes.lighthouseData;

    const isEditing = lighthouseContext?.isDrawing.value;

    const sector = lighthouseData.sectors[sectorIndex];
    const sectorNext =
        lighthouseData.sectors[(sectorIndex + 1) % lighthouseData.sectors.length] ?? lighthouseData.sectors[0];
    const setLighthouseState = lighthouseContext!.lighthouseData.set;

    const isOpen = lighthouseContext!.lighthouseSectorPanelsOpen.value.has(sectorIndex);

    const setIsOpen = (sectorIndex: number, isOpen: boolean) => {
        const openSectors = lighthouseContext?.lighthouseSectorPanelsOpen;
        if (!isOpen) {
            const newSectors = new Set(openSectors?.value);
            newSectors.delete(sectorIndex);
            openSectors?.set(newSectors);
        } else {
            openSectors?.set(new Set(openSectors.value).add(sectorIndex));
        }
    };

    const setSelectedColorOption = (option: string | null) => {
        const newLighthouseData = { ...lighthouseData };
        if (option) {
            newLighthouseData.sectors[sectorIndex].color = SectorColor[option as keyof typeof SectorColor];
        }
        lighthouseContext!.lighthouseData.value = newLighthouseData;

        setLighthouseState(lighthouseContext!.lighthouseData.value);
    };

    const setSelectedNumberValue = (value: number, sectorIndex: number, attributeName: string) => {
        const newLighthouseData = { ...lighthouseData };
        newLighthouseData.sectors[sectorIndex][attributeName] = value;
        lighthouseContext!.lighthouseData.value = newLighthouseData;

        setLighthouseState(lighthouseContext!.lighthouseData.value);
    };

    const setDescription = (description: string) => {
        const newLighthouseData = { ...lighthouseData };
        newLighthouseData.sectors[sectorIndex].description = description;
        lighthouseContext!.lighthouseData.value = newLighthouseData;
        setLighthouseState(lighthouseContext!.lighthouseData.value);
    };

    const isAngleLessThanOneDegree = (startAngle1: number, startAngle2: number) => {
        return Math.abs(startAngle2 - startAngle1) < 1;
    };

    const findLineGraphic = (index: number, mapViewId?: string, layerId?: string) => {
        const mapView = getMapView(mapViewId);
        if (!mapView) return;

        const lighthouseEditingLayer = mapView.map.findLayerById(layerId ?? "FyrlyktEditing") as GraphicsLayer;
        const lineGraphic = lighthouseEditingLayer.graphics.find(
            (graphic) => graphic.attributes?.objectType === "line" && graphic.attributes?.index === index
        );

        return lineGraphic;
    };

    const highlightLineGraphic = (
        index: number,
        mapRef: React.MutableRefObject<void | IHandle>,
        mapViewId?: string,
        layerId?: string
    ) => {
        const mapView = getMapView(mapViewId);
        if (!mapView) return;

        const layer = mapView.map.findLayerById(layerId ?? "FyrlyktEditing") as GraphicsLayer;
        const lineGraphic = findLineGraphic(index, mapViewId, layerId);

        if (lineGraphic) {
            highlightFeature(lineGraphic, layer, mapViewId).then((handle) => {
                mapRef.current = handle;
            });
        }
    };

    const unHighlightLineGraphic = (
        index: number,
        ref: React.MutableRefObject<void | IHandle>,
        mapViewId?: string,
        layerId?: string
    ) => {
        const mapView = getMapView(mapViewId);
        if (!mapView) return;

        const layer = mapView.map.findLayerById(layerId ?? "FyrlyktEditing") as GraphicsLayer;
        const lineGraphic = findLineGraphic(index);

        if (lineGraphic) {
            if (!(highlightSectorLineEditingHandleRef.current && highlightSectorLineHoverHandleRef.current)) {
                unHighlightGraphic(lineGraphic, layer);
            }
            ref.current?.remove();
            ref.current = undefined;
        }
    };

    const onBlur = (index?: number) => {
        if (index === null || index === undefined) index = sectorIndex;

        const newData = sanitizeSectors(lighthouseData, index);

        newData.sectors.forEach((sector, i) => {
            sector.index = i;
        });
        lighthouseContext!.lighthouseData.value = newData;
        setLighthouseState(lighthouseContext!.lighthouseData.value);
        appContext!.selectedFeature.value!.attributes.lighthouseData = newData;
        appContext!.selectedFeature.set(appContext!.selectedFeature.value);

        unHighlightLineGraphic(index, highlightSectorLineEditingHandleRef);
        unHighlightLineGraphic(index, highlightSectorLineEditingRoseHandleRef, "sector-rose-map", "SectorRose");
        setHighlightSectorLineEditingIndex(null);
    };

    const onFocus = (index?: number) => {
        if (index === null || index === undefined) index = sectorIndex;
        highlightLineGraphic(index, highlightSectorLineEditingHandleRef);
        highlightLineGraphic(index, highlightSectorLineEditingRoseHandleRef, "sector-rose-map", "SectorRose");
        setHighlightSectorLineEditingIndex(index);
    };

    const onMouseEnter = (index?: number) => {
        if (index === null || index === undefined) index = sectorIndex;
        highlightLineGraphic(index, highlightSectorLineHoverHandleRef);
        highlightLineGraphic(index, highlightSectorLineHoverRoseHandleRef, "sector-rose-map", "SectorRose");
        setHighlightSectorLineHoverIndex(index);
    };

    const onMouseLeave = (index?: number) => {
        if (index === null || index === undefined) index = sectorIndex;
        unHighlightLineGraphic(index, highlightSectorLineHoverHandleRef);
        unHighlightLineGraphic(index, highlightSectorLineHoverRoseHandleRef, "sector-rose-map", "SectorRose");
        setHighlightSectorLineHoverIndex(null);
    };

    const highlightSector = (ref: React.MutableRefObject<void | IHandle>, mapViewId?: string, layerId?: string) => {
        const mapView = getMapView(mapViewId);
        if (!mapView) return;

        const layer = mapView.map.findLayerById(layerId ?? "FyrlyktEditing") as GraphicsLayer;
        const sectorGraphics = layer.graphics.filter((graphic) => graphic.attributes?.objectType === "sector");

        const targetGraphic = sectorGraphics.find((graphic) => graphic.attributes?.index === sectorIndex);

        if (targetGraphic) {
            highlightFeature(targetGraphic, layer, mapViewId).then((handle) => {
                ref.current = handle;
            });
        }
    };

    const unHighlightSector = (ref: React.MutableRefObject<void | IHandle>, mapViewId?: string, layerId?: string) => {
        const mapView = getMapView(mapViewId);
        if (!mapView) return;

        const layer = mapView.map.findLayerById(layerId ?? "FyrlyktEditing") as GraphicsLayer;
        const sectorGraphics = layer.graphics.filter((graphic) => graphic.attributes?.objectType === "sector");

        const targetGraphic = sectorGraphics.find((graphic) => graphic.attributes?.index === sectorIndex);
        if (targetGraphic) {
            unHighlightGraphic(targetGraphic, layer);
        }
        ref.current?.remove();
        ref.current = undefined;
    };

    useEffect(() => {
        // If data changes and a line is highlighted, reapply the highlight
        if (highlightSectorLineHoverIndex !== null) {
            setTimeout(() => {
                onMouseEnter(highlightSectorLineHoverIndex);
            }, 100);
        }
        if (highlightSectorLineEditingIndex !== null) {
            setTimeout(() => {
                onFocus(highlightSectorLineEditingIndex);
            }, 100);
        }
    }, [lighthouseContext?.lighthouseData.value]);

    // Effect to handle highlighting when lighthouse data changes or panel opens/closes
    useEffect(() => {
        const mapView = getMapView();
        if (!mapView || isEditing) {
            highlightSectorHandleRef.current?.remove();
            highlightSectorHandleRef.current = undefined;
            return;
        }

        if (isOpen) {
            setTimeout(() => {
                highlightSector(highlightSectorHandleRef);
                highlightSector(highlightSectorRoseHandleRef, "sector-rose-map", "SectorRose");
            }, 100); // We need this delay in order to wait for the graphic to be added to the layer
        } else {
            setTimeout(() => {
                unHighlightSector(highlightSectorHandleRef);
                unHighlightSector(highlightSectorRoseHandleRef, "sector-rose-map", "SectorRose");
            }, 100);
        }

        return () => {
            highlightSectorHandleRef.current?.remove();
            highlightSectorHandleRef.current = undefined;
        };
    }, [lighthouseContext?.lighthouseData.value, isOpen, isEditing]);

    return (
        <div className="feature-viewer-feature-box">
            <Collapsible
                title={`Sektor ${sector.color === SectorColor.DARK ? "-" : displayIndex + 1}`}
                icon={<SectorColorIndicator color={sector.color?.valueOf()} height="1.7rem" />}
                isOpen={isOpen}
                setIsOpen={(isOpen) => setIsOpen(sectorIndex, isOpen)}
            >
                <RadioButtonGroup
                    options={["Vis i Nm", "Vis i m"]}
                    selectedIndex={unitSelectedIndex}
                    onChange={(selectedIndex) => {
                        setUnitSelectedIndex(selectedIndex);
                    }}
                />
                <Spacer height={5} />
                {lighthouseData.sectors.length > 1 && (
                    <>
                        <SectorNumberAttribute
                            label="Retning linje 1"
                            sector={sector}
                            attributeName="startAngle"
                            defaultValue={0}
                            setValue={setSelectedNumberValue}
                            onFocus={() => onFocus()}
                            onBlur={() => onBlur()}
                            onMouseEnter={() => onMouseEnter()}
                            onMouseLeave={() => onMouseLeave()}
                            unit={"°"}
                        />

                        <SectorNumberAttribute
                            label="Lengde linje 1"
                            sector={sector}
                            attributeName="startLineLength"
                            defaultValue={DefaultSectorValues.defaultLineLength}
                            setValue={setSelectedNumberValue}
                            onFocus={() => onFocus()}
                            onBlur={() => onBlur()}
                            onMouseEnter={() => onMouseEnter()}
                            onMouseLeave={() => onMouseLeave()}
                            valueMultiplier={unitSelectedIndex === 0 ? metersPerNauticalMile : 1}
                            decimalDigits={unitSelectedIndex === 0 ? 3 : 0}
                            unit={unitSelectedIndex === 0 ? "Nm" : "m"}
                        />

                        <Spacer height={10} />
                        <SectorNumberAttribute
                            label="Retning linje 2"
                            sector={sectorNext}
                            attributeName="startAngle"
                            defaultValue={0}
                            setValue={setSelectedNumberValue}
                            onFocus={() => onFocus(sectorNext.index)}
                            onBlur={() => onBlur(sectorNext.index)}
                            onMouseEnter={() => onMouseEnter(sectorNext.index)}
                            onMouseLeave={() => onMouseLeave(sectorNext.index)}
                            errorMessage={
                                isAngleLessThanOneDegree(sector.startAngle, sectorNext.startAngle)
                                    ? "Sektor mindre enn 1 grad"
                                    : undefined
                            }
                            unit={"°"}
                        />
                        <SectorNumberAttribute
                            label="Lengde linje 2"
                            sector={sectorNext}
                            attributeName="startLineLength"
                            defaultValue={DefaultSectorValues.defaultLineLength}
                            setValue={setSelectedNumberValue}
                            onFocus={() => onFocus(sectorNext.index)}
                            onBlur={() => onBlur(sectorNext.index)}
                            onMouseEnter={() => onMouseEnter(sectorNext.index)}
                            onMouseLeave={() => onMouseLeave(sectorNext.index)}
                            valueMultiplier={unitSelectedIndex === 0 ? metersPerNauticalMile : 1}
                            decimalDigits={unitSelectedIndex === 0 ? 3 : 0}
                            unit={unitSelectedIndex === 0 ? "Nm" : "m"}
                        />
                        <Spacer height={10} />
                    </>
                )}
                <SectorNumberAttribute
                    label="Radius"
                    sector={sector}
                    attributeName="innerRadius"
                    defaultValue={DefaultSectorValues.defaultInnerRadius}
                    setValue={setSelectedNumberValue}
                    onBlur={() => onBlur()}
                    unit={"m"}
                />
                <SectorColorAttribute
                    label="Farge"
                    value={sector.color ?? SectorColor.WHITE}
                    setSelectedOption={setSelectedColorOption}
                />
                <Spacer height={10} />
                <SectorDescriptionAttribute
                    setDescription={setDescription}
                    initialText={lighthouseData.sectors[sectorIndex].description}
                />
                <div className="flex-row small-gap">
                    <NfsButton
                        onClick={() => {
                            const newLighthouseData = lighthouseContext!.lighthouseData.value;
                            lighthouseContext!.lighthouseData.value = createSector(lighthouseData, sectorIndex);
                            setLighthouseState({ ...newLighthouseData });
                        }}
                    >
                        <AddIcon />
                        Ny sektor
                    </NfsButton>
                    <NfsButton
                        onClick={() => {
                            const newLighthouseData = lighthouseContext!.lighthouseData.value;
                            lighthouseContext!.lighthouseData.value = deleteSector(lighthouseData, sectorIndex);
                            setLighthouseState({ ...newLighthouseData });
                        }}
                        outlined
                        disabled={lighthouseData.sectors.length === 1}
                    >
                        <TrashCanIcon />
                        Slett sektor
                    </NfsButton>
                </div>
            </Collapsible>
        </div>
    );
};

export default SectorMenu;

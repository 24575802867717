import "./RangeSlider.css";

interface RangeSliderProps {
    value: number;
    onChange: (value: number) => void;
    min?: number;
    max?: number;
    step?: number;
    disabled?: boolean;
}

const RangeSlider = (props: RangeSliderProps) => {
    const { value, onChange, min, max, step, disabled } = props;

    const defaultMin = 0;
    const defaultMax = 1;

    return (
        <div className="range-slider">
            <input
                type="range"
                disabled={disabled ?? false}
                min={min ?? defaultMin}
                max={max ?? defaultMax}
                step={step ?? ((max ?? defaultMax) - (min ?? defaultMin)) / 100}
                value={value}
                onChange={(event) => onChange(event.target.valueAsNumber)}
            />
        </div>
    );
};

export default RangeSlider;

import ViewPoint from "@arcgis/core/Viewpoint";

export const storeViewPoint = (viewPoint: ViewPoint) => {
    const viewPointString = viewPoint.toJSON();
    const viewPointStoreObject = {
        storeDate: new Date().toISOString(),
        viewPoint: viewPointString
    }

    if (viewPointString) {
        localStorage.setItem('viewPoint', JSON.stringify(viewPointStoreObject));
    }
}

export const getViewPoint = (): ViewPoint | null => {
    const viewPointString = localStorage.getItem('viewPoint');
    const viewPointStoreObject = viewPointString ? JSON.parse(viewPointString) : null;
    if (!viewPointStoreObject) {
        return null;
    }

    if (viewPointStoreObject) {
        return ViewPoint.fromJSON(viewPointStoreObject.viewPoint);
    }
    return null;
}
import * as React from "react";
import { useSelector } from "react-redux";
import {
    AppContext,
    AppContextType,
    JobContext,
    JobContextType,
    LighthouseContext,
    LighthouseContextType,
    VersionContext,
    VersionContextType,
} from "../../Context";
import { StoreState } from "../../store/rootReducer";
import { LighthouseData } from "../../utils/lighthouseUtils";
import FeatureViewer from "../FeatureViewer/FeatureViewer";
import Header from "../Header/Header";
import MapControl from "../MapComponent/MapControl";
import CreateObjectControl from "../MapTools/CreateObject/CreateObjectControl";
import MeasurementControl from "../MapTools/Measurement/MeasurementControl";
import SearchResults from "../SearchResults/SearchResults";
import VersionControl from "../VersionControl/VersionControl";
import "./AuthenticatedContent.css";
import { Bounce, ToastContainer } from "react-toastify";
import LightCalculator from "../LightCalculator/LightCalculator";
import SectorTable from "../MapTools/LightHouse/SectorTable";
// import JobsPanel from "../JobsManager/JobsPanel";
import SectorEditor from "../MapTools/LightHouse/SectorEditor";
import VersionManagementService from "@arcgis/core/versionManagement/VersionManagementService";
import { AppConfig } from "../../AppConfig";

export function AuthenticatedContent() {
    const [currentVersion, setCurrentVersion] = React.useState<__esri.VersionInfoJSON>();
    const [mapView, setMapView] = React.useState<__esri.MapView>();
    const [showVersionMgmt, setShowVersionMgmt] = React.useState<boolean>(false);
    const [searchResults, setSearchResults] = React.useState<__esri.SearchViewModelSearchResponseResults[]>([]);

    const [selectedFeature, setSelectedFeature] = React.useState<__esri.Graphic | null>(null);
    const [lighthouseDataState, setLighthouseDataState] = React.useState<LighthouseData | null>(null);
    const [lighthouseData, setLighthouseData] = React.useState<React.MutableRefObject<LighthouseData | null>>(
        React.useRef(null)
    );
    const [lighthouseSectorPanelsOpen, setLighthouseSectorPanelsOpen] = React.useState<Set<number>>(new Set());
    const [lighthouseSectorPanelsOpenRef, setLighthouseSectorPanelsOpenRef] = React.useState<
        React.MutableRefObject<Set<number>>
    >(React.useRef(new Set()));
    const [showSectorColors, setShowSectorColors] = React.useState<boolean>(false);
    const [sectorOpacity, setSectorOpacity] = React.useState<number>(0.5);
    const [isDrawing, setIsDrawing] = React.useState<boolean>(false);
    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const [versionServiceRef, setVersionServiceRef] = React.useState<React.MutableRefObject<VersionManagementService>>(
        React.useRef(
            new VersionManagementService({
                url: AppConfig.ArcGIS.BaseUrl + AppConfig.ArcGIS.VersionManagementService,
            })
        )
    );

    const [selectedJob, setSelectedJob] = React.useState<__esri.Graphic | null>(null);

    const measurementToolOpen = useSelector((state: StoreState) => state.app.measureToolOpen);
    const createObjectOpen = useSelector((state: StoreState) => state.app.createObjectOpen);
    const isSectorTableOpen = useSelector((state: StoreState) => state.lighthouse.isSectorTableOpen);

    const versionContextStore = {
        showVersionMgmt: {
            set: setShowVersionMgmt,
            value: showVersionMgmt,
        },
        currentVersion: {
            set: setCurrentVersion,
            value: currentVersion,
        },
        mapView: {
            set: setMapView,
            value: mapView,
        },
        versionServiceRef: {
            set: setVersionServiceRef,
            value: versionServiceRef,
        },
    } as VersionContextType;

    const appContextStore = {
        selectedFeature: {
            set: setSelectedFeature,
            value: selectedFeature,
        },
    } as AppContextType;

    const lighthouseContextStore = {
        lighthouseData: {
            set: setLighthouseDataState,
            value: lighthouseDataState,
        },
        lighthouseDataHook: {
            set: setLighthouseData,
            value: lighthouseData,
        },
        lighthouseSectorPanelsOpen: {
            set: setLighthouseSectorPanelsOpen,
            value: lighthouseSectorPanelsOpen,
        },
        lighthouseSectorPanelsOpenHook: {
            set: setLighthouseSectorPanelsOpenRef,
            value: lighthouseSectorPanelsOpenRef,
        },
        showSectorColors: {
            set: setShowSectorColors,
            value: showSectorColors,
        },
        sectorOpacity: {
            set: setSectorOpacity,
            value: sectorOpacity,
        },
        isDrawing: {
            set: setIsDrawing,
            value: isDrawing,
        },
        isEditing: {
            set: setIsEditing,
            value: isEditing,
        },
    } as LighthouseContextType;

    const jobIContextStore = {
        selectedJob: {
            set: setSelectedJob,
            value: selectedJob,
        },
    } as JobContextType;

    const memoVersionContextStore = React.useMemo(() => versionContextStore, [versionContextStore]);
    const memoAppContextStore = React.useMemo(() => appContextStore, [appContextStore]);
    const memoLighthouseContextStore = React.useMemo(() => lighthouseContextStore, [lighthouseContextStore]);
    const memoJobContextStore = React.useMemo(() => jobIContextStore, [jobIContextStore]);

    return (
        <VersionContext.Provider value={memoVersionContextStore}>
            <AppContext.Provider value={memoAppContextStore}>
                <LighthouseContext.Provider value={memoLighthouseContextStore}>
                    <JobContext.Provider value={memoJobContextStore}>
                        <Header setSearchResults={setSearchResults} />
                        <div className="map-container">
                            <SearchResults searchResults={searchResults} />
                            <FeatureViewer />
                            <VersionControl />
                            <LightCalculator />
                            <SectorEditor />
                            <MapControl />
                            {/* Not ready for the public yet :) */}
                            {/* <JobsPanel /> */}
                            {measurementToolOpen && <MeasurementControl />}
                            {createObjectOpen && <CreateObjectControl />}
                            {isSectorTableOpen && <SectorTable />}
                        </div>
                        <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                            transition={Bounce}
                        />
                    </JobContext.Provider>
                </LighthouseContext.Provider>
            </AppContext.Provider>
        </VersionContext.Provider>
    );
}

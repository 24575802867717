import * as symbolUtils from "@arcgis/core/symbols/support/symbolUtils.js";

import "./SearchItem.css";
import { useContext, useEffect, useState } from "react";
import { GetCodedValueAndAlias, highlightFeature } from "../../utils/arcgisUtils";
import { formatDate, zoomToObject } from "../../utils/helpers";
import NfsButton from "../genericComponents/NfsButton/NfsButton";
import { AppConfig } from "../../AppConfig";
import { AppContext } from "../../Context";

interface SearchItemProps {
    result: __esri.SearchResult;
    storeHighlightHandle: (handle: IHandle | null) => void;
}

const SearchItem = (props: SearchItemProps) => {
    const appContext = useContext(AppContext);

    const [symbolHtmlElement, setSymbolHtmlElement] = useState<HTMLElement | null>(null);

    const getAttributeValue = (attributeName: string) => {
        return GetCodedValueAndAlias(
            props.result.feature.layer,
            attributeName,
            props.result.feature.attributes[attributeName]
        )[1];
    };

    const zoomTo = async () => {
        if (!props.result.feature?.layer) {
            console.error("Feature or layer not found in search result.");
            return;
        }

        const layer = props.result.feature.layer;
        let scale = 5000;
        const layerConfig = AppConfig.Layers.find((layerConfig) => layerConfig.Name === layer.id);
        if (layerConfig) {
            scale = layerConfig.zoomScale;
        }
        if ("minScale" in layer && "maxScale" in layer) {
            // @ts-expect-error minScale does not exist on generic layer type
            const minScale = props.result.feature.layer.minScale;
            // @ts-expect-error maxScale does not exist on generic layer type
            const maxScale = props.result.feature.layer.maxScale;
            if (scale > minScale) {
                scale = minScale;
            }
            if (scale < maxScale) {
                scale = maxScale;
            }
        }
        const highlightHandle = await zoomToObject(props.result.feature, scale, undefined, true);
        if (highlightHandle) {
            props.storeHighlightHandle(highlightHandle);
        }
    };

    const openFeature = (feature: __esri.Graphic) => {
        appContext?.selectedFeature.set(feature);
    };

    const mouseEnterHandler = async () => {
        if (props.result.feature) {
            const highlightHandle = await highlightFeature(props.result.feature);
            if (highlightHandle) {
                props.storeHighlightHandle(highlightHandle);
            }
        }
    };

    useEffect(() => {
        if (props.result.feature) {
            symbolUtils.getDisplayedSymbol(props.result.feature).then((symbol) => {
                if (symbol) {
                    symbolUtils.renderPreviewHTML(symbol, { size: 12 }).then((symbolElement) => {
                        setSymbolHtmlElement(symbolElement);
                    });
                }
            });
        }
    }, [props.result]);

    return (
        <div className={`search-item`} onMouseEnter={mouseEnterHandler}>
            <div className="search-item-title">
                <div
                    className="search-item-symbol"
                    dangerouslySetInnerHTML={{ __html: symbolHtmlElement?.outerHTML ?? "" }}
                ></div>
                <span>{getAttributeValue("sjomerke_nr")}</span>
                <span>{getAttributeValue("navn")}</span>
            </div>
            {getAttributeValue("nfs_status") && (
                <div className="search-item-status">
                    <span>{getAttributeValue("nfs_status")}</span>
                </div>
            )}
            <div className="search-item-updated">
                <span>Sist oppdatert</span>
                <span>{formatDate(props.result.feature.attributes?.OppdatertDato)}</span>
            </div>
            <div className="search-item-onhover">
                <NfsButton
                    onClick={() => {
                        openFeature(props.result.feature);
                    }}
                >
                    <span>Åpne</span>
                </NfsButton>
                <NfsButton onClick={zoomTo}>
                    <span>Zoom til</span>
                </NfsButton>
            </div>
        </div>
    );
};

export default SearchItem;

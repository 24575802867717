import * as React from "react";
import { getUser } from "../../utils/authenticate";
import { UserContext } from "../../Context";

export function LoginScreen() {
  const userContext = React.useContext(UserContext);

  React.useEffect(() => {
    getUser(userContext?.userSelf.set).then((user) => {
      userContext?.user.set(user);
    });
  }, []);

  return <div>Logging in..</div>;
}

import NfsButton from "../genericComponents/NfsButton/NfsButton";

import CloseIcon from "../../assets/versionControl/close.svg?react";
import InputField from "../genericComponents/InputField/InputField";
import { useEffect, useState } from "react";

import "./CreateNewVersion.css";
import { getVersionName } from "../../utils/versioningUtils";
import Loader from "../genericComponents/Loader/Loader";

interface CreateNewVersionProps {
    onClose: () => void;
    createNewVersion: (versionName: string, versionDescription: string) => Promise<__esri.VersionManagementServiceVersionInfoExtendedJSON | null>;
    versionInfos: __esri.VersionInfoExtendedJSON[];
}

const CreateNewVersion = (props: CreateNewVersionProps) => {

    const [versionName, setVersionName] = useState<string>("");
    const [versionDescription, setVersionDescription] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const createNewVersion = async () => {
        if (!versionName || error.length > 0) {
            return
        }
        setIsLoading(true);
        const result = await props.createNewVersion(versionName, versionDescription);
        if (result !== null) {
            setIsLoading(false);
            props.onClose();
        }
        setIsLoading(false);
    }

    const validate = () => {
        let errorMessage = "";
        const listOfVersionNames = props.versionInfos.map(version => {
            const versionName = getVersionName(version);
            return versionName;
        })

        if (versionName.length > 62) {
            errorMessage = "Versjonsnavn kan ikke overstige 62 tegn.";
        } else if (/[.;'"]/.test(versionName)) {
            errorMessage = "Versjonsnavn kan ikke inkludere punktum (.), semikolon (;), enkel anførselstegn ('), eller dobbel anførselstegn (\").";
        } else if (versionDescription.length > 64) {
            errorMessage = "Versjonsbeskrivelse kan ikke overstige 64 tegn.";
        } else if (versionName.length === 0) {
            errorMessage = "Versjonsnavn kan ikke være tomt.";
        } else if (listOfVersionNames.includes(versionName)) {
            errorMessage = "En versjon med dette navnet eksisterer allerede.";
        }

        setError(errorMessage);
    };

    useEffect(() => {
        validate();
    }, [versionName, versionDescription])

    return (
        <div className="new-version-container">
            {isLoading &&
                <Loader
                    color="#000667"
                    overlay="element"
                    targetElement=".new-version-container"
                    size={30}
                />
            }
            <div className="new-version-header">
                <span>Opprett ny versjon</span>
                <button className="empty-button" onClick={props.onClose} >
                    <CloseIcon />
                </button >
            </div>
            <div className="new-version-content w-100">
                <InputField
                    inputFieldId="versionName"
                    label="Navn på versjon"
                    onChange={(value) => setVersionName(value)}
                    type="text"
                    value={versionName}
                    w100
                />
                <InputField
                    inputFieldId="versionDescription"
                    label="Beskrivelse"
                    onChange={(value) => setVersionDescription(value)}
                    type="text"
                    value={versionDescription}
                    isTextArea
                    w100
                />
                {error && <span className="error-message">{error}</span>}
                <div className="new-version-actions">
                    <NfsButton className="no-flex" inverted outlined onClick={props.onClose} >
                        Avbryt
                    </NfsButton>
                    <NfsButton disabled={error.length > 0} className="no-flex" onClick={createNewVersion} >
                        Opprett
                    </NfsButton>
                </div>
            </div>
        </div>
    )
};

export default CreateNewVersion;
import { useEffect, useState } from "react";
import TextArea from "../../genericComponents/TextArea/TextArea";

interface SectorDescriptionAttributeProps {
    setDescription: (description: string) => void;
    initialText?: string;
}

const SectorDescriptionAttribute = (props: SectorDescriptionAttributeProps) => {
    const { setDescription, initialText } = props;
    const [text, setText] = useState("");

    useEffect(() => {
        setText(initialText ?? "");
    }, []);

    useEffect(() => {
        setText(initialText ?? "");
    }, [initialText]);

    return (
        <div>
            <span className="sector-label">Beskrivelse</span>
            <TextArea
                label=""
                value={text}
                inputFieldId=""
                onChange={(newText) => {
                    setText(newText);
                }}
                onBlur={() => {
                    setDescription(text);
                }}
                maxLength={200}
            />
        </div>
    );
};

export default SectorDescriptionAttribute;

import { BackgroundLight, glassFactor, PassThroughMaterial, plasticFactor, visibilityFactor } from "../../constants/LightCalculation";
import { metersPerNauticalMile } from "../../utils/constants";

export const lightCalculationCandela = (desiredRange: number, passThroughMaterial: PassThroughMaterial, backgroundLight: BackgroundLight, shortestActiveTime: number, lightColor: number, whiteMin: number, whiteMax: number, redMin: number, redMax: number, greenMin: number, greenMax: number) => {


    const candela = beregnCandela(desiredRange, passThroughMaterial, backgroundLight, shortestActiveTime);
    const ieff = candela["Ieff"];
    const ifix = candela["Ifix"];
    const imin = ifix * 0.05 / 7.0;
    let message = "";

    const ieffcomp = Math.ceil(ieff);

    const effectiveIntensity = Math.ceil(ieff);
    const maxIntensity = Math.ceil(ifix);

    switch (lightColor) {
        case 0: //Alle
            {
                if (ieffcomp > whiteMax || ieffcomp > redMax || ieffcomp > greenMax) {
                    message = "For høy maks lysstyrke. Legg inn korrigerte parametere og beregn på nytt";
                }
                else if (ieffcomp < whiteMin || ieffcomp < redMin || ieffcomp < greenMin) {
                    message = "For lav minimum lysstyrke. Legg inn korrigerte parametere og beregn på nytt";
                }
                break;
            }

        case 1: //Hvit
            {

                if (ieffcomp > whiteMax) {
                    message = "For høy maks lysstyrke. Legg inn korrigerte parametere og beregn på nytt";
                }
                else if (ieffcomp < whiteMin) {
                    message = "For lav minimum lysstyrke. Legg inn korrigerte parametere og beregn på nytt";
                }

                break;
            }
        case 3: //Rød
            {
                if (ieffcomp > redMax) {
                    message = "For høy maks lysstyrke. Legg inn korrigerte parametere og beregn på nytt";
                }
                else if (ieffcomp < redMin) {
                    message = "For lav minimum lysstyrke. Legg inn korrigerte parametere og beregn på nytt";
                }
                break;
            }
        case 4:
            {
                if (ieffcomp > greenMax) {
                    message = "For høy maks lysstyrke. Legg inn korrigerte parametere og beregn på nytt";
                }
                else if (ieffcomp < greenMin) {
                    message = "For lav minimum lysstyrke. Legg inn korrigerte parametere og beregn på nytt";
                }
                break;
            }
    }

    return { effectiveIntensity, maxIntensity, imin, message };

}


export const beregnCandela = (desiredRange: number, passThroughMaterial: PassThroughMaterial, backgroundLight: BackgroundLight, shortestActiveTime: number) => {
    const resDict: { [key: string]: number } = {};

    resDict["D"] = desiredRange;

    const tau = shortestActiveTime;
    resDict["tau"] = tau;

    let ieff: number;
    let ifix: number;

    const e = getE(backgroundLight);

    ieff = compAllard(e, visibilityFactor, desiredRange);

    if (passThroughMaterial === PassThroughMaterial.GLASS) {
        ieff = ieff / glassFactor;
    } else if (passThroughMaterial === PassThroughMaterial.PLASTIC) {
        ieff = ieff / plasticFactor;
    }

    if (tau < 0) {
        ifix = ieff;
    } else {
        ifix = fixIntensity(ieff, tau);
    }

    resDict["Ieff"] = ieff;
    resDict["Ifix"] = ifix;
    return resDict;
}

const getE = (background: BackgroundLight): number => {
    let e = 1;
    switch (background) {
        case BackgroundLight.NONE:
            e = 2 * Math.pow(10, -7);
            break;
        case BackgroundLight.MEDIUM:
            e = 2 * Math.pow(10, -6);
            break;
        case BackgroundLight.HIGH:
            e = 2 * Math.pow(10, -5);
            break;
    }
    return e;
};

const compAllard = (I: number, T: number, d: number): number => {
    d = d * metersPerNauticalMile;
    const res = (I * Math.pow(d, 2)) / Math.pow(T, d / metersPerNauticalMile);
    return res;
};

const fixIntensity = (ie: number, df: number): number => {
    const res = ie * (0.1 + df) / df;
    return res;
};

export const omvFixIntensity = (ie: number, df: number): number => {
    const res = ie * df / (0.1 + df);
    return res;
};

export const beregnMin = (ieff: number) => {
    return (ieff * 0.05 / 7.0);
}

export const findRange = (imin: number, eff: number, passThroughMaterial: PassThroughMaterial, backgroundLight: BackgroundLight, shortestActiveTime: number) => {
    let start = 0.1;
    let inc = 0.1;
    let I = 0;
    let dist;
    dist = start;
    let minfunnet = false;
    let maksfunnet = false;
    let dmaks = 0.0;
    let dmin = 0.0;

    while (I <= eff) {
        const lyst = beregnCandela(dist, passThroughMaterial, backgroundLight, shortestActiveTime);
        const ieff = lyst["Ieff"];
        I = Math.ceil(ieff);


        if (!minfunnet) {
            if (I >= imin) {
                dmin = dist;
                minfunnet = true;
            }
        }
        if (!maksfunnet) {
            if (I > eff) {
                dmaks = dist;
                maksfunnet = true;
            }
        }
        dist = dist + inc;

    }
    dmaks = dmaks - inc;;

    return { dmin, dmaks };

}
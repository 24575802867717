import { useContext, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTableIcon from "../../../assets/datatable.svg?react";
import { AppContext, LighthouseContext } from "../../../Context";
import { setIsSectorTableOpen, setLighthouseTableSelectedSector } from "../../../store/lighthouseSlice";
import { StoreState } from "../../../store/rootReducer";
import "../../../style/variables.css";
import { LighthouseData, SectorColor } from "../../../utils/lighthouseUtils";
import FloatingWindow from "../../genericComponents/FloatingWindow/FloatingWindow";
import SectorColorIndicator from "./SectorColorIndicator";
import "./SectorTable.css";
import SectorTableInputField from "./SectorTableInputField";

const SectorTable = () => {
    const appContext = useContext(AppContext);
    const lighthouseContext = useContext(LighthouseContext);

    const lighthouseTableRef = useRef(null);
    const dispatch = useDispatch();

    const lighthouseTableSelectedSector = useSelector(
        (state: StoreState) => state.lighthouse.lighthouseTableSelectedSector
    );

    const [focusedTextField, setFocusedTextField] = useState<number | null>(null);

    const selectedFeature = appContext!.selectedFeature.value;
    const lighthouseData = selectedFeature?.attributes.lighthouseData as LighthouseData;
    const setLighthouseSectorPanelsOpen = lighthouseContext!.lighthouseSectorPanelsOpen.set;
    const setLighthouseState = lighthouseContext!.lighthouseData.set;

    if (selectedFeature?.attributes.lighthouseData === null) return null;

    const closeTable = () => {
        dispatch(setIsSectorTableOpen(false));
        dispatch(setLighthouseTableSelectedSector(null));
    };

    const selectSector = (index: number) => {
        dispatch(setLighthouseTableSelectedSector(index));
        setLighthouseSectorPanelsOpen(new Set([index]));
    };

    const saveDescription = (index: number, description: string) => {
        const newLighthouseData = { ...lighthouseData };
        newLighthouseData.sectors[index].description = description;
        lighthouseContext!.lighthouseData.value = newLighthouseData;
        setLighthouseState(lighthouseContext!.lighthouseData.value);
    };

    const unselectSectors = () => {
        dispatch(setLighthouseTableSelectedSector(null));
        setLighthouseSectorPanelsOpen(new Set());
    };

    let displayIndex = -1;

    return (
        <FloatingWindow
            title="Sektortabell"
            className="sector-table-window"
            toolRef={lighthouseTableRef}
            closeWindow={closeTable}
            Icon={DataTableIcon}
        >
            <table className="sector-table">
                <thead>
                    <tr>
                        <th>Sektor</th>
                        <th>Beskrivelse</th>
                    </tr>
                </thead>
                <tbody>
                    {lighthouseData?.sectors.map((sector) => {
                        if (sector.color !== SectorColor.DARK) displayIndex++;
                        return (
                            <tr
                                key={sector.index}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    if (lighthouseTableSelectedSector !== sector.index) {
                                        selectSector(sector.index);
                                    } else if (focusedTextField !== sector.index) {
                                        unselectSectors();
                                    }
                                }}
                                className={
                                    lighthouseTableSelectedSector === sector.index ? "sector-table-selected-sector" : ""
                                }
                            >
                                <td className="flex-row small-gap align-center">
                                    <SectorColorIndicator color={sector.color ?? SectorColor.DARK} height="1rem" />
                                    {`Sektor ${sector.color === SectorColor.DARK ? "-" : displayIndex + 1}`}
                                </td>
                                <td>
                                    <SectorTableInputField
                                        index={sector.index}
                                        initialValue={sector.description ?? ""}
                                        saveDescription={saveDescription}
                                        onFocus={() => {
                                            if (lighthouseTableSelectedSector !== sector.index)
                                                selectSector(sector.index);
                                            setFocusedTextField(sector.index);
                                        }}
                                        onBlur={() => setFocusedTextField(null)}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </FloatingWindow>
    );
};

export default SectorTable;

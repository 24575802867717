export const readOnlyFields = [
    "objectid",
    "gis_obj_id",
    "OppdatertDato",
    "OppdatertAv",
    "Lat",
    "Long",
    "OpprettetDato",
    "MaltDato",
    "MaltAv",
    "gyldigFra",
    "gyldigTil",
]